import moment from "moment";

import * as Types from "../types";

export function* scheduleTimeSequence(day: number, center: Types.Center, intervalMinutes: number) {
  if (!center?.hoursOfOperation?.length) {
    return undefined;
  }

  const start = moment(day).startOf("day");
  const hours = center.hoursOfOperation.find(hoo => hoo.day === Types.daysOfWeek[start.day()]);
  if (!hours) {
    return undefined;
  }

  for (let current = hours.start; current < hours.end; current += intervalMinutes) {
    // console.log(current);
    yield start.clone().add(current, "m").valueOf();
  }
}
