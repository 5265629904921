import React, { useCallback, useState } from "react";
import classnames from "classnames";

import * as Types from "../../data/types";

import "./edit-center-rate.scss";

export interface EditCenterRateProps {
  rate: Types.CenterTuitionRate;
  onSave?: (rate: Types.CenterTuitionRate) => void;
  onCancel?: () => void;
}

export interface FieldProps {
  value?: string;
  mode?: "edit" | "view";
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Field: React.FC<FieldProps> = ({ value, mode, onChange }) => {
  if (mode === "view") {
    return <>{value}</>;
  } else {
    return <input type="text" value={value} style={{ width: "100%" }} onChange={onChange} />;
  }
};

export const rateNumberToString = (value: number | undefined, type: Types.CenterTuitionRateType) => {
  if (value === undefined || type === undefined) {
    return "0";
  }
  if (type === "tuition") {
    return value.toFixed(2);
  } else {
    return (value * 100).toFixed(0);
  }
};

export const EditCenterRate: React.FC<EditCenterRateProps> = ({ rate, onSave, onCancel }) => {
  const [localRate, setLocalRate] = useState<Types.CenterTuitionRate>({ ...rate });
  const localSave = useCallback(() => {
    if (onSave) {
      onSave(localRate);
    }
    if (onCancel) {
      onCancel();
    }
  }, [onSave, onCancel, localRate]);

  const onChangeRate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = !!e.target.value ? parseInt(e.target.value, 10) : 0;
    setLocalRate(prev => {
      const rate = prev.type === "tuition" ? userInput : userInput > 0 ? userInput / 100 : userInput;
      return { ...prev, rate };
    });
  };

  const onChangeHours = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = !!e.target.value ? parseInt(e.target.value, 10) : 0;
    setLocalRate(prev => {
      return { ...prev, hours: userInput };
    });
  };

  return (
    <div className={classnames("edit-center-rate-outer-container")}>
      <h2>{rate.type === "tuition" ? "Tuition Rate" : "Discount"}</h2>
      <div className="edit-center-rate-container">
        <div className="single">
          <div className="data-cell">
            <span>ID</span>
            <span>{rate?.id || "new"}</span>
          </div>
        </div>
        <div className="single">
          <div className="data-cell">
            <span>{localRate?.type === "discount" ? "Description" : "Service"}</span>
            <span>{rate?.description || "new"}</span>
          </div>
        </div>
        <div className="single">
          <div className="data-cell">
            <span>{localRate?.type === "discount" ? "Percentage" : "Rate"}</span>
            <span>
              <Field
                value={rateNumberToString(localRate?.rate, localRate?.type)}
                mode={"edit"}
                onChange={onChangeRate}
              />
            </span>
          </div>
        </div>
        {localRate?.type === "discount" && (
          <div className="single">
            <div className="data-cell">
              <span>Hours</span>
              <span>
                <Field value={localRate?.hours?.toFixed(0)} mode={"edit"} onChange={onChangeHours} />
              </span>
            </div>
          </div>
        )}
      </div>

      <div className={classnames("edit-center-rate-button-row")}>
        <button className="button" onClick={onCancel}>
          Cancel
        </button>
        <button className="button" onClick={localSave}>
          Save
        </button>
      </div>
    </div>
  );
};
