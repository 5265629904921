import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as Types from "../data/types";
import * as UsersData from "../data/users";

//import * as Api from "../data/api";

const currentUserSessionStorageKey = "lcos-current-user";
const UserApi = {
  getCurrentUser: async () => {
    const user = sessionStorage.getItem(currentUserSessionStorageKey);
    if (user) {
      return JSON.parse(user) as Types.User;
    } else {
      return undefined;
    }
  },
  logout: async () => {
    sessionStorage.removeItem(currentUserSessionStorageKey);
  },
  login: async (username: string, password: string) => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    //await Api.login("sharvell", "Shawniscool1!");
    const user = UsersData.users.find(u => u.username === username && u.username === password);
    if (!user) {
      throw new Error("invalid username and/or password");
    } else {
      sessionStorage.setItem(currentUserSessionStorageKey, JSON.stringify(user));
      return user;
    }
  }
};

export interface AuthenticationContextType {
  user?: Types.User;
  activeCenter?: Types.Center;
  loading: boolean;
  error?: string | Error;
  login: (username: string, password: string) => void;
  logout: () => void;
  changeActiveCenter: (newCenter: Types.Center) => void;
}

export const AuthenticationContext = createContext<AuthenticationContextType>({
  loading: false,
  login: (username: string, password: string) => {
    console.log(`login ${username} / ${password}`);
  },
  logout: () => {
    console.log("logout");
  },
  user: {
    id: "mock-user",
    username: "mock-user",
    sortableName: "Doe, John",
    name: "John Doe",
    initials: "JD",
    centers: [UsersData.centers[0]],
    roles: ["admin", "owner", "faculty"]
  },
  activeCenter: UsersData.centers[0],
  changeActiveCenter: (newCenter: Types.Center) => {
    console.log("changeActiveCenter", newCenter);
  }
});

export const AuthenticationProvider: React.FunctionComponent = ({ children }) => {
  const [user, setUser] = useState<Types.User>();
  const [activeCenter, setActiveCenter] = useState<Types.Center>();
  const [error, setError] = useState<string | Error>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    UserApi.getCurrentUser()
      .then(user => setUser(user))
      .finally(() => setLoadingInitial(false));
  }, []);

  useEffect(() => {
    if (user && user.centers?.length && !activeCenter) {
      setActiveCenter(user.centers[0]);
    }
  }, [user, activeCenter]);

  const login = useCallback(
    (username: string, password: string) => {
      setLoading(true);

      UserApi.login(username, password)
        .then(user => {
          setUser(user);
          setError(undefined);
          navigate("/dashboard", { replace: true });
        })
        .catch(error => setError(error))
        .finally(() => setLoading(false));
    },
    [navigate]
  );

  const logout = useCallback(() => {
    setError(undefined);
    UserApi.logout().then(() => setUser(undefined));
  }, []);

  const changeActiveCenter = useCallback((newCenter: Types.Center) => {
    if (newCenter) {
      setActiveCenter(newCenter);
    }
  }, []);

  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      logout,
      activeCenter,
      changeActiveCenter
    }),
    [user, loading, error, activeCenter, login, logout, changeActiveCenter]
  );

  return (
    <AuthenticationContext.Provider value={memoedValue}>{!loadingInitial && children}</AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => useContext(AuthenticationContext);
