import React, { useMemo } from "react";

import * as Types from "../../data/types";

export interface TodaysAeAssignmentsProps {
  aeToday: Types.Student[];
  employees: Types.Employee[];
}

export const TodaysAeAssignments: React.FC<TodaysAeAssignmentsProps> = ({ aeToday, employees }) => {
  const summaries = useMemo(() => {
    const data = aeToday
      .filter(s => !!s.assignedTo)
      .reduce<{ [key: string]: { name: string; count: number; id: string } }>((previous, current) => {
        const id = `${current.assignedTo}`;
        const employee = employees.find(e => e.id === id)?.name || "";
        if (id in previous) {
          const copy = { ...previous };
          copy[id].count = previous[id].count + 1;
          return copy;
        } else {
          const copy = { ...previous };
          copy[id] = { name: employee, count: 1, id };
          return copy;
        }
      }, {});
    const array = Object.values(data);
    array.sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""));
    return array;
  }, [aeToday, employees]);

  // this percentage bases the percent of progress bar on how many a given employee has of the
  // total count of AE today
  //const total = useMemo(() => summaries.reduce((previous, current) => previous + current.count, 0), [summaries]);
  // whereas this one shows based on how many a given employee has WRT the total count of employees that
  // have any assignments today, I think this one seems better but preserving the above in case anyone disagrees
  const total = useMemo(() => summaries.length, [summaries]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Today&apos;s AE Assignments</h2>
      </div>
      <div className="card-body">
        <table className="table table-hover table-collapse-md">
          <thead>
            <tr>
              <th scope="col">Staff</th>
              <th scope="col">Capacity</th>
            </tr>
          </thead>

          <tbody>
            {summaries.map(s => {
              const percentage = Math.round((s.count / total) * 100);
              return (
                <tr key={`todays-ae-assignments-row-${s.id}`} data-testid={`todays-ae-assignments-row-${s.id}`}>
                  <td className="td-primary">{s.name}</td>
                  <td>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${percentage}%` }}
                        aria-valuenow={percentage}
                        aria-valuetext={`${percentage} percent`}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        aria-label="Bayer, Giovan"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
