import React from "react";

import { Header, HeaderTab } from "../components/header/header";
import { AppMenu } from "./app-menu";
import { useAuthentication } from "./authentication-context";

export interface AppLayoutProps {
  children?: React.ReactNode;
  activeTab?: HeaderTab;
  tabQualifier?: string;
}

export const AppLayout: React.FC<React.PropsWithChildren<AppLayoutProps>> = ({ children, activeTab, tabQualifier }) => {
  const { user, activeCenter, logout } = useAuthentication();

  return (
    <>
      <Header activeTab={activeTab} user={user} center={activeCenter} logout={logout} tabQualifier={tabQualifier} />
      <main>
        <AppMenu />
        <div className="content-viewport">{children}</div>
      </main>
    </>
  );
};

export const Leftside: React.FC<React.PropsWithChildren<{ size?: number }>> = ({ children, size = 6 }) => {
  return <div className={`col col-content-primary col-xs-${size} col-lg-${size}`}>{children}</div>;
};

export const Rightside: React.FC<React.PropsWithChildren<{ showIf?: boolean; size?: number }>> = ({
  children,
  showIf = true,
  size = 6
}) => {
  if (!showIf) {
    return null;
  }
  return <div className={`col col-sidebar col-xs-${size} col-lg-${size}`}>{children}</div>;
};

export const Utility: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <div className="utility-bar">{children}</div>;
};

export const ContentPad: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <div className="content-pad">{children}</div>;
};
