import React from "react";

import * as Types from "../../data/types";

import { useAuthentication } from "../../app/authentication-context";
import { Contact } from "./tab-content/contact";
import { Subjects } from "./tab-content/subjects";
import { Scheduling } from "./tab-content/scheduling";
import { Positions } from "./tab-content/position";
import { Access } from "./tab-content/access";

import * as StaffSlice from "../../data/staff-slice";

export type StaffDetailTabType = "contact" | "subjects" | "scheduling" | "position" | "access";

export interface StaffDetailProps {
  employee?: Types.Employee;
  onSaveStaffAvailability?: (payload: StaffSlice.SaveStaffAvailabilityPayload) => void;
  onToggleStaffSubject?: (payload: StaffSlice.ToggleSubjectPayload) => void;
  onToggleStaffServiceSubject?: (payload: StaffSlice.ToggleServiceSubjectPayload) => void;
}

export const StaffDetail: React.FC<StaffDetailProps> = ({
  employee,
  onSaveStaffAvailability,
  onToggleStaffSubject,
  onToggleStaffServiceSubject
}) => {
  const { activeCenter } = useAuthentication();

  if (!employee || !activeCenter) {
    return null;
  }

  return (
    <>
      <ul className="nav nav-tabs" id="staff-detail-tabs">
        <li className="nav-item">
          <a className={"nav-link active"} data-bs-toggle="tab" href="#tab-contact">
            <span className="fal icon fa-mobile-android" aria-hidden="true"></span>
            Contact
          </a>
        </li>
        <li className="nav-item">
          <a className={"nav-link"} data-bs-toggle="tab" href="#tab-subjects">
            <span className="fal icon fa-chalkboard-teacher" aria-hidden="true"></span>
            Subjects
          </a>
        </li>
        <li className="nav-item">
          <a className={"nav-link"} data-bs-toggle="tab" href="#tab-scheduling">
            <span className="fal icon fa-user-clock" aria-hidden="true"></span>
            Scheduling
          </a>
        </li>
        <li className="nav-item">
          <a className={"nav-link"} data-bs-toggle="tab" href="#tab-position">
            <span className="fal icon fa-people-carry" aria-hidden="true"></span>
            Position
          </a>
        </li>
        <li className="nav-item">
          <a className={"nav-link"} data-bs-toggle="tab" href="#tab-access">
            <span className="fal icon fa-user-lock" aria-hidden="true"></span>
            Access
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div className="tab-pane container active" id="tab-contact">
          <Contact contactInformation={employee?.contactInformation} />
        </div>
        <div className="tab-pane container" id="tab-subjects">
          <Subjects
            subjects={employee.subjects}
            serviceSubjects={employee.serviceSubjects}
            onToggleStaffSubject={onToggleStaffSubject}
            onToggleStaffServiceSubject={onToggleStaffServiceSubject}
            employeeId={employee.id}
            centerId={activeCenter.id}
          />
        </div>
        <div className="tab-pane container" id="tab-scheduling">
          <Scheduling
            availability={employee.availability}
            employeeId={employee.id}
            centerId={activeCenter.id}
            onSaveStaffAvailability={onSaveStaffAvailability}
          />
        </div>
        <div className="tab-pane container" id="tab-position">
          <Positions employments={employee?.employments} />
        </div>
        <div className="tab-pane container" id="tab-access">
          <Access accessControl={employee?.accessControl} />
        </div>
      </div>
    </>
  );
};
