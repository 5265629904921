import * as Types from "./types";
import * as Utils from "./utils";

import moment from "moment";

export const filterStudents = (type: Types.TodoItemType, students: Types.Student[]): Types.Student[] => {
  if (!students?.length) {
    return [];
  }

  switch (type) {
    case "ae-today": {
      return students.filter(s => s.status === "DT");
    }
    case "starting-today": {
      return students.filter(s => !!s.startingToday);
    }
    case "balance-due": {
      return students.filter(s => (s.financials?.reduce((acc, item) => acc + item.amount, 0) || 0) > 0);
    }
    case "alert-list": {
      return students.filter(s => !!s.alert);
    }
    case "ae-no-conf": {
      return students.filter(
        s =>
          s.histories?.some(h => h.text === "academic-evaluation") && !s.histories?.some(h => h.text === "conference")
      );
    }
    case "conf-not-enrolled": {
      return students.filter(
        s => s.histories?.some(h => h.text === "conference") && !s.histories?.some(h => h.text === "enrolled")
      );
    }
    case "missing-info": {
      return students.filter(s => !!s.missingInfo);
    }
    case "deactivated-sched": {
      return students.filter(s => !!s.schedules?.some(sc => !!sc.deactivated));
    }
    case "re-enroll": {
      return students.filter(s => !!s.reenrolling);
    }
    case "interim-conf": {
      return students.filter(s => s.status === "CONF" && !!s.interimConference);
    }
    case "initial-conference": {
      return students.filter(s => s.status === "CONF" && !s.interimConference);
    }
    case "attending-today": {
      return students.filter(s => s.status === "ENR");
    }
    case "interim-test": {
      return students.filter(s => s.status === "DROP" && s.startingToday);
    }
    case "school-visit": {
      return students.filter(s => s.status === "INQ" && s.ready);
    }
    case "inquiry": {
      return students.filter(s => s.status === "INQ" && !s.ready);
    }

    default: {
      return [];
    }
  }
};

export const getSummaryData = (students: Types.Student[]): Types.TodoItem[] => {
  return Types.todoItemTypes.map((it: Types.TodoItemType) => {
    return { type: it, count: filterStudents(it, students).length };
  });
};

export interface DashboardDatasets {
  [key: string]: Types.Student[];
}

export const getDashboardDatasets = (): DashboardDatasets => {
  const datasets: DashboardDatasets = {};
  for (const itemType of Types.todoItemTypes) {
    datasets[itemType] = filterStudents(itemType as Types.TodoItemType, students);
  }
  return datasets;
};

export const student01: Types.Student = {
  id: "1878867",
  name: { first: "Kirstin", middle: "", last: "Ritchie" },
  contactInformation: {
    phone: "(610) 046-8322",
    address: {
      line1: "7459 Botsford Lakes",
      line2: "",
      city: "Palm Desert",
      state: "PA",
      zip: "38786-8079"
    },
    parent1: "Ritchie, Tommie",
    parent2: "Ritchie, Nicolette"
  },
  lastInteractionTime: 1640968202809,
  currentBalance: 0,
  lastPaymentDate: 1638953224186,
  status: "ENR",
  startingToday: true,
  alert: false,
  schedules: [],
  financials: [
    { date: 1642821097297, type: "debit", amount: 91 },
    { date: 1642825699502, type: "debit", amount: 28 },
    { date: 1642862956964, type: "credit", amount: -395 }
  ],
  histories: [
    { date: new Date("2021-10-19T00:00:00.000Z").getTime(), text: "inquired" },
    { date: new Date("2021-11-09T05:00:00.000Z").getTime(), text: "academic-evaluation" },
    { date: new Date("2021-12-17T00:00:00.000Z").getTime(), text: "conference" },
    { date: new Date("2022-01-04T05:00:00.000Z").getTime(), text: "enrolled" }
  ],
  notes: [
    {
      date: 1640619880076,
      text: "bandwidth-monitored Bedfordshire Clothing deposit website Loan Factors azure disintermediate Accounts"
    }
  ],
  assignedTo: "7601447"
};

const alertDescriptions: string[] = [
  "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
  "Qui dicta minus molestiae vel beatae natus eveniet ratione temporibus aperiam harum alias officiis assumenda officia quibusdam deleniti eos cupiditate dolore doloribus!",
  "Ad dolore dignissimos asperiores dicta facere optio quod commodi nam tempore recusandae.",
  "Modi velit odio nam nulla unde amet odit pariatur at.",
  "Consequatur rerum amet fuga expedita sunt et tempora saepe."
];

export const students: Types.Student[] = [
  {
    id: "7776982",
    name: { first: "Lisa", middle: "", last: "Abshire" },
    contactInformation: {
      phone: "(610) 636-6124",
      address: {
        line1: "96413 Ashton Landing",
        line2: "",
        city: "West Jordan",
        state: "PA",
        zip: "04032-1958"
      },
      parent1: "Abshire, Alysa",
      parent2: "Abshire, Georgianna"
    },
    lastInteractionTime: 1641514963749,
    currentBalance: 0,
    lastPaymentDate: 1637427415599,
    status: "DROP",
    startingToday: true,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1640886852904,
        text: "Tuna Refined Intelligent Berkshire payment deposit withdrawal orange monitoring Village Human Directives best-of-breed visualize experiences Practical deposit Legacy Ariary invoice Licensed e-tailers"
      }
    ],
    assignedTo: "3091227",
    ready: false,
    service: "LC",
    aeTime: "10:00"
  },
  {
    id: "1052099",
    name: { first: "Justus", middle: "", last: "Altenwerth" },
    contactInformation: {
      phone: "(610) 826-2624",
      address: {
        line1: "83736 Ibrahim Points",
        line2: "",
        city: "Enterprise",
        state: "PA",
        zip: "12964-0825"
      },
      parent1: "Altenwerth, Juanita",
      parent2: "Altenwerth, Erwin"
    },
    lastInteractionTime: 1636893005853,
    currentBalance: 0,
    lastPaymentDate: 1642646896098,
    status: "DT",
    startingToday: false,
    alert: false,
    alertDescription: alertDescriptions[1],
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642146981780,
        text: "Forward Maine architecture firewall Cotton Regional Carolina disintermediate Mobility sky Indiana Global withdrawal deposit"
      }
    ],
    assignedTo: "9146060",
    ready: false,
    service: "ST",
    aeTime: "15:30"
  },
  {
    id: "8765009",
    name: { first: "Estelle", middle: "", last: "Bashirian" },
    contactInformation: {
      phone: "(610) 371-1525",
      address: { line1: "02047 Goodwin Roads", line2: "", city: "Concord", state: "PA", zip: "12389" },
      parent1: "Bashirian, Olaf",
      parent2: "Bashirian, Lemuel"
    },
    lastInteractionTime: 1637660391935,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [
      {
        id: "student-sched-01",
        days: [
          {
            id: "day-sched-01",
            day: "monday",
            start: Utils.parse24HourTime("15:00"),
            end: Utils.parse24HourTime("16:00")
          }
        ],
        effective: moment().subtract(135, "days").valueOf(),
        expiration: moment().add(69, "days").valueOf(),
        serviceSubject: "ST---Foreign Languages",
        delivery: "Center"
      }
    ],
    financials: [
      { date: 1642874554492, type: "debit", amount: 100 },
      { date: 1642928929573, type: "credit", amount: -528 },
      { date: 1642947985559, type: "debit", amount: 255 }
    ],
    histories: [
      { date: 1633492800000, text: "inquired" },
      { date: 1637366400000, text: "academic-evaluation" },
      { date: 1638939600000, text: "conference" },
      { date: 1642118400000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1640760759080,
        text: "parse Quality Realigned Frozen Handcrafted alliance Rustic Ethiopian synthesizing Refined Account Refined Sports Avon"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "2047369",
    name: { first: "Domenica", middle: "", last: "Bayer" },
    contactInformation: {
      phone: "(610) 627-5142",
      address: {
        line1: "2989 Cary Corner",
        line2: "",
        city: "South Valley",
        state: "PA",
        zip: "68547-0280"
      },
      parent1: "Bayer, Carolanne",
      parent2: "Bayer, Ernest"
    },
    lastInteractionTime: 1637671016190,
    currentBalance: 0,
    lastPaymentDate: 1638020880045,
    status: "DT",
    startingToday: false,
    alert: false,
    schedules: [
      {
        id: "student-sched-01",
        days: [
          {
            id: "day-sched-01",
            day: "monday",
            start: Utils.parse24HourTime("15:00"),
            end: Utils.parse24HourTime("16:00")
          }
        ],
        effective: moment().subtract(135, "days").valueOf(),
        expiration: moment().add(69, "days").valueOf(),
        serviceSubject: "ST---History",
        delivery: "Center"
      }
    ],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641497739162,
        text: "frictionless bus composite Arkansas Rial Licensed and Home Fantastic envisioneer Pre-emptive Consultant leverage Officer payment Designer open-source indigo Internal sticky Shoes online Gloves implement"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "ST",
    aeTime: "15:30",
    missingInfo: true
  },
  {
    id: "8687549",
    name: { first: "Garrison", middle: "", last: "Bernier" },
    contactInformation: {
      phone: "(610) 928-0954",
      address: { line1: "92478 Spencer Fords", line2: "", city: "Kendall", state: "PA", zip: "68851-3134" },
      parent1: "Bernier, Arnulfo",
      parent2: "Bernier, Edgardo"
    },
    lastInteractionTime: 1637842380028,
    currentBalance: 0,
    lastPaymentDate: 1642358436060,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [
      {
        id: "student-sched-01",
        days: [
          {
            id: "day-sched-01",
            day: "monday",
            start: Utils.parse24HourTime("17:00"),
            end: Utils.parse24HourTime("18:30")
          },
          {
            id: "day-sched-02",
            day: "wednesday",
            start: Utils.parse24HourTime("17:00"),
            end: Utils.parse24HourTime("18:30")
          }
        ],
        effective: moment().subtract(216, "days").valueOf(),
        expiration: moment().add(167, "days").valueOf(),
        serviceSubject: "S1---Math",
        delivery: "Center"
      }
    ],
    financials: [
      { date: 1642875036193, type: "debit", amount: 103 },
      { date: 1642894618112, type: "credit", amount: -104 },
      { date: 1642915670746, type: "debit", amount: 127 }
    ],
    histories: [
      { date: 1633752000000, text: "inquired" },
      { date: 1637712000000, text: "academic-evaluation" },
      { date: 1639785600000, text: "conference" },
      { date: 1642291200000, text: "enrolled" }
    ],
    notes: [{ date: 1642370568361, text: "Concrete Bacon Cove Mill Oklahoma Bacon Isle relationships deploy" }],
    assignedTo: "7601447",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "6801634",
    name: { first: "Cathryn", middle: "", last: "Boyer" },
    contactInformation: {
      phone: "(610) 273-7272",
      address: { line1: "64732 Cayla Trail", line2: "", city: "Wyoming", state: "PA", zip: "82337-6485" },
      parent1: "Boyer, Monserrat",
      parent2: "Boyer, Murray"
    },
    lastInteractionTime: 1642733175171,
    currentBalance: 0,
    lastPaymentDate: 1641312188360,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [
      {
        id: "student-sched-01",
        days: [
          {
            id: "day-sched-01",
            day: "tuesday",
            start: Utils.parse24HourTime("18:00"),
            end: Utils.parse24HourTime("19:00")
          }
        ],
        effective: moment().subtract(29, "days").valueOf(),
        expiration: moment().add(359, "days").valueOf(),
        serviceSubject: "S1---Critical Reading and Writing",
        delivery: "Center"
      },
      {
        id: "student-sched-02",
        days: [
          {
            id: "day-sched-01",
            day: "saturday",
            start: Utils.parse24HourTime("10:00"),
            end: Utils.parse24HourTime("11:00")
          }
        ],
        effective: moment().subtract(29, "days").valueOf(),
        expiration: moment().add(150, "days").valueOf(),
        serviceSubject: "ST---Foreign Languages",
        delivery: "Center"
      }
    ],
    financials: [
      { date: 1642899072578, type: "debit", amount: 74 },
      { date: 1642909413991, type: "debit", amount: 129 },
      { date: 1642949686607, type: "credit", amount: -117 }
    ],
    histories: [
      { date: 1633752000000, text: "inquired" },
      { date: 1636761600000, text: "academic-evaluation" },
      { date: 1640736000000, text: "conference" },
      { date: 1641272400000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642322663460,
        text: "Fresh North SAS microchip Trinidad Cotton Austria Hong Tools maroon Jewelery AI Tobago Strategist Security orchestrate Salad mindshare enhance concept New Neck"
      }
    ],
    assignedTo: "9322861",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "0147136",
    name: { first: "Kameron", middle: "", last: "Casper" },
    contactInformation: {
      phone: "(610) 373-3433",
      address: { line1: "216 Schmitt Plains", line2: "", city: "Mountain View", state: "PA", zip: "63236" },
      parent1: "Casper, Myrtle",
      parent2: "Casper, Jadyn"
    },
    lastInteractionTime: 1642712177526,
    currentBalance: 396.3662898975979,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: false,
    alert: true,
    alertDescription: alertDescriptions[2],
    schedules: [
      {
        id: "student-sched-11",
        days: [
          {
            id: "day-sched-01",
            day: "monday",
            start: Utils.parse24HourTime("15:00"),
            end: Utils.parse24HourTime("16:00")
          }
        ],
        effective: moment().subtract(135, "days").valueOf(),
        expiration: moment().add(69, "days").valueOf(),
        serviceSubject: "ST---Foreign Languages",
        delivery: "Center"
      }
    ],
    financials: [
      { date: 1642882029388, type: "credit", amount: -331 },
      { date: 1642894419194, type: "debit", amount: 78 },
      { date: 1642921739170, type: "debit", amount: 61 }
    ],
    histories: [
      { date: 1634515200000, text: "inquired" },
      { date: 1636675200000, text: "academic-evaluation" },
      { date: 1639180800000, text: "conference" },
      { date: 1643155200000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642779920420,
        text: "Garden e-business bandwidth Towels Mountain panel online deposit Fantastic withdrawal IB Towels Dynamic Cotton"
      }
    ],
    assignedTo: "3091227",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "4337466",
    name: { first: "Preston", middle: "", last: "Cummerata" },
    contactInformation: {
      phone: "(610) 447-2768",
      address: {
        line1: "8983 Dickens Court",
        line2: "",
        city: "West Hartford",
        state: "PA",
        zip: "85010-9432"
      },
      parent1: "Cummerata, Garnett",
      parent2: "Cummerata, Stanley"
    },
    lastInteractionTime: 1641121810321,
    currentBalance: 0,
    lastPaymentDate: 1638453296030,
    status: "INQ",
    startingToday: false,
    alert: false,
    schedules: [
      {
        id: "student-sched-01",
        days: [
          {
            id: "day-sched-01",
            day: "monday",
            start: Utils.parse24HourTime("15:00"),
            end: Utils.parse24HourTime("16:00")
          }
        ],
        effective: moment().subtract(135, "days").valueOf(),
        expiration: moment().add(69, "days").valueOf(),
        serviceSubject: "ST---English",
        delivery: "Center"
      }
    ],
    financials: [],
    histories: [],
    notes: [{ date: 1642556254539, text: "Account Account Beauty Borders Gateway feed scale Tennessee" }],
    assignedTo: "1993477",
    ready: true,
    service: "LC",
    aeTime: "13:00"
  },
  {
    id: "9405370",
    name: { first: "Jackson", middle: "", last: "Davis" },
    contactInformation: {
      phone: "(610) 624-0928",
      address: {
        line1: "09593 Paucek Harbors",
        line2: "",
        city: "Midwest City",
        state: "PA",
        zip: "52437"
      },
      parent1: "Davis, Cristal",
      parent2: "Davis, Liliane"
    },
    lastInteractionTime: 1640157783649,
    currentBalance: 0,
    lastPaymentDate: 1636685979220,
    status: "DROP",
    reenrolling: true,
    startingToday: false,
    schedules: [],
    financials: [],
    histories: [
      { date: 1633492800000, text: "inquired" },
      { date: 1637366400000, text: "academic-evaluation" },
      { date: 1638939600000, text: "conference" },
      { date: 1642118400000, text: "enrolled" },
      { date: 1642118400000 + 86400000 * 3, text: "dropped" }
    ],
    notes: [
      {
        date: 1641421672795,
        text: "exploit ivory integrated payment Soap transmitter haptic Forge up Kansas initiatives open-source Shores compelling Platinum Plastic Cotton Tactics strategize azure Future Soft mobile 1080p"
      }
    ],
    assignedTo: "9322861",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "3421175",
    name: { first: "Abbie", middle: "", last: "Deckow" },
    contactInformation: {
      phone: "(610) 285-5695",
      address: { line1: "00028 Wiza Cape", line2: "", city: "Orland Park", state: "PA", zip: "92478-9744" },
      parent1: "Deckow, Mathias",
      parent2: "Deckow, Josefina"
    },
    lastInteractionTime: 1639270916449,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: true,
    alert: false,
    schedules: [
      {
        id: "student-sched-11",
        days: [
          {
            id: "day-sched-01",
            day: "monday",
            start: Utils.parse24HourTime("12:00"),
            end: Utils.parse24HourTime("12:30")
          }
        ],
        effective: moment().subtract(135, "days").valueOf(),
        expiration: moment().add(69, "days").valueOf(),
        serviceSubject: "ST---Foreign Languages",
        delivery: "Center"
      }
    ],
    financials: [
      { date: 1642881024091, type: "debit", amount: 95 },
      { date: 1642893429897, type: "credit", amount: -521 },
      { date: 1642948176618, type: "debit", amount: 73 }
    ],
    histories: [
      { date: 1635465600000, text: "inquired" },
      { date: 1636084800000, text: "academic-evaluation" },
      { date: 1640217600000, text: "conference" },
      { date: 1641945600000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641957530786,
        text: "overriding back-end Markets hard Manager Switzerland hack Digitized Strategist reinvent brand Soft invoice gold Accountability stable CSS Bedfordshire fresh-thinking e-tailers Developer Gorgeous"
      }
    ],
    assignedTo: "9322861",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "3262664",
    name: { first: "Breanna", middle: "", last: "Dibbert" },
    contactInformation: {
      phone: "(610) 890-8368",
      address: { line1: "9954 Ona Street", line2: "", city: "San Ramon", state: "PA", zip: "79098-5363" },
      parent1: "Dibbert, Kiarra",
      parent2: "Dibbert, Rosemarie"
    },
    lastInteractionTime: 1636304433376,
    currentBalance: 0,
    lastPaymentDate: 1637454977629,
    status: "DT",
    histories: [
      { date: 1635465600000, text: "inquired" },
      { date: 1636084800000, text: "academic-evaluation" }
    ],
    startingToday: false,
    alert: false,
    schedules: [
      {
        id: "student-sched-11",
        days: [
          {
            id: "day-sched-01",
            day: "monday",
            start: Utils.parse24HourTime("12:00"),
            end: Utils.parse24HourTime("12:30")
          }
        ],
        effective: moment().subtract(135, "days").valueOf(),
        expiration: moment().add(69, "days").valueOf(),
        serviceSubject: "ST---Foreign Languages",
        delivery: "Center"
      }
    ],
    financials: [],
    notes: [
      {
        date: 1642526489859,
        text: "driver solutions SQL connecting Paradigm value-added Brook Colorado Corporate payment withdrawal Granite Response Netherlands Chair virtual interactive Keyboard Terrace"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "ST",
    aeTime: "14:00"
  },
  {
    id: "1760710",
    name: { first: "Summer", middle: "", last: "Dickinson" },
    contactInformation: {
      phone: "(610) 277-1220",
      address: { line1: "2454 Adrianna Junctions", line2: "", city: "Merced", state: "PA", zip: "03850" },
      parent1: "Dickinson, Filomena",
      parent2: "Dickinson, Lorine"
    },
    lastInteractionTime: 1637810387885,
    currentBalance: 246.96671512871583,
    lastPaymentDate: 0,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642143797743,
        text: "Buckinghamshire AGP Enhanced parsing recontextualize purple harness Tactics synergistic"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "6207957",
    name: { first: "Zachary", middle: "", last: "Dickinson" },
    contactInformation: {
      phone: "(610) 681-2540",
      address: { line1: "720 Runte Ways", line2: "", city: "Ames", state: "PA", zip: "08074-9755" },
      parent1: "Dickinson, Jayce",
      parent2: "Dickinson, Dayana"
    },
    lastInteractionTime: 1639107020702,
    currentBalance: 0,
    lastPaymentDate: 1639205733977,
    status: "DROP",
    startingToday: false,
    alert: true,
    alertDescription: alertDescriptions[4],
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642774606349,
        text: "Savings generating SDD yellow magenta invoice Table Fresh Loan Lesotho Gorgeous synthesize Fantastic haptic Soft communities payment Gloves Fantastic program"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "LC",
    aeTime: "",
    missingInfo: true
  },
  {
    id: "4437846",
    name: { first: "Madonna", middle: "", last: "Douglas" },
    contactInformation: {
      phone: "(610) 654-1556",
      address: { line1: "2768 Petra Junction", line2: "", city: "Fargo", state: "PA", zip: "38765" },
      parent1: "Douglas, Reyes",
      parent2: "Douglas, Eldridge"
    },
    lastInteractionTime: 1638368963453,
    currentBalance: 0,
    lastPaymentDate: 1642825656224,
    status: "INQ",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641998799047,
        text: "algorithm Kenya Handmade Nebraska Producer Texas e-markets wireless Creative value-added quantify Chicken orange Buckinghamshire drive value-added"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "5879494",
    name: { first: "Gaetano", middle: "", last: "Durgan" },
    contactInformation: {
      phone: "(610) 162-5245",
      address: { line1: "309 Mitchell Spur", line2: "", city: "Kennewick", state: "PA", zip: "11189-4976" },
      parent1: "Durgan, Vern",
      parent2: "Durgan, Rachel"
    },
    lastInteractionTime: 1640565571191,
    currentBalance: 66.58613107553637,
    lastPaymentDate: 1635309329500,
    status: "DT",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [
      { date: 1635465600000, text: "inquired" },
      { date: 1636084800000, text: "academic-evaluation" }
    ],
    notes: [
      {
        date: 1641969748821,
        text: "Coordinator Shoes violet Intuitive generate Cambridgeshire withdrawal Health capacitor sensor Producer Afghani multi-byte primary"
      }
    ],
    assignedTo: "",
    ready: false,
    service: "LC",
    aeTime: "10:00"
  },
  {
    id: "2356888",
    name: { first: "Lucious", middle: "", last: "Emard" },
    contactInformation: {
      phone: "(610) 960-5160",
      address: { line1: "61509 White Brooks", line2: "", city: "Sioux City", state: "PA", zip: "41766" },
      parent1: "Emard, Jaylon",
      parent2: "Emard, Else"
    },
    lastInteractionTime: 1638076832062,
    currentBalance: 0,
    lastPaymentDate: 1641563598394,
    status: "INQ",
    startingToday: false,
    alert: true,
    alertDescription: alertDescriptions[0],
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642173043754,
        text: "Tuna methodical Cambridgeshire Honduras Salad Customizable haptic bandwidth e-tailers Toys scalable Oklahoma Web extranet frictionless New Peso"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "9848443",
    name: { first: "Gunner", middle: "", last: "Fahey" },
    contactInformation: {
      phone: "(610) 752-9873",
      address: { line1: "83952 Lind Turnpike", line2: "", city: "El Cajon", state: "PA", zip: "52438" },
      parent1: "Fahey, Madie",
      parent2: "Fahey, Bethany"
    },
    lastInteractionTime: 1640506706014,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [
      {
        id: "student-sched-11",
        days: [
          {
            id: "day-sched-01",
            day: "monday",
            start: Utils.parse24HourTime("13:00"),
            end: Utils.parse24HourTime("14:00")
          }
        ],
        effective: moment().subtract(135, "days").valueOf(),
        expiration: moment().add(69, "days").valueOf(),
        serviceSubject: "A1---English and Writing",
        delivery: "Center"
      }
    ],
    financials: [
      { date: 1642884609520, type: "debit", amount: 164 },
      { date: 1642889962915, type: "debit", amount: 118 },
      { date: 1642920201003, type: "credit", amount: -275 }
    ],
    histories: [
      { date: 1634256000000, text: "inquired" },
      { date: 1637971200000, text: "academic-evaluation" },
      { date: 1639353600000, text: "conference" },
      { date: 1641358800000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641681547871,
        text: "Berkshire disintermediate Gloves Managed evolve index paradigms recontextualize info-mediaries portal Cotton streamline Concrete Kip Delaware"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "0879714",
    name: { first: "Jarrod", middle: "", last: "Fay" },
    contactInformation: {
      phone: "(610) 401-6780",
      address: { line1: "7354 Koepp Shore", line2: "", city: "Findlay", state: "PA", zip: "13780" },
      parent1: "Fay, Alia",
      parent2: "Fay, Dulce"
    },
    lastInteractionTime: 1642013420323,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "DROP",
    startingToday: false,
    alert: true,
    alertDescription: alertDescriptions[0],
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1640865197342,
        text: "panel invoice compress ability Hryvnia Fords invoice Ergonomic British compelling mobile Synergistic withdrawal Delaware exploit dynamic Rue program Games"
      }
    ],
    assignedTo: "7601447",
    ready: false,
    service: "LC",
    aeTime: "15:30"
  },
  {
    id: "0066345",
    name: { first: "Aniyah", middle: "", last: "Feest" },
    contactInformation: {
      phone: "(610) 669-4864",
      address: {
        line1: "98420 Mustafa Fields",
        line2: "",
        city: "Missouri City",
        state: "PA",
        zip: "03594"
      },
      parent1: "Feest, Estevan",
      parent2: "Feest, Emile"
    },
    lastInteractionTime: 1638061314672,
    currentBalance: 0,
    lastPaymentDate: 1637176103411,
    status: "ENR",
    startingToday: false,
    alert: true,
    alertDescription: alertDescriptions[1],
    schedules: [],
    financials: [
      { date: 1642914059834, type: "debit", amount: 18 },
      { date: 1642922259881, type: "credit", amount: -378 },
      { date: 1642942416409, type: "debit", amount: 204 }
    ],
    histories: [
      { date: 1633147200000, text: "inquired" },
      { date: 1638057600000, text: "academic-evaluation" },
      { date: 1639526400000, text: "conference" },
      { date: 1643068800000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642157037289,
        text: "generation Gloves Alaska Virginia Samoa Tugrik implement firewall Barbados hack Dollar Metical deposit solid solutions panel aggregate ADP"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "9679874",
    name: { first: "Martine", middle: "", last: "Fisher" },
    contactInformation: {
      phone: "(610) 267-2372",
      address: {
        line1: "0979 Robel Knolls",
        line2: "",
        city: "Apple Valley",
        state: "PA",
        zip: "98872-9611"
      },
      parent1: "Fisher, Whitney",
      parent2: "Fisher, Cristina"
    },
    lastInteractionTime: 1641522460902,
    currentBalance: 0,
    lastPaymentDate: 1636258156006,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [
      { date: 1635465600000, text: "inquired" },
      { date: 1636084800000, text: "academic-evaluation" },
      { date: 1640217600000, text: "conference" }
    ],
    notes: [
      {
        date: 1641178745615,
        text: "yellow Washington Avon Steel Licensed website Inverse CSS neural quantifying bleeding-edge Refined Mill Cuban"
      }
    ],
    assignedTo: "7601447",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "5178337",
    name: { first: "Rhea", middle: "", last: "Gislason" },
    contactInformation: {
      phone: "(610) 517-0407",
      address: {
        line1: "1475 Augustine Drive",
        line2: "",
        city: "Ontario",
        state: "PA",
        zip: "27354-2738"
      },
      parent1: "Gislason, Katarina",
      parent2: "Gislason, Tierra"
    },
    lastInteractionTime: 1638847265103,
    currentBalance: 0,
    lastPaymentDate: 1635638205613,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642701699627,
        text: "Small purple International e-commerce Robust Handcrafted Realigned Koruna one-to-one area tangible Clothing National Shoes Strategist"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "4443753",
    name: { first: "Makayla", middle: "", last: "Goodwin" },
    contactInformation: {
      phone: "(610) 854-5671",
      address: {
        line1: "4430 Bernier Street",
        line2: "",
        city: "Grand Forks",
        state: "PA",
        zip: "37065-1397"
      },
      parent1: "Goodwin, Aiden",
      parent2: "Goodwin, Rahsaan"
    },
    lastInteractionTime: 1642756587784,
    currentBalance: 0,
    lastPaymentDate: 1636117215413,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642115907668,
        text: "monitor Washington blue Games Markets Bedfordshire Wisconsin Mississippi Concrete Legacy Shore Beauty Avon Plains Accountability Syrian payment alarm reboot Factors Global Human value-added Point"
      }
    ],
    assignedTo: "7601447",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "0015456",
    name: { first: "Dimitri", middle: "", last: "Gorczany" },
    contactInformation: {
      phone: "(610) 494-5846",
      address: { line1: "31301 Crona Summit", line2: "", city: "Berwyn", state: "PA", zip: "90564" },
      parent1: "Gorczany, Breanne",
      parent2: "Gorczany, Eliezer"
    },
    lastInteractionTime: 1636000839322,
    currentBalance: 0,
    lastPaymentDate: 1638516264266,
    status: "ENR",
    startingToday: true,
    alert: true,
    alertDescription: alertDescriptions[2],
    schedules: [],
    financials: [
      { date: 1642885794833, type: "debit", amount: 28 },
      { date: 1642904860410, type: "debit", amount: 217 },
      { date: 1642920041910, type: "credit", amount: -116 }
    ],
    histories: [
      { date: 1633406400000, text: "inquired" },
      { date: 1637020800000, text: "academic-evaluation" },
      { date: 1640131200000, text: "conference" },
      { date: 1642723200000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641907893292,
        text: "plug-and-play Landing Product Small Kentucky national brand open-source bus Movies Soap deposit indigo Strategist Hills Electronics Credit Oregon Sleek Corner Ball Bedfordshire"
      }
    ],
    assignedTo: "7601447",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "4305139",
    name: { first: "Einar", middle: "", last: "Grady" },
    contactInformation: {
      phone: "(610) 666-5727",
      address: {
        line1: "51335 Vandervort Shoals",
        line2: "",
        city: "New Haven",
        state: "PA",
        zip: "51118"
      },
      parent1: "Grady, Tevin",
      parent2: "Grady, Alfonso"
    },
    lastInteractionTime: 1641602458866,
    currentBalance: 335.4221944632422,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642872646818, type: "debit", amount: 71 },
      { date: 1642924306050, type: "debit", amount: 26 },
      { date: 1642948287235, type: "credit", amount: -282 }
    ],
    histories: [
      { date: 1634688000000, text: "inquired" },
      { date: 1636934400000, text: "academic-evaluation" },
      { date: 1639785600000, text: "conference" },
      { date: 1641186000000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641625806577,
        text: "Quality Regional enable interactive next Synergistic quantifying pink Account Tanzania Concrete Enhanced Account Montenegro Fantastic Colombian calculate payment cyan evolve wireless payment"
      }
    ],
    assignedTo: "7601447",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "7808033",
    name: { first: "Daija", middle: "", last: "Halvorson" },
    contactInformation: {
      phone: "(610) 981-1373",
      address: { line1: "587 Bednar Mount", line2: "", city: "Beaumont", state: "PA", zip: "80615" },
      parent1: "Halvorson, Terry",
      parent2: "Halvorson, Lelia"
    },
    lastInteractionTime: 1641895966995,
    currentBalance: 0,
    lastPaymentDate: 1639959281719,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642304579723,
        text: "composite Usability collaboration Investment JSON 1080p Division ability Jewelery primary Engineer Wooden lime ability Christmas Cambridgeshire Enhanced Managed Steel bluetooth Web Books virtual Chief"
      }
    ],
    assignedTo: "3091227",
    ready: false,
    service: "LC",
    aeTime: "",
    missingInfo: true
  },
  {
    id: "1307601",
    name: { first: "Willa", middle: "", last: "Hane" },
    contactInformation: {
      phone: "(610) 770-1219",
      address: { line1: "1556 Margie Mill", line2: "", city: "Mission", state: "PA", zip: "12232" },
      parent1: "Hane, Isaias",
      parent2: "Hane, Phoebe"
    },
    lastInteractionTime: 1641904905598,
    currentBalance: 55.80920848676034,
    lastPaymentDate: 1640500773646,
    status: "ENR",
    startingToday: false,
    alert: true,
    alertDescription: alertDescriptions[3],
    schedules: [],
    financials: [
      { date: 1642887804205, type: "debit", amount: 161 },
      { date: 1642908258092, type: "credit", amount: -424 },
      { date: 1642947153586, type: "debit", amount: 43 }
    ],
    histories: [
      { date: 1634860800000, text: "inquired" },
      { date: 1636675200000, text: "academic-evaluation" },
      { date: 1638507600000, text: "conference" },
      { date: 1642636800000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641358214266,
        text: "open-source bypassing maroon protocol Engineer Wyoming Outdoors teal Lithuania Cotton synthesizing methodical orchestration alarm sensor Soap Soap synthesizing"
      }
    ],
    assignedTo: "9146060",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "4017466",
    name: { first: "Elva", middle: "", last: "Hegmann" },
    contactInformation: {
      phone: "(610) 486-8734",
      address: {
        line1: "363 Beahan Mountains",
        line2: "",
        city: "Missoula",
        state: "PA",
        zip: "58433-9903"
      },
      parent1: "Hegmann, Jaleel",
      parent2: "Hegmann, Alene"
    },
    lastInteractionTime: 1639381002642,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: true,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642881010133, type: "debit", amount: 269 },
      { date: 1642893344850, type: "credit", amount: -579 },
      { date: 1642921829032, type: "debit", amount: 93 }
    ],
    histories: [
      { date: 1634083200000, text: "inquired" },
      { date: 1638057600000, text: "academic-evaluation" },
      { date: 1639958400000, text: "conference" },
      { date: 1641358800000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1640968959917,
        text: "Ergonomic 24/7 embrace Berkshire Buckinghamshire bifurcated systemic Avon Manor rich Forward synergies Fish input Mexican"
      }
    ],
    assignedTo: "9146060",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "4612118",
    name: { first: "Priscilla", middle: "", last: "Hettinger" },
    contactInformation: {
      phone: "(610) 224-6435",
      address: { line1: "90532 Heller Spurs", line2: "", city: "Amarillo", state: "PA", zip: "53139" },
      parent1: "Hettinger, Geoffrey",
      parent2: "Hettinger, Ebony"
    },
    lastInteractionTime: 1636423861027,
    currentBalance: 330.6768507827722,
    lastPaymentDate: 1635672152838,
    status: "ENR",
    startingToday: false,
    alert: true,
    alertDescription: alertDescriptions[4],
    schedules: [],
    financials: [
      { date: 1642874527339, type: "debit", amount: 99 },
      { date: 1642880359189, type: "credit", amount: -444 },
      { date: 1642933905286, type: "debit", amount: 101 }
    ],
    histories: [
      { date: 1633579200000, text: "inquired" },
      { date: 1636084800000, text: "academic-evaluation" },
      { date: 1639267200000, text: "conference" },
      { date: 1641618000000, text: "enrolled" }
    ],
    notes: [{ date: 1640527481979, text: "Tasty portals Shoes Radial hub Identity Integration real-time" }],
    assignedTo: "1993477",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "2391106",
    name: { first: "Watson", middle: "", last: "Hilll" },
    contactInformation: {
      phone: "(610) 664-5955",
      address: { line1: "8832 Larson Club", line2: "", city: "Baton Rouge", state: "PA", zip: "46125" },
      parent1: "Hilll, Edmund",
      parent2: "Hilll, Adrian"
    },
    lastInteractionTime: 1636250698637,
    currentBalance: 0,
    lastPaymentDate: 1641750939119,
    status: "ENR",
    startingToday: false,
    alert: true,
    alertDescription: alertDescriptions[2],
    schedules: [],
    financials: [
      { date: 1642914254747, type: "debit", amount: 79 },
      { date: 1642923377631, type: "credit", amount: -276 },
      { date: 1642951508786, type: "debit", amount: 238 }
    ],
    histories: [
      { date: 1634601600000, text: "inquired" },
      { date: 1636848000000, text: "academic-evaluation" },
      { date: 1638939600000, text: "conference" },
      { date: 1642377600000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642950879451,
        text: "Guam Beauty capacitor indexing Visionary Cambridgeshire Ball Intelligent integrate haptic Investment Shirt expedite Nepalese"
      }
    ],
    assignedTo: "9322861",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "0990759",
    name: { first: "Clement", middle: "", last: "Hilpert" },
    contactInformation: {
      phone: "(610) 039-2336",
      address: {
        line1: "6084 Welch Extensions",
        line2: "",
        city: "Castro Valley",
        state: "PA",
        zip: "16262-0469"
      },
      parent1: "Hilpert, Dorothy",
      parent2: "Hilpert, Uriel"
    },
    lastInteractionTime: 1635416307193,
    currentBalance: 99.83583728295598,
    lastPaymentDate: 1642722549052,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642894736974, type: "credit", amount: -559 },
      { date: 1642902091993, type: "debit", amount: 216 },
      { date: 1642903231928, type: "debit", amount: 120 }
    ],
    histories: [
      { date: 1634774400000, text: "inquired" },
      { date: 1636934400000, text: "academic-evaluation" },
      { date: 1639526400000, text: "conference" },
      { date: 1642032000000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642815577594,
        text: "Specialist Personal B2B incubate Granite Identity Books payment fuchsia Jamahiriya Handcrafted"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "0283301",
    name: { first: "Magdalen", middle: "", last: "Hodkiewicz" },
    contactInformation: {
      phone: "(610) 194-8034",
      address: {
        line1: "662 Hermiston Trail",
        line2: "",
        city: "Santa Fe",
        state: "PA",
        zip: "77371-6876"
      },
      parent1: "Hodkiewicz, Shea",
      parent2: "Hodkiewicz, Bradford"
    },
    lastInteractionTime: 1641422177032,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642872984311, type: "debit", amount: 208 },
      { date: 1642911747940, type: "debit", amount: 71 },
      { date: 1642941007246, type: "credit", amount: -403 }
    ],
    histories: [
      { date: 1633665600000, text: "inquired" },
      { date: 1635739200000, text: "academic-evaluation" },
      { date: 1639094400000, text: "conference" },
      { date: 1643068800000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641451293281,
        text: "Cotton Engineer Cambridgeshire generate Inlet Producer navigate Fresh Table Drives up secondary encompassing"
      }
    ],
    assignedTo: "3091227",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "3096527",
    name: { first: "Erich", middle: "", last: "Hoeger" },
    contactInformation: {
      phone: "(610) 434-9755",
      address: {
        line1: "4369 Delphia Valley",
        line2: "",
        city: "Fort Smith",
        state: "PA",
        zip: "03865-0134"
      },
      parent1: "Hoeger, Cassandre",
      parent2: "Hoeger, Reginald"
    },
    lastInteractionTime: 1636984559532,
    currentBalance: 0,
    lastPaymentDate: 1641011827949,
    status: "DROP",
    startingToday: false,
    alert: true,
    alertDescription: alertDescriptions[1],
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642899498405,
        text: "Crossing Rupee AGP Shore Directives extensible user-centric Shirt Avon PCI Cotton bottom-line Arizona Washington Product"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "9105884",
    name: { first: "Quinton", middle: "", last: "Hoeger" },
    contactInformation: {
      phone: "(610) 182-5990",
      address: { line1: "3227 Leilani Coves", line2: "", city: "Westland", state: "PA", zip: "70517-5151" },
      parent1: "Hoeger, Joanne",
      parent2: "Hoeger, Elmer"
    },
    lastInteractionTime: 1642914889153,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: true,
    alert: true,
    alertDescription: alertDescriptions[4],
    schedules: [],
    financials: [
      { date: 1642907225292, type: "credit", amount: -242 },
      { date: 1642915071878, type: "debit", amount: 41 },
      { date: 1642924613491, type: "debit", amount: 290 }
    ],
    histories: [
      { date: 1633406400000, text: "inquired" },
      { date: 1637452800000, text: "academic-evaluation" },
      { date: 1640563200000, text: "conference" },
      { date: 1643500800000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641949731584,
        text: "harness copy Avon bleeding-edge methodologies Rapid process Azerbaijanian scalable Bermuda Row"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "9121589",
    name: { first: "Dorian", middle: "", last: "Howe" },
    contactInformation: {
      phone: "(610) 714-1712",
      address: {
        line1: "5874 Kilback Trafficway",
        line2: "",
        city: "Coon Rapids",
        state: "PA",
        zip: "49364-3817"
      },
      parent1: "Howe, Sibyl",
      parent2: "Howe, Randall"
    },
    lastInteractionTime: 1639806312592,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642895950720, type: "debit", amount: 43 },
      { date: 1642927386617, type: "credit", amount: -101 },
      { date: 1642953462780, type: "debit", amount: 198 }
    ],
    histories: [
      { date: 1634169600000, text: "inquired" },
      { date: 1637280000000, text: "academic-evaluation" },
      { date: 1640390400000, text: "conference" },
      { date: 1642896000000, text: "enrolled" }
    ],
    notes: [
      { date: 1640399787448, text: "administration Leu open-source Nuevo International Usability bus visionary" }
    ],
    assignedTo: "9322861",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "6403209",
    name: { first: "Antone", middle: "", last: "Hudson" },
    contactInformation: {
      phone: "(610) 946-8282",
      address: { line1: "48155 Jonas Ranch", line2: "", city: "West Seneca", state: "PA", zip: "22099" },
      parent1: "Hudson, Rebeca",
      parent2: "Hudson, Fritz"
    },
    lastInteractionTime: 1638294690845,
    currentBalance: 0,
    lastPaymentDate: 1641019080720,
    status: "CONF",
    interimConference: true,
    startingToday: false,
    alert: false,
    alertDescription: alertDescriptions[0],
    schedules: [],
    financials: [],
    histories: [],
    notes: [{ date: 1641526273657, text: "Camp Small Investor help-desk payment Camp Soft Quality quantify protocol" }],
    assignedTo: "1274142",
    ready: false,
    service: "LC",
    aeTime: "10:00"
  },
  {
    id: "2712248",
    name: { first: "Kasey", middle: "", last: "Jaskolski" },
    contactInformation: {
      phone: "(610) 419-4755",
      address: { line1: "6803 Romaguera Loaf", line2: "", city: "Mishawaka", state: "PA", zip: "78041" },
      parent1: "Jaskolski, Trevor",
      parent2: "Jaskolski, Simone"
    },
    lastInteractionTime: 1640557054815,
    currentBalance: 133.6624466727124,
    lastPaymentDate: 0,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642603323411,
        text: "ubiquitous Concrete driver parse ADP Kwacha deliverables Kentucky Grocery payment Cambridgeshire Implemented paradigms hack Health withdrawal dot-com Micronesia Roads hacking generation hard"
      }
    ],
    assignedTo: "9322861",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "3738016",
    name: { first: "Luisa", middle: "", last: "Jast" },
    contactInformation: {
      phone: "(610) 351-2637",
      address: { line1: "874 Jennifer Field", line2: "", city: "Bowie", state: "PA", zip: "51142" },
      parent1: "Jast, Woodrow",
      parent2: "Jast, Shemar"
    },
    lastInteractionTime: 1642526414047,
    currentBalance: 0,
    lastPaymentDate: 1635401741694,
    status: "INQ",
    startingToday: false,
    alert: true,
    alertDescription: alertDescriptions[2],
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642432320685,
        text: "intuitive Steel Bacon Keyboard reintermediate withdrawal generation Soap purple payment Kansas Fully-configurable auxiliary input next-generation Loan Handcrafted array"
      }
    ],
    assignedTo: "9146060",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "0423221",
    name: { first: "Greyson", middle: "", last: "Jenkins" },
    contactInformation: {
      phone: "(610) 394-0182",
      address: {
        line1: "2521 Sanford Lodge",
        line2: "",
        city: "Paramount",
        state: "PA",
        zip: "37385-1111"
      },
      parent1: "Jenkins, Estefania",
      parent2: "Jenkins, Merlin"
    },
    lastInteractionTime: 1636093395829,
    currentBalance: 0,
    lastPaymentDate: 1642168030374,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642874057512, type: "debit", amount: 142 },
      { date: 1642899636034, type: "credit", amount: -142 },
      { date: 1642930102021, type: "debit", amount: 249 }
    ],
    histories: [
      { date: 1633406400000, text: "inquired" },
      { date: 1637971200000, text: "academic-evaluation" },
      { date: 1638853200000, text: "conference" },
      { date: 1641013200000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641283485626,
        text: "Investor Data ADP indigo innovate Metrics Integrated synergies protocol Director Producer Massachusetts User-friendly"
      }
    ],
    assignedTo: "7601447",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "7524527",
    name: { first: "Roderick", middle: "", last: "Jenkins" },
    contactInformation: {
      phone: "(610) 007-4247",
      address: { line1: "556 Lorena Creek", line2: "", city: "Novato", state: "PA", zip: "93362-1434" },
      parent1: "Jenkins, Amaya",
      parent2: "Jenkins, Van"
    },
    lastInteractionTime: 1638258229313,
    currentBalance: 0,
    lastPaymentDate: 1639701174579,
    status: "INQ",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642725510147,
        text: "Pants Direct alarm Paradigm Hat Lodge Towels Auto calculate sensor robust Plastic override blockchains Functionality matrices"
      }
    ],
    assignedTo: "9322861",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "5647904",
    name: { first: "Tyler", middle: "", last: "Johns" },
    contactInformation: {
      phone: "(610) 582-3171",
      address: {
        line1: "3782 Connor Trafficway",
        line2: "",
        city: "Rancho Palos Verdes",
        state: "PA",
        zip: "39870"
      },
      parent1: "Johns, Maryjane",
      parent2: "Johns, Jewell"
    },
    lastInteractionTime: 1641320984636,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641412177148,
        text: "Lane supply-chains Multi-channelled Gateway Architect Bacon GB Handcrafted world-class Computers wireless Usability Associate content-based"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "7955096",
    name: { first: "Aileen", middle: "", last: "Kassulke" },
    contactInformation: {
      phone: "(610) 146-4017",
      address: { line1: "3250 Hayes Loop", line2: "", city: "Gilbert", state: "PA", zip: "88221" },
      parent1: "Kassulke, Kamille",
      parent2: "Kassulke, Eric"
    },
    lastInteractionTime: 1639675278449,
    currentBalance: 278.4900238208293,
    lastPaymentDate: 1639092417524,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642889976852, type: "debit", amount: 129 },
      { date: 1642916310246, type: "debit", amount: 51 },
      { date: 1642944995358, type: "credit", amount: -429 }
    ],
    histories: [
      { date: 1634256000000, text: "inquired" },
      { date: 1636257600000, text: "academic-evaluation" },
      { date: 1640304000000, text: "conference" },
      { date: 1641704400000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641887631713,
        text: "Dynamic Marketing Salad Assistant 1080p Comoro non-volatile recontextualize Netherlands Shoes Buckinghamshire Mandatory Customer California Fresh Borders Soap override bus real-time"
      }
    ],
    assignedTo: "3091227",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "6401517",
    name: { first: "Leopold", middle: "", last: "Kessler" },
    contactInformation: {
      phone: "(610) 385-3094",
      address: { line1: "3827 Grant Burgs", line2: "", city: "Annandale", state: "PA", zip: "73542-2745" },
      parent1: "Kessler, Marcos",
      parent2: "Kessler, Kayden"
    },
    lastInteractionTime: 1641659678080,
    currentBalance: 162.0292149558534,
    lastPaymentDate: 0,
    status: "DROP",
    interimConference: true,
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642430250536,
        text: "Account Groves full-range magenta Technician Orchestrator Turnpike homogeneous efficient access Global Senior"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "ST",
    aeTime: "11:00"
  },
  {
    id: "9789036",
    name: { first: "Casandra", middle: "", last: "King" },
    contactInformation: {
      phone: "(610) 220-6720",
      address: { line1: "90254 Mae Way", line2: "", city: "Canton", state: "PA", zip: "79553-1021" },
      parent1: "King, Antonetta",
      parent2: "King, Maynard"
    },
    lastInteractionTime: 1641215286086,
    currentBalance: 327.39091258306263,
    lastPaymentDate: 1638179936970,
    status: "DT",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642079742006,
        text: "Metrics virtual Checking Car SCSI turquoise Account Bedfordshire Bacon back-end challenge African Avon up invoice Wisconsin Islands"
      }
    ],
    assignedTo: "3091227",
    ready: false,
    service: "LC",
    aeTime: "14:00"
  },
  {
    id: "7544521",
    name: { first: "Suzanne", middle: "", last: "Krajcik" },
    contactInformation: {
      phone: "(610) 466-1722",
      address: {
        line1: "46268 Stiedemann Spur",
        line2: "",
        city: "Pittsfield",
        state: "PA",
        zip: "05975-5904"
      },
      parent1: "Krajcik, Stephon",
      parent2: "Krajcik, Lewis"
    },
    lastInteractionTime: 1639434906717,
    currentBalance: 0,
    lastPaymentDate: 1637702642797,
    status: "DROP",
    startingToday: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642602573784,
        text: "Consultant Steel Mouse Pound Orchestrator Table copy paradigm compress bandwidth-monitored XML connecting Berkshire"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "6424174",
    name: { first: "Bret", middle: "", last: "Kunde" },
    contactInformation: {
      phone: "(610) 116-8946",
      address: { line1: "39305 Gibson Islands", line2: "", city: "Des Plaines", state: "PA", zip: "62844" },
      parent1: "Kunde, Gilbert",
      parent2: "Kunde, Percy"
    },
    lastInteractionTime: 1636048089386,
    currentBalance: 0,
    lastPaymentDate: 1642442094628,
    status: "CONF",
    interimConference: true,
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [{ date: 1641907739451, text: "flexibility Benin Rubber lime Pound Towels Tools interface" }],
    assignedTo: "9146060",
    ready: false,
    service: "ST",
    aeTime: "09:00"
  },
  {
    id: "6489716",
    name: { first: "Bernardo", middle: "", last: "Labadie" },
    contactInformation: {
      phone: "(610) 960-9032",
      address: {
        line1: "0977 Romaguera Shores",
        line2: "",
        city: "Antioch",
        state: "PA",
        zip: "32131-2256"
      },
      parent1: "Labadie, Carmelo",
      parent2: "Labadie, Duane"
    },
    lastInteractionTime: 1641720412062,
    currentBalance: 375.0983088462126,
    lastPaymentDate: 1640998736286,
    status: "INQ",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641475346960,
        text: "Loan SMTP Rubber Usability Loan Infrastructure Buckinghamshire teal challenge Cotton Implementation evolve Salad Cotton Forint synthesizing exploit Strategist Ball Delaware indigo"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "4444692",
    name: { first: "Reynold", middle: "", last: "Langosh" },
    contactInformation: {
      phone: "(610) 606-4505",
      address: { line1: "7141 Jerald Fall", line2: "", city: "Blacksburg", state: "PA", zip: "72340" },
      parent1: "Langosh, Benny",
      parent2: "Langosh, Sigmund"
    },
    lastInteractionTime: 1639298737559,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "INQ",
    startingToday: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [{ date: 1641987783628, text: "Stand-alone Chips AI Gloves Republic Metrics user synthesize Utah" }],
    assignedTo: "8563230",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "6761818",
    name: { first: "Mekhi", middle: "", last: "Larkin" },
    contactInformation: {
      phone: "(610) 925-3191",
      address: { line1: "6534 Blanda Pines", line2: "", city: "Lynchburg", state: "PA", zip: "01545-8305" },
      parent1: "Larkin, Kory",
      parent2: "Larkin, Jeffrey"
    },
    lastInteractionTime: 1642259282073,
    currentBalance: 219.81993385727552,
    lastPaymentDate: 0,
    status: "INQ",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641801633136,
        text: "deploy hack harness cyan THX Web microchip frictionless Solutions indexing Integrated SMS payment copy Valleys Soft hack Computer Books"
      }
    ],
    assignedTo: "9146060",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "3569752",
    name: { first: "Chelsey", middle: "", last: "Lehner" },
    contactInformation: {
      phone: "(610) 677-4523",
      address: { line1: "7959 Pascale Canyon", line2: "", city: "Brandon", state: "PA", zip: "81375" },
      parent1: "Lehner, Letitia",
      parent2: "Lehner, Samantha"
    },
    lastInteractionTime: 1638075799982,
    currentBalance: 0,
    lastPaymentDate: 1637337035119,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1640393477412,
        text: "Table and Sports Cotton matrix blue bypass Total synthesize sensor digital standardization silver Investor Account open-source navigating Personal"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "3187321",
    name: { first: "Fermin", middle: "", last: "Lehner" },
    contactInformation: {
      phone: "(610) 827-9284",
      address: { line1: "6219 Elnora Court", line2: "", city: "Cincinnati", state: "PA", zip: "84601" },
      parent1: "Lehner, Demarco",
      parent2: "Lehner, Wilton"
    },
    lastInteractionTime: 1637899257099,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1640971731903,
        text: "RAM Island tan e-commerce Avon index Dollar Creative Hat Movies maximized Arizona reboot firewall input Iowa incubate Awesome Ferry Gorgeous Fresh optical Bike orchestration"
      }
    ],
    assignedTo: "9146060",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "5694971",
    name: { first: "Keira", middle: "", last: "Lesch" },
    contactInformation: {
      phone: "(610) 532-0001",
      address: { line1: "121 Kevon Trail", line2: "", city: "Moore", state: "PA", zip: "75316" },
      parent1: "Lesch, Urban",
      parent2: "Lesch, Andreanne"
    },
    lastInteractionTime: 1635958723012,
    currentBalance: 0,
    lastPaymentDate: 1641909811468,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642898250121, type: "debit", amount: 128 },
      { date: 1642942895359, type: "credit", amount: -189 },
      { date: 1642953440688, type: "debit", amount: 248 }
    ],
    histories: [
      { date: 1634428800000, text: "inquired" },
      { date: 1636434000000, text: "academic-evaluation" },
      { date: 1639872000000, text: "conference" },
      { date: 1642032000000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641701227924,
        text: "open-source Kansas Rustic Morocco index Netherlands enhance responsive Rustic THX overriding"
      }
    ],
    assignedTo: "3091227",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "4441987",
    name: { first: "Dock", middle: "", last: "Little" },
    contactInformation: {
      phone: "(610) 004-4654",
      address: { line1: "18292 Stehr Loaf", line2: "", city: "Burbank", state: "PA", zip: "05898-1747" },
      parent1: "Little, Jayde",
      parent2: "Little, Jerod"
    },
    lastInteractionTime: 1640510579184,
    currentBalance: 140.70024773222116,
    lastPaymentDate: 0,
    status: "DT",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641208929498,
        text: "Soap Dinar Tools hard Games navigate RSS Cheese Licensed Generic black Customizable Account Won"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "LC",
    aeTime: "11:00"
  },
  {
    id: "8834838",
    name: { first: "Florencio", middle: "", last: "Lockman" },
    contactInformation: {
      phone: "(610) 411-1722",
      address: {
        line1: "006 Marquardt Cape",
        line2: "",
        city: "Rancho Cordova",
        state: "PA",
        zip: "39872-9341"
      },
      parent1: "Lockman, Norbert",
      parent2: "Lockman, Rodrick"
    },
    lastInteractionTime: 1635880188598,
    currentBalance: 0,
    lastPaymentDate: 1642514462110,
    status: "ENR",
    startingToday: true,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642872954551, type: "debit", amount: 11 },
      { date: 1642881321176, type: "credit", amount: -509 },
      { date: 1642942439202, type: "debit", amount: 199 }
    ],
    histories: [
      { date: 1635379200000, text: "inquired" },
      { date: 1636848000000, text: "academic-evaluation" },
      { date: 1640563200000, text: "conference" },
      { date: 1642723200000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1640747932933,
        text: "sticky Shirt orchestrate Fresh Intelligent channels deposit application Fundamental Jersey website invoice Incredible Louisiana 24 Product Account Metal"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "6789338",
    name: { first: "Pedro", middle: "", last: "Lubowitz" },
    contactInformation: {
      phone: "(610) 846-3709",
      address: {
        line1: "3669 Carmen Course",
        line2: "",
        city: "Asheville",
        state: "PA",
        zip: "68133-0142"
      },
      parent1: "Lubowitz, Connor",
      parent2: "Lubowitz, Daniela"
    },
    lastInteractionTime: 1638984023657,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1640574743480,
        text: "Texas mobile Borders Checking Iceland transmitting B2C methodical Ramp regional Lithuania Checking Knoll Course Progressive Buckinghamshire Metal Chicken Balanced mission-critical feed"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "9856603",
    name: { first: "Fritz", middle: "", last: "Marks" },
    contactInformation: {
      phone: "(610) 475-8960",
      address: { line1: "662 Lilla Fords", line2: "", city: "Perth Amboy", state: "PA", zip: "86413" },
      parent1: "Marks, Wilton",
      parent2: "Marks, Donato"
    },
    lastInteractionTime: 1637811927344,
    currentBalance: 217.13617701032004,
    lastPaymentDate: 1639781467785,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642115554047,
        text: "RAM blue Avon Designer supply-chains Pound Plastic Berkshire Licensed alliance interface deposit Garden Virginia directional users implementation National COM cutting-edge Fresh"
      }
    ],
    assignedTo: "3091227",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "9069831",
    name: { first: "Wayne", middle: "", last: "Marks" },
    contactInformation: {
      phone: "(610) 894-7152",
      address: {
        line1: "0281 McCullough Path",
        line2: "",
        city: "Catonsville",
        state: "PA",
        zip: "15098-9699"
      },
      parent1: "Marks, Dolores",
      parent2: "Marks, Olga"
    },
    lastInteractionTime: 1640545384888,
    currentBalance: 0,
    lastPaymentDate: 1641297161686,
    status: "DROP",
    startingToday: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641692559395,
        text: "Granite eco-centric ADP bypass Borders payment Sausages Officer Corporate logistical copy override Soft Steel Berkshire Movies Steel calculating copying"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "1292956",
    name: { first: "Cheyenne", middle: "", last: "Mayert" },
    contactInformation: {
      phone: "(610) 740-7098",
      address: {
        line1: "59337 Dietrich Lights",
        line2: "",
        city: "Baltimore",
        state: "PA",
        zip: "44590-0982"
      },
      parent1: "Mayert, Nakia",
      parent2: "Mayert, Sadye"
    },
    lastInteractionTime: 1637309071909,
    currentBalance: 0,
    lastPaymentDate: 1639510088617,
    status: "DROP",
    startingToday: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641609162798,
        text: "methodologies virtual incentivize quantify Kids Fields Granite Reactive bus Riel Concrete Representative"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "6329897",
    name: { first: "Jefferey", middle: "", last: "McKenzie" },
    contactInformation: {
      phone: "(610) 434-5151",
      address: { line1: "49504 Dee Route", line2: "", city: "Reno", state: "PA", zip: "47336" },
      parent1: "McKenzie, Buford",
      parent2: "McKenzie, Tamia"
    },
    lastInteractionTime: 1637506729791,
    currentBalance: 0,
    lastPaymentDate: 1641918217504,
    status: "INQ",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642446461986,
        text: "Sausages toolset sexy Borders back-end copy Balboa withdrawal Portugal Leone RAM navigating SSL Steel Plastic Producer Vatu Dynamic alliance"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "1584270",
    name: { first: "Angie", middle: "", last: "Monahan" },
    contactInformation: {
      phone: "(610) 967-6661",
      address: { line1: "852 Heaney Harbors", line2: "", city: "Bozeman", state: "PA", zip: "30633-0259" },
      parent1: "Monahan, Michael",
      parent2: "Monahan, Janet"
    },
    lastInteractionTime: 1639600442620,
    currentBalance: 389.77377790151377,
    lastPaymentDate: 1636489705626,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642873469078, type: "debit", amount: 87 },
      { date: 1642880087676, type: "credit", amount: -39 },
      { date: 1642891769501, type: "debit", amount: 70 }
    ],
    histories: [
      { date: 1633060800000, text: "inquired" },
      { date: 1636502400000, text: "academic-evaluation" },
      { date: 1638421200000, text: "conference" },
      { date: 1642550400000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641152501072,
        text: "Principal Planner Generic withdrawal Plastic Islands Auto middleware state Lead Incredible PCI"
      }
    ],
    assignedTo: "7601447",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "0652312",
    name: { first: "Michel", middle: "", last: "Monahan" },
    contactInformation: {
      phone: "(610) 056-9931",
      address: {
        line1: "2728 Harris Summit",
        line2: "",
        city: "Bellingham",
        state: "PA",
        zip: "84333-1843"
      },
      parent1: "Monahan, Barry",
      parent2: "Monahan, Vicente"
    },
    lastInteractionTime: 1638857916419,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642938683982, type: "debit", amount: 33 },
      { date: 1642945804300, type: "debit", amount: 264 },
      { date: 1642948782108, type: "credit", amount: -459 }
    ],
    histories: [
      { date: 1635206400000, text: "inquired" },
      { date: 1637280000000, text: "academic-evaluation" },
      { date: 1638680400000, text: "conference" },
      { date: 1641013200000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1640377157720,
        text: "Principal Movies bi-directional Berkshire Monitored Frozen Summit Strategist web-readiness Crest Concrete magnetic"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "6804114",
    name: { first: "Ignacio", middle: "", last: "Nitzsche" },
    contactInformation: {
      phone: "(610) 178-9745",
      address: { line1: "454 Jimmy Square", line2: "", city: "Irondequoit", state: "PA", zip: "18113" },
      parent1: "Nitzsche, Diana",
      parent2: "Nitzsche, Dell"
    },
    lastInteractionTime: 1641908467004,
    currentBalance: 0,
    lastPaymentDate: 1642708029449,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642872148082, type: "credit", amount: -250 },
      { date: 1642914300231, type: "debit", amount: 128 },
      { date: 1642918279410, type: "debit", amount: 89 }
    ],
    histories: [
      { date: 1634342400000, text: "inquired" },
      { date: 1636588800000, text: "academic-evaluation" },
      { date: 1638680400000, text: "conference" },
      { date: 1641099600000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642843424763,
        text: "Research Refined Market orchid Associate Loan Cotton Guam Customer CFA Barbados Bike invoice dot-com Data Incredible ivory"
      }
    ],
    assignedTo: "9322861",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "1579886",
    name: { first: "Lyric", middle: "", last: "Nitzsche" },
    contactInformation: {
      phone: "(610) 839-4195",
      address: { line1: "8272 Sabina View", line2: "", city: "Kissimmee", state: "PA", zip: "04761" },
      parent1: "Nitzsche, Darren",
      parent2: "Nitzsche, Pablo"
    },
    lastInteractionTime: 1637331012700,
    currentBalance: 0,
    lastPaymentDate: 1635998541853,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642883142184, type: "debit", amount: 98 },
      { date: 1642907547051, type: "credit", amount: -583 },
      { date: 1642943227564, type: "debit", amount: 57 }
    ],
    histories: [
      { date: 1634688000000, text: "inquired" },
      { date: 1637712000000, text: "academic-evaluation" },
      { date: 1639785600000, text: "conference" },
      { date: 1643068800000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1640708449046,
        text: "Chair Hat Accountability drive Table Nevada orange hack synthesize THX payment Consultant Plains systematic olive Keyboard embrace Wooden architect THX payment Central B2C Account"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "3335916",
    name: { first: "Marley", middle: "", last: "O'Kon" },
    contactInformation: {
      phone: "(610) 925-4773",
      address: {
        line1: "298 Hermiston Unions",
        line2: "",
        city: "Johns Creek",
        state: "PA",
        zip: "46112-3395"
      },
      parent1: "O'Kon, Christophe",
      parent2: "O'Kon, Tobin"
    },
    lastInteractionTime: 1637884885476,
    currentBalance: 0,
    lastPaymentDate: 1637014059174,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642884311954, type: "debit", amount: 275 },
      { date: 1642900765341, type: "debit", amount: 117 },
      { date: 1642929473565, type: "credit", amount: -155 }
    ],
    histories: [
      { date: 1635379200000, text: "inquired" },
      { date: 1637107200000, text: "academic-evaluation" },
      { date: 1639526400000, text: "conference" },
      { date: 1641099600000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642039032066,
        text: "array cross-platform Shoes algorithm Re-engineered Industrial Account Shoes Iowa Reverse-engineered Terrace withdrawal engineer Division Table embrace moderator Dirham yellow hard Steel calculating RSS"
      }
    ],
    assignedTo: "9146060",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "8574335",
    name: { first: "Krystina", middle: "", last: "Orn" },
    contactInformation: {
      phone: "(610) 652-4947",
      address: { line1: "1225 Idella Inlet", line2: "", city: "Cary", state: "PA", zip: "86328-4035" },
      parent1: "Orn, Evie",
      parent2: "Orn, Alana"
    },
    lastInteractionTime: 1637959636069,
    currentBalance: 0,
    lastPaymentDate: 1640157108293,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642286382374,
        text: "Checking interfaces digital CSS lime Handcrafted Assurance North mesh matrix didactic Global Seamless yellow deposit Progressive B2C"
      }
    ],
    assignedTo: "9322861",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "6181352",
    name: { first: "Alexander", middle: "", last: "Ortiz" },
    contactInformation: {
      phone: "(610) 193-5994",
      address: { line1: "722 Aleen Station", line2: "", city: "Cambridge", state: "PA", zip: "84537" },
      parent1: "Ortiz, Hanna",
      parent2: "Ortiz, Anika"
    },
    lastInteractionTime: 1637634098995,
    currentBalance: 0,
    lastPaymentDate: 1639143882123,
    status: "ENR",
    startingToday: true,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642922841714, type: "credit", amount: -284 },
      { date: 1642925968202, type: "debit", amount: 28 },
      { date: 1642927415694, type: "debit", amount: 171 }
    ],
    histories: [
      { date: 1633824000000, text: "inquired" },
      { date: 1637798400000, text: "academic-evaluation" },
      { date: 1638507600000, text: "conference" },
      { date: 1642032000000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642500727498,
        text: "calculate protocol Yemen deposit Auto Industrial Rubber payment Generic invoice base"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "8309537",
    name: { first: "Deven", middle: "", last: "Pagac" },
    contactInformation: {
      phone: "(610) 875-8319",
      address: { line1: "782 Funk Club", line2: "", city: "Lewisville", state: "PA", zip: "15674-9793" },
      parent1: "Pagac, Ada",
      parent2: "Pagac, Korey"
    },
    lastInteractionTime: 1640270687608,
    currentBalance: 81.21360958403889,
    lastPaymentDate: 1640369414455,
    status: "ENR",
    startingToday: true,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642875197549, type: "credit", amount: -533 },
      { date: 1642883987659, type: "debit", amount: 193 },
      { date: 1642921742430, type: "debit", amount: 76 }
    ],
    histories: [
      { date: 1633320000000, text: "inquired" },
      { date: 1636257600000, text: "academic-evaluation" },
      { date: 1640304000000, text: "conference" },
      { date: 1642896000000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642488714210,
        text: "Card Burkina Dynamic Crossroad Forge Car Investor Chief payment Tasty Borders open-source Suriname Helena Handcrafted GB card Keyboard"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "2487030",
    name: { first: "Carlie", middle: "", last: "Pollich" },
    contactInformation: {
      phone: "(610) 301-0585",
      address: { line1: "5977 Pagac Plaza", line2: "", city: "Kansas City", state: "PA", zip: "22490" },
      parent1: "Pollich, Mabelle",
      parent2: "Pollich, Tania"
    },
    lastInteractionTime: 1640800225835,
    currentBalance: 390.038810473633,
    lastPaymentDate: 0,
    status: "INQ",
    startingToday: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641549896805,
        text: "Concrete Computers bluetooth redundant Borders withdrawal synthesizing Berkshire Loan Walk killer Macao"
      }
    ],
    assignedTo: "7601447",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "2897363",
    name: { first: "Mara", middle: "", last: "Pouros" },
    contactInformation: {
      phone: "(610) 174-9801",
      address: { line1: "525 Luigi Route", line2: "", city: "Carson", state: "PA", zip: "21790-7266" },
      parent1: "Pouros, Nellie",
      parent2: "Pouros, Dejon"
    },
    lastInteractionTime: 1635613382119,
    currentBalance: 36.76874585417592,
    lastPaymentDate: 1639945637973,
    status: "INQ",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641864027325,
        text: "Borders payment Designer primary Shoes haptic Account Towels encompassing e-commerce structure grey Dollar Coordinator Meadows implementation Streamlined deliverables Senior infrastructures"
      }
    ],
    assignedTo: "7601447",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "0013032",
    name: { first: "Eudora", middle: "", last: "Purdy" },
    contactInformation: {
      phone: "(610) 092-5555",
      address: { line1: "92736 Rashad Meadow", line2: "", city: "Indio", state: "PA", zip: "07021-1603" },
      parent1: "Purdy, Janessa",
      parent2: "Purdy, Mason"
    },
    lastInteractionTime: 1642934167948,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: true,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642896325227, type: "debit", amount: 34 },
      { date: 1642948949178, type: "credit", amount: -72 },
      { date: 1642953928317, type: "debit", amount: 206 }
    ],
    histories: [
      { date: 1634169600000, text: "inquired" },
      { date: 1637452800000, text: "academic-evaluation" },
      { date: 1640736000000, text: "conference" },
      { date: 1642550400000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642326233208,
        text: "hierarchy Port Open-architected Intelligent Bypass USB motivating Liaison Overpass repurpose Handmade quantify copying"
      }
    ],
    assignedTo: "7601447",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "7943964",
    name: { first: "Aylin", middle: "", last: "Quitzon" },
    contactInformation: {
      phone: "(610) 821-5547",
      address: { line1: "52142 Zemlak Throughway", line2: "", city: "Newark", state: "PA", zip: "73820" },
      parent1: "Quitzon, Eldora",
      parent2: "Quitzon, Lilyan"
    },
    lastInteractionTime: 1641506545074,
    currentBalance: 0,
    lastPaymentDate: 1636020619406,
    status: "ENR",
    startingToday: false,
    schedules: [],
    financials: [
      { date: 1642901759219, type: "debit", amount: 93 },
      { date: 1642915756870, type: "credit", amount: -355 },
      { date: 1642924448453, type: "debit", amount: 65 }
    ],
    histories: [
      { date: 1633910400000, text: "inquired" },
      { date: 1637971200000, text: "academic-evaluation" },
      { date: 1639526400000, text: "conference" },
      { date: 1641531600000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1640571381537,
        text: "Account Functionality Product incentivize Cambridgeshire XML SAS SMS salmon Principal Frozen Director Jamaica deposit Isle"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "0227432",
    name: { first: "Luigi", middle: "", last: "Rice" },
    contactInformation: {
      phone: "(610) 075-6865",
      address: {
        line1: "93538 Gilbert Summit",
        line2: "",
        city: "Oceanside",
        state: "PA",
        zip: "91040-4726"
      },
      parent1: "Rice, Liana",
      parent2: "Rice, Cristian"
    },
    lastInteractionTime: 1638448074698,
    currentBalance: 0,
    lastPaymentDate: 1637412081648,
    status: "INQ",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1640695328463,
        text: "frictionless maroon Dobra Poland grey Money Ukraine Platinum clear-thinking Small Beauty online"
      }
    ],
    assignedTo: "9146060",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "1445423",
    name: { first: "Caitlyn", middle: "", last: "Ritchie" },
    contactInformation: {
      phone: "(610) 152-8250",
      address: { line1: "3858 Spinka Vista", line2: "", city: "Bell Gardens", state: "PA", zip: "71072" },
      parent1: "Ritchie, Lawson",
      parent2: "Ritchie, Myron"
    },
    lastInteractionTime: 1639318906068,
    currentBalance: 73.41853313795917,
    lastPaymentDate: 1637445378347,
    status: "DROP",
    startingToday: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642796809123,
        text: "Maryland Grass-roots system America Uruguay transmitter compressing Electronics Industrial"
      }
    ],
    assignedTo: "9322861",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "3221102",
    name: { first: "Enrique", middle: "", last: "Rodriguez" },
    contactInformation: {
      phone: "(610) 770-8507",
      address: { line1: "25583 Freddy Forge", line2: "", city: "Santa Barbara", state: "PA", zip: "21743" },
      parent1: "Rodriguez, Candace",
      parent2: "Rodriguez, Yessenia"
    },
    lastInteractionTime: 1638668152863,
    currentBalance: 348.1415473243386,
    lastPaymentDate: 0,
    status: "DT",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641838247627,
        text: "index Steel Nevada supply-chains Human human-resource Dakota Concrete Intranet next-generation networks array Concrete Advanced Gorgeous Grocery enhance"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "LC",
    aeTime: "09:30"
  },
  {
    id: "3152438",
    name: { first: "Claire", middle: "", last: "Romaguera" },
    contactInformation: {
      phone: "(610) 187-6986",
      address: { line1: "8450 Kira Estates", line2: "", city: "Lynn", state: "PA", zip: "81878" },
      parent1: "Romaguera, Kody",
      parent2: "Romaguera, Emely"
    },
    lastInteractionTime: 1640389117200,
    currentBalance: 389.8021648923232,
    lastPaymentDate: 1639927695576,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642908774847, type: "debit", amount: 128 },
      { date: 1642912680970, type: "debit", amount: 23 },
      { date: 1642930715855, type: "credit", amount: -363 }
    ],
    histories: [
      { date: 1633579200000, text: "inquired" },
      { date: 1636347600000, text: "academic-evaluation" },
      { date: 1639699200000, text: "conference" },
      { date: 1642723200000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641676465338,
        text: "Dynamic Bedfordshire Executive Reverse-engineered extensible web-enabled Guarani Buckinghamshire invoice Marshall solution Applications Implementation Group Connecticut Buckinghamshire Rustic neural schemas"
      }
    ],
    assignedTo: "9146060",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "7787183",
    name: { first: "Lionel", middle: "", last: "Romaguera" },
    contactInformation: {
      phone: "(610) 815-3725",
      address: {
        line1: "409 Mariela Garden",
        line2: "",
        city: "Rancho Cucamonga",
        state: "PA",
        zip: "59473-8177"
      },
      parent1: "Romaguera, Johnathon",
      parent2: "Romaguera, Joy"
    },
    lastInteractionTime: 1636784339444,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "CONF",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1640680769392,
        text: "Borders Berkshire Gloves primary Forward Developer Streets transmitting Toys Loop invoice Rial"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "3880266",
    name: { first: "Myrtice", middle: "", last: "Ruecker" },
    contactInformation: {
      phone: "(610) 352-8521",
      address: { line1: "28381 Monique Run", line2: "", city: "Charleston", state: "PA", zip: "36445" },
      parent1: "Ruecker, Alexandre",
      parent2: "Ruecker, Cassandre"
    },
    lastInteractionTime: 1642627987801,
    currentBalance: 0,
    lastPaymentDate: 1636275831829,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641252604748,
        text: "Uruguayo program Strategist Practical program info-mediaries state intranet Lilangeni online EXE Venezuela Associate Persevering"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "8841321",
    name: { first: "Marianna", middle: "", last: "Runolfsdottir" },
    contactInformation: {
      phone: "(610) 399-5554",
      address: { line1: "743 Kiley Heights", line2: "", city: "Azusa", state: "PA", zip: "44982" },
      parent1: "Runolfsdottir, Ruthe",
      parent2: "Runolfsdottir, Johan"
    },
    lastInteractionTime: 1641819591832,
    currentBalance: 149.95143787076677,
    lastPaymentDate: 0,
    status: "DT",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641891959708,
        text: "calculating black Chair Gorgeous Chicken synthesizing Cheese Handmade bricks-and-clicks quantify Manager online copy Corporate transmit Kroon"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "LC",
    aeTime: "14:00"
  },
  {
    id: "6714963",
    name: { first: "Stephany", middle: "", last: "Ryan" },
    contactInformation: {
      phone: "(610) 201-9446",
      address: {
        line1: "733 Altenwerth Station",
        line2: "",
        city: "Battle Creek",
        state: "PA",
        zip: "59512"
      },
      parent1: "Ryan, Madalyn",
      parent2: "Ryan, Susan"
    },
    lastInteractionTime: 1636238982018,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642402595405,
        text: "e-business lime transmitter intuitive Research Mouse Home Total explicit e-business architecture Intranet attitude-oriented cultivate solid Account"
      }
    ],
    assignedTo: "3091227",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "9061612",
    name: { first: "Savanna", middle: "", last: "Schimmel" },
    contactInformation: {
      phone: "(610) 499-3122",
      address: { line1: "67696 Durgan Club", line2: "", city: "St. Paul", state: "PA", zip: "75206" },
      parent1: "Schimmel, Charlotte",
      parent2: "Schimmel, Annamarie"
    },
    lastInteractionTime: 1639855514695,
    currentBalance: 0,
    lastPaymentDate: 1638306181483,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642276337308,
        text: "Officer Market Loan content-based yellow invoice e-services flexibility clicks-and-mortar deposit Berkshire maroon turquoise hardware Plastic"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "5442200",
    name: { first: "Shaun", middle: "", last: "Schoen" },
    contactInformation: {
      phone: "(610) 353-4244",
      address: { line1: "44884 Blanda Oval", line2: "", city: "Santa Clarita", state: "PA", zip: "44062" },
      parent1: "Schoen, Elvie",
      parent2: "Schoen, Raphaelle"
    },
    lastInteractionTime: 1642516602608,
    currentBalance: 0,
    lastPaymentDate: 1637538361701,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642880913702, type: "credit", amount: -334 },
      { date: 1642943812757, type: "debit", amount: 17 },
      { date: 1642947786868, type: "debit", amount: 131 }
    ],
    histories: [
      { date: 1635379200000, text: "inquired" },
      { date: 1637798400000, text: "academic-evaluation" },
      { date: 1639026000000, text: "conference" },
      { date: 1641186000000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641711930496,
        text: "Berkshire directional Gardens red Toys Afghani New Plaza challenge state synthesize bluetooth invoice transmitting olive Health Polarised extend ADP payment"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "9005492",
    name: { first: "Elda", middle: "", last: "Stanton" },
    contactInformation: {
      phone: "(610) 584-1958",
      address: { line1: "7264 Hand Cliff", line2: "", city: "Waukesha", state: "PA", zip: "65455" },
      parent1: "Stanton, Hazle",
      parent2: "Stanton, Elmer"
    },
    lastInteractionTime: 1635752017829,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641592879956,
        text: "proactive RSS Adaptive virtual RAM tan target protocol Mouse Producer discrete"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "1876389",
    name: { first: "Jonathan", middle: "", last: "Stehr" },
    contactInformation: {
      phone: "(610) 195-2153",
      address: { line1: "73955 Wiegand Squares", line2: "", city: "Lynwood", state: "PA", zip: "73644" },
      parent1: "Stehr, Vince",
      parent2: "Stehr, Kyla"
    },
    lastInteractionTime: 1638242346444,
    currentBalance: 0,
    lastPaymentDate: 1640858504243,
    status: "INQ",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1640901103651,
        text: "flexibility card redundant Buckinghamshire reinvent Analyst Awesome primary ivory concept Architect Korea discrete Small"
      }
    ],
    assignedTo: "9146060",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "2186641",
    name: { first: "Lessie", middle: "", last: "Stiedemann" },
    contactInformation: {
      phone: "(610) 108-0514",
      address: {
        line1: "02468 Schowalter Station",
        line2: "",
        city: "Missoula",
        state: "PA",
        zip: "94047"
      },
      parent1: "Stiedemann, Shanie",
      parent2: "Stiedemann, Rafael"
    },
    lastInteractionTime: 1642802856520,
    currentBalance: 0,
    lastPaymentDate: 1635641295908,
    status: "CONF",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641871375298,
        text: "Ball Salad bypass Refined back Music Philippines Berkshire Chicken focus capacitor Borders"
      }
    ],
    assignedTo: "1993477",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "7103928",
    name: { first: "Korey", middle: "", last: "Stokes" },
    contactInformation: {
      phone: "(610) 235-3243",
      address: {
        line1: "2796 Harold Turnpike",
        line2: "",
        city: "Lake Ridge",
        state: "PA",
        zip: "29350-9910"
      },
      parent1: "Stokes, Jeremie",
      parent2: "Stokes, Jodie"
    },
    lastInteractionTime: 1636266500260,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "DROP",
    startingToday: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [{ date: 1642705419912, text: "grey invoice Marketing Cheese Computer Wyoming Berkshire Metal" }],
    assignedTo: "8563230",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "8447936",
    name: { first: "Sylvia", middle: "", last: "Stroman" },
    contactInformation: {
      phone: "(610) 309-5468",
      address: { line1: "51555 Hoppe Station", line2: "", city: "Bethlehem", state: "PA", zip: "16638" },
      parent1: "Stroman, Otha",
      parent2: "Stroman, Camden"
    },
    lastInteractionTime: 1635927232442,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642873531738, type: "credit", amount: -99 },
      { date: 1642909486507, type: "debit", amount: 103 },
      { date: 1642921954162, type: "debit", amount: 231 }
    ],
    histories: [
      { date: 1633824000000, text: "inquired" },
      { date: 1637452800000, text: "academic-evaluation" },
      { date: 1638939600000, text: "conference" },
      { date: 1642377600000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641080576149,
        text: "stable Dollar XML Account engine protocol transition attitude-oriented maroon web Gorgeous virtual Garden Georgia neural Jordan open driver Personal Martinique Versatile Liaison"
      }
    ],
    assignedTo: "3091227",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "1789705",
    name: { first: "Mario", middle: "", last: "Thompson" },
    contactInformation: {
      phone: "(610) 721-2020",
      address: {
        line1: "79559 Vivien Station",
        line2: "",
        city: "Anaheim",
        state: "PA",
        zip: "97749-7356"
      },
      parent1: "Thompson, Kayden",
      parent2: "Thompson, Michel"
    },
    lastInteractionTime: 1639466548895,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "CONF",
    startingToday: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641365917093,
        text: "District port connecting Grocery program Chair Borders Concrete Metal bypass HTTP El Savings vortals CSS Unit full-range backing deposit"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "7193847",
    name: { first: "Francisco", middle: "", last: "Towne" },
    contactInformation: {
      phone: "(610) 679-9729",
      address: { line1: "54198 Milford Street", line2: "", city: "Lakeland", state: "PA", zip: "27349" },
      parent1: "Towne, Arvid",
      parent2: "Towne, Levi"
    },
    lastInteractionTime: 1639184570959,
    currentBalance: 0,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: true,
    schedules: [],
    financials: [
      { date: 1642868851193, type: "credit", amount: -580 },
      { date: 1642909993193, type: "debit", amount: 149 },
      { date: 1642926912947, type: "debit", amount: 133 }
    ],
    histories: [
      { date: 1633910400000, text: "inquired" },
      { date: 1638057600000, text: "academic-evaluation" },
      { date: 1639526400000, text: "conference" },
      { date: 1642204800000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1640952959818,
        text: "implementation invoice withdrawal infrastructures North Generic Republic New firewall Gibraltar California Rustic copy Specialist Computers Namibia Hat Incredible New Alaska"
      }
    ],
    assignedTo: "7601447",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "2347757",
    name: { first: "Lela", middle: "", last: "Towne" },
    contactInformation: {
      phone: "(610) 080-7162",
      address: { line1: "696 Blanda Ramp", line2: "", city: "San Mateo", state: "PA", zip: "21583-3919" },
      parent1: "Towne, Lizzie",
      parent2: "Towne, Clementine"
    },
    lastInteractionTime: 1641247257548,
    currentBalance: 0,
    lastPaymentDate: 1638350694062,
    status: "ENR",
    startingToday: false,
    schedules: [],
    financials: [
      { date: 1642909125134, type: "debit", amount: 95 },
      { date: 1642921938312, type: "debit", amount: 119 },
      { date: 1642953103448, type: "credit", amount: -503 }
    ],
    histories: [
      { date: 1633752000000, text: "inquired" },
      { date: 1637539200000, text: "academic-evaluation" },
      { date: 1640563200000, text: "conference" },
      { date: 1641013200000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1640839557741,
        text: "Manager Turnpike generate Zambia Berkshire withdrawal Towels Qatar Sierra Future Technician indigo cultivate synthesize orange Account haptic infrastructures relationships"
      }
    ],
    assignedTo: "7601447",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "7339685",
    name: { first: "Electa", middle: "", last: "Toy" },
    contactInformation: {
      phone: "(610) 704-7216",
      address: { line1: "7943 Leo Grove", line2: "", city: "Knoxville", state: "PA", zip: "49067-8068" },
      parent1: "Toy, Sean",
      parent2: "Toy, Dexter"
    },
    lastInteractionTime: 1641499677632,
    currentBalance: 0,
    lastPaymentDate: 1637238281899,
    status: "INQ",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641397521902,
        text: "alliance Salad Chair Steel input Unbranded multi-byte pink Chair Intelligent users Buckinghamshire Account Junction engine Cambridgeshire Usability Progressive clicks-and-mortar"
      }
    ],
    assignedTo: "9322861",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "7849414",
    name: { first: "Paige", middle: "", last: "Tremblay" },
    contactInformation: {
      phone: "(610) 821-6076",
      address: { line1: "527 Wiza Field", line2: "", city: "Metairie", state: "PA", zip: "55878" },
      parent1: "Tremblay, Christ",
      parent2: "Tremblay, Leif"
    },
    lastInteractionTime: 1642904918403,
    currentBalance: 0,
    lastPaymentDate: 1639478591957,
    status: "CONF",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642066130830,
        text: "bluetooth deposit Soft parse CSS compressing card parsing teal Checking Compatible e-business"
      }
    ],
    assignedTo: "9146060",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "1147335",
    name: { first: "Kevin", middle: "", last: "Tromp" },
    contactInformation: {
      phone: "(610) 211-4948",
      address: { line1: "25696 Wilber Pines", line2: "", city: "Lodi", state: "PA", zip: "10535-2737" },
      parent1: "Tromp, Malika",
      parent2: "Tromp, Rosamond"
    },
    lastInteractionTime: 1637891213073,
    currentBalance: 0,
    lastPaymentDate: 1636167182164,
    status: "CONF",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641546513301,
        text: "Dominican Global workforce Baby Account Gardens generate Account repurpose state Officer synergy service-desk azure Pizza e-markets Books maroon"
      }
    ],
    assignedTo: "3091227",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "5348499",
    name: { first: "Rogers", middle: "", last: "Turcotte" },
    contactInformation: {
      phone: "(610) 961-5942",
      address: {
        line1: "53658 Dorian Shoals",
        line2: "",
        city: "North Las Vegas",
        state: "PA",
        zip: "79817"
      },
      parent1: "Turcotte, Elliott",
      parent2: "Turcotte, Willie"
    },
    lastInteractionTime: 1639705594047,
    currentBalance: 0,
    lastPaymentDate: 1640866331592,
    status: "DROP",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      { date: 1642473483017, text: "superstructure contingency cross-platform index coherent Salad state Plastic" }
    ],
    assignedTo: "9322861",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "9576227",
    name: { first: "Nicola", middle: "", last: "Upton" },
    contactInformation: {
      phone: "(610) 227-1527",
      address: {
        line1: "75303 Hank Gateway",
        line2: "",
        city: "Coeur d'Alene",
        state: "PA",
        zip: "67461-9570"
      },
      parent1: "Upton, Osborne",
      parent2: "Upton, Edna"
    },
    lastInteractionTime: 1642308722076,
    currentBalance: 186.78286698609176,
    lastPaymentDate: 1640869853047,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642893851404, type: "debit", amount: 211 },
      { date: 1642908872649, type: "debit", amount: 77 },
      { date: 1642911900010, type: "credit", amount: -206 }
    ],
    histories: [
      { date: 1634688000000, text: "inquired" },
      { date: 1637020800000, text: "academic-evaluation" },
      { date: 1640390400000, text: "conference" },
      { date: 1642896000000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642210692989,
        text: "Gold Identity bypassing navigating Electronics Operations Wooden explicit heuristic Mission Arizona Plastic monitor e-business driver SCSI"
      }
    ],
    assignedTo: "8563230",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "1992836",
    name: { first: "Zetta", middle: "", last: "Waelchi" },
    contactInformation: {
      phone: "(610) 498-7825",
      address: { line1: "696 Sadie Radial", line2: "", city: "Hillsboro", state: "PA", zip: "32171-3189" },
      parent1: "Waelchi, Effie",
      parent2: "Waelchi, Rosamond"
    },
    lastInteractionTime: 1639654272799,
    currentBalance: 362.6238798176137,
    lastPaymentDate: 1640188634016,
    status: "ENR",
    startingToday: false,
    schedules: [],
    financials: [
      { date: 1642926989565, type: "credit", amount: -447 },
      { date: 1642931271816, type: "debit", amount: 219 },
      { date: 1642950463845, type: "debit", amount: 82 }
    ],
    histories: [
      { date: 1633060800000, text: "inquired" },
      { date: 1636934400000, text: "academic-evaluation" },
      { date: 1639526400000, text: "conference" },
      { date: 1642809600000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642838323859,
        text: "Multi-tiered user-facing Account Directives microchip SMS Missouri Up-sized haptic bypass Technician system-worthy platforms Expanded Market Georgia Configurable Licensed Tuna partnerships firewall"
      }
    ],
    assignedTo: "9146060",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "6316197",
    name: { first: "Kevon", middle: "", last: "West" },
    contactInformation: {
      phone: "(610) 777-2887",
      address: {
        line1: "4032 Jose Mills",
        line2: "",
        city: "Country Club",
        state: "PA",
        zip: "25278-2073"
      },
      parent1: "West, Macy",
      parent2: "West, Reyes"
    },
    lastInteractionTime: 1641713013028,
    currentBalance: 89.11674385819617,
    lastPaymentDate: 0,
    status: "DROP",
    startingToday: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1641772365447,
        text: "quantify Concrete Granite California neural-net Ports upward-trending engage District Personal Table XML Developer Account"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "3574773",
    name: { first: "David", middle: "", last: "Wilderman" },
    contactInformation: {
      phone: "(610) 008-3157",
      address: { line1: "9570 Leffler Ford", line2: "", city: "Avondale", state: "PA", zip: "57305-6438" },
      parent1: "Wilderman, Katheryn",
      parent2: "Wilderman, Bethel"
    },
    lastInteractionTime: 1640784386146,
    currentBalance: 58.02513450420657,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642892544775, type: "debit", amount: 77 },
      { date: 1642923854242, type: "debit", amount: 280 },
      { date: 1642931775825, type: "credit", amount: -317 }
    ],
    histories: [
      { date: 1634688000000, text: "inquired" },
      { date: 1636347600000, text: "academic-evaluation" },
      { date: 1640217600000, text: "conference" },
      { date: 1641186000000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642331475395,
        text: "Savings Operations Corporate Islands Switzerland override Cape SSL Electronics Branding withdrawal web-readiness Heights Dinar deposit Persevering conglomeration and Open-source Lead PCI white"
      }
    ],
    assignedTo: "9322861",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "5996944",
    name: { first: "Dean", middle: "", last: "Wilkinson" },
    contactInformation: {
      phone: "(610) 089-5652",
      address: {
        line1: "183 Phoebe Ridge",
        line2: "",
        city: "Niagara Falls",
        state: "PA",
        zip: "42390-9005"
      },
      parent1: "Wilkinson, Rosemary",
      parent2: "Wilkinson, Katrina"
    },
    lastInteractionTime: 1637060792480,
    currentBalance: 0,
    lastPaymentDate: 1640408763304,
    status: "INQ",
    startingToday: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1640575519780,
        text: "Mountains Buckinghamshire Re-contextualized navigating turn-key Facilitator Marketing foreground Bedfordshire Shirt Licensed PNG Shirt Malaysian digital microchip supply-chains Cambridgeshire installation Handcrafted (Bouvetoya) Configurable"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "7779907",
    name: { first: "Rosalyn", middle: "", last: "Windler" },
    contactInformation: {
      phone: "(610) 726-1041",
      address: {
        line1: "94253 Leila Park",
        line2: "",
        city: "National City",
        state: "PA",
        zip: "20526-3488"
      },
      parent1: "Windler, Pierre",
      parent2: "Windler, Martin"
    },
    lastInteractionTime: 1637618474664,
    currentBalance: 0,
    lastPaymentDate: 1639140117277,
    status: "ENR",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [
      { date: 1642898123695, type: "debit", amount: 152 },
      { date: 1642901425184, type: "debit", amount: 59 },
      { date: 1642926908984, type: "credit", amount: -87 }
    ],
    histories: [
      { date: 1633910400000, text: "inquired" },
      { date: 1636347600000, text: "academic-evaluation" },
      { date: 1639026000000, text: "conference" },
      { date: 1642291200000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1642564916673,
        text: "Dynamic dynamic Concrete withdrawal web-enabled coherent Towels Communications Macao interactive Pine Plastic Baht Fantastic"
      }
    ],
    assignedTo: "1274142",
    ready: false,
    service: "LC",
    aeTime: ""
  },
  {
    id: "5878416",
    name: { first: "Alaina", middle: "", last: "Wisozk" },
    contactInformation: {
      phone: "(610) 771-8424",
      address: { line1: "471 Lemke Rest", line2: "", city: "Orange", state: "PA", zip: "17915-3107" },
      parent1: "Wisozk, Mallory",
      parent2: "Wisozk, Ferne"
    },
    lastInteractionTime: 1642047099139,
    currentBalance: 244.84350059129673,
    lastPaymentDate: 0,
    status: "ENR",
    startingToday: false,
    schedules: [],
    financials: [
      { date: 1642871059122, type: "debit", amount: 131 },
      { date: 1642876812124, type: "credit", amount: -280 },
      { date: 1642926546261, type: "debit", amount: 274 }
    ],
    histories: [
      { date: 1633824000000, text: "inquired" },
      { date: 1637280000000, text: "academic-evaluation" },
      { date: 1639785600000, text: "conference" },
      { date: 1641358800000, text: "enrolled" }
    ],
    notes: [
      {
        date: 1641887082058,
        text: "driver Belize brand Shirt calculate Skyway one-to-one Sudan Consultant withdrawal optical"
      }
    ],
    assignedTo: "9146060",
    ready: false,
    service: "ST",
    aeTime: ""
  },
  {
    id: "2123316",
    name: { first: "Madison", middle: "", last: "Wolff" },
    contactInformation: {
      phone: "(610) 238-6036",
      address: { line1: "11432 Breitenberg Hill", line2: "", city: "Arlington", state: "PA", zip: "24121" },
      parent1: "Wolff, Efren",
      parent2: "Wolff, Hazel"
    },
    lastInteractionTime: 1642431590713,
    currentBalance: 0,
    lastPaymentDate: 1638697124575,
    status: "DT",
    startingToday: false,
    alert: false,
    schedules: [],
    financials: [],
    histories: [],
    notes: [
      {
        date: 1642834801857,
        text: "content cutting-edge withdrawal projection 24/7 input sticky Direct Implementation Research override generate Pakistan programming Object-based transmitting Grass-roots Bike Dobra"
      }
    ],
    assignedTo: "",
    ready: false,
    service: "ST",
    aeTime: "09:30"
  }
];

const parents: Types.StudentRelative[] = [];
students.forEach(current => {
  if (current?.contactInformation?.parent1) {
    parents.push({
      id: Math.random().toString(10).substr(2, 7),
      studentId: current.id,
      phone: Utils.generateSimilarPhoneNumber(current.contactInformation.phone),
      name: current.contactInformation.parent1
    } as Types.StudentRelative);
  }
  if (current?.contactInformation?.parent2) {
    parents.push({
      id: Math.random().toString(10).substr(2, 7),
      studentId: current.id,
      phone: Utils.generateSimilarPhoneNumber(current.contactInformation.phone),
      name: current.contactInformation.parent2,
      relationship: "parent"
    } as Types.StudentRelative);
  }
});

export { parents };
