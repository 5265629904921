import React, { useState, useEffect } from "react";
import moment from "moment";

export interface DateFieldProps {
  value?: number;
  mode?: "edit" | "view";
  onChange?: (newvalue: number | undefined) => void;
}

export const convertNumericDateToStringDate = (value: number | undefined) => {
  if (!value) {
    return "";
  } else {
    return moment(value).format("YYYY-MM-DD");
  }
};

export const convertStringDateToNumericDate = (value: string | undefined) => {
  if (!value) {
    return undefined;
  } else {
    return moment(value, "YYYY-MM-DD").valueOf();
  }
};

export const DateField: React.FC<DateFieldProps> = ({ value, mode, onChange }) => {
  const [localValue, setLocalValue] = useState<number | undefined>(value);
  const localOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    setLocalValue(convertStringDateToNumericDate(e.target.value));
  };

  useEffect(() => {
    onChange && onChange(localValue);
  }, [localValue, onChange]);

  if (mode === "view") {
    return <>{convertNumericDateToStringDate(localValue)}</>;
  } else {
    return (
      <input
        type="date"
        className="form-control"
        value={convertNumericDateToStringDate(localValue)}
        style={{ width: "100%" }}
        onChange={localOnChange}
      />
    );
  }
};
