import * as Types from "../types";

// todo this will require refinement later to handle more complex scenarios but this will
// do for now
export const getEffectiveSchedule = (asOf: number, schedules?: Types.WeekSchedule[] | Types.StudentSchedule[]) => {
  if (!!asOf && !!schedules && !!schedules.length) {
    const candidates = schedules.filter(s => {
      if (s.deactivated) {
        return false;
      } else if (s.effective === undefined && s.expiration === undefined) {
        return true;
      } else if (s.effective !== undefined && s.expiration === undefined) {
        return asOf >= s.effective;
      } else if (s.effective === undefined && s.expiration !== undefined) {
        return asOf <= s.expiration;
      } else if (s.effective !== undefined && s.expiration !== undefined) {
        return asOf >= s.effective && asOf <= s.expiration;
      } else {
        return false;
      }
    });
    if (candidates.length === 0) {
      return undefined;
    } else if (candidates.length > 0) {
      // todo this is the part that needs refining
      // lame temporary thing
      if (candidates.length === 2) {
        if (candidates[0].effective && candidates[1].effective) {
          if (candidates[0].effective > candidates[1].effective) {
            return candidates[0];
          } else {
            return candidates[1];
          }
        }
      }
      return candidates[0];
    }
  }

  return undefined;
};
