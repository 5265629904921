import React from "react";

import * as Types from "../../data/types";
import { ItemType } from "../../hooks/use-sortable-filterable-data";

import { GeneralList, GeneralListColumnSpecification, GeneralListDataMapHandler } from "../general-list/general-list";

export interface CenterServiceRatiosProps {
  ratios?: Types.CenterServiceRatio[];
}

export const CenterServiceRatios: React.FC<CenterServiceRatiosProps> = ({ ratios }) => {
  return (
    <div>
      <h5>Service Ratios</h5>
      <GeneralList data={ratios} mapper={ratioMapper} specs={ratioSpecs} />
    </div>
  );
};

export const ratioMapper: GeneralListDataMapHandler = function (this, ratio, index?, array?) {
  const r = ratio as Types.CenterServiceRatio;
  return {
    id: r.id,
    service: Types.serviceDescriptiveNames.find(sdn => sdn.service === r.service)?.name ?? r.service,
    code: r.service,
    ratio: `${r.toOne}:1`
  } as ItemType;
};

export const ratioSpecs: GeneralListColumnSpecification[] = [
  { title: "Service", name: "service", sorterName: "service" },
  { title: "Service Code", name: "code" },
  { title: "Ratio", name: "ratio" }
];
