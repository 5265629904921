import { useMemo, useState } from "react";

export type ItemType = Record<string, string | number | boolean | JSX.Element>;
export type DirectionType = "ascending" | "descending";

export interface SortParameters {
  key: string;
  direction: DirectionType;
}

export const containsFilter = (item: ItemType, filter: string): boolean => {
  for (const prop in item) {
    const value = item[prop].toString().toLocaleLowerCase();
    if (value.includes(filter.toLocaleLowerCase())) {
      return true;
    }
  }
  return false;
};

export const useSortableFilterableData = (
  items: ItemType[],
  sortParameters: SortParameters = { key: "", direction: "ascending" },
  pageSize = 0
) => {
  const [sort, setSort] = useState<SortParameters>(sortParameters);
  const [filter, setFilter] = useState<string>("");
  const [page, setPage] = useState<number>(0);

  const computedItems = useMemo(() => {
    const sortableItems = items.filter(i => (!!filter.trim() ? containsFilter(i, filter.trim()) : true));
    if (sort.key) {
      sortableItems.sort((a, b) => {
        if (a[sort.key] < b[sort.key]) {
          return sort.direction === "ascending" ? -1 : 1;
        } else if (a[sort.key] > b[sort.key]) {
          return sort.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sort, filter]);

  const requestSort = (key: string) => {
    let direction: DirectionType = "ascending";
    if (sort && sort.key === key && sort.direction === "ascending") {
      direction = "descending";
    }
    setSort({ key, direction });
  };

  const requestFilter = (filter: string) => {
    setFilter(filter);
  };

  const requestPage = (newPage: number) => {
    setPage(newPage);
  };

  return {
    items: computedItems,
    pagedItems: pageSize > 0 ? computedItems.slice(page * pageSize, page * pageSize + pageSize) : computedItems,
    requestSort,
    requestFilter,
    requestPage,
    page,
    totalPages: !!pageSize ? Math.ceil(computedItems.length / pageSize) : 1
  };
};
