import React from "react";
import * as Types from "../../data/types";
import * as Utils from "../../data/utils";

import { Contact } from "./tab-content/contact";
import { StudentScheduling } from "./tab-content/schedule";
// import { Financial } from "./tab-content/financial";
import { History } from "./tab-content/history";
import { Notes } from "./tab-content/notes";
import { Family } from "./tab-content/family";
import { Attendance } from "./tab-content/attendance";
import { Actions } from "../../screens/students/students";
import { useAuthentication } from "../../app/authentication-context";

export type StudentDetailTabType = "contact" | "schedule" | "financial" | "history" | "notes" | "family" | "attendance";

export interface StudentDetailTabsProps {
  student?: Types.Student;
  parents?: Types.StudentRelative[];
  mode?: "summary" | "extended";
  actions?: Actions;
}

export const StudentDetailTabs: React.FC<StudentDetailTabsProps> = ({
  student,
  parents,
  mode = "summary",
  actions
}) => {
  const { activeCenter } = useAuthentication();
  if (!student || !activeCenter) {
    return null;
  }

  return (
    <>
      <ul className="nav nav-tabs" id="student-detail-tabs">
        <li className="nav-item">
          <a className={"nav-link active"} data-bs-toggle="tab" href="#tab-contact">
            <span className="fal icon fa-mobile-android" aria-hidden="true"></span>
            Contact
          </a>
        </li>

        {mode === "extended" && (
          <li className="nav-item">
            <a className={"nav-link"} data-bs-toggle="tab" href="#tab-family">
              <span className="fal icon fa-house-leave" aria-hidden="true"></span>
              Family
            </a>
          </li>
        )}

        <li className="nav-item">
          <a className={"nav-link"} data-bs-toggle="tab" href="#tab-scheduling">
            <span className="fal icon fa-clock" aria-hidden="true"></span>
            Scheduling
          </a>
        </li>

        {mode === "extended" && (
          <li className="nav-item">
            <a className={"nav-link"} data-bs-toggle="tab" href="#tab-attendance">
              <span className="fal icon fa-calendar-check" aria-hidden="true"></span>
              Attendance
            </a>
          </li>
        )}

        {/* <li className="nav-item">
          <a className={"nav-link"} data-bs-toggle="tab" href="#tab-financial">
            <span className="fal icon fa-sack-dollar" aria-hidden="true"></span>
            Financial
          </a>
        </li> */}

        <li className="nav-item">
          <a className={"nav-link"} data-bs-toggle="tab" href="#tab-history">
            <span className="fal icon fa-clipboard" aria-hidden="true"></span>
            History
          </a>
        </li>

        <li className="nav-item">
          <a className={"nav-link"} data-bs-toggle="tab" href="#tab-notes">
            <span className="fal icon fa-sticky-note" aria-hidden="true"></span>
            Notes
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div className="tab-pane container active" id="tab-contact">
          <Contact contactInformation={student?.contactInformation} mode={mode} />
        </div>

        {mode === "extended" && (
          <div className="tab-pane container" id="tab-family">
            <Family parents={parents} />
          </div>
        )}

        <div className="tab-pane container" id="tab-scheduling">
          <StudentScheduling
            schedules={student.schedules}
            studentId={student.id}
            centerId={activeCenter.id}
            onSaveStudentSchedule={actions?.onSaveStudentSchedule}
          />
        </div>

        {mode === "extended" && (
          <div className="tab-pane container" id="tab-attendance">
            <Attendance data={Utils.constructAttendanceHistory(student)} />
          </div>
        )}

        {/* <div className="tab-pane container" id="tab-financial">
          <Financial student={student} actions={actions} />
        </div> */}

        <div className="tab-pane container" id="tab-history">
          <History data={student?.histories} />
        </div>

        <div className="tab-pane container" id="tab-notes">
          <Notes data={student?.notes} />
        </div>
      </div>
    </>
  );
};
