/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AppMenuPrimaryItemProps, AppMenuSecondaryItemGroupProps } from "./app-menu";

import * as Types from "../data/types";
import * as StudentsMocks from "../data/students";
import * as EmployeeMocks from "../data/employees";

const dashboardData = StudentsMocks.getSummaryData(StudentsMocks.students);
const activeStudents = StudentsMocks.students.filter(s => s.status !== "DROP").length;
const inactiveStudents = StudentsMocks.students.filter(s => s.status === "DROP").length;
const activeEmployees = EmployeeMocks.employees.filter(e => !e.inactive).length;
const inactiveEmployees = EmployeeMocks.employees.filter(e => e.inactive).length;

export const studentsMenuItems: AppMenuSecondaryItemGroupProps[] = [
  {
    id: "student-menu-group",
    groupLabel: "",
    items: [
      { label: "Current", route: "/current", count: activeStudents },
      { label: "Inactive", route: "/inactive", count: inactiveStudents }
    ]
  }
];

export const staffMenuItems: AppMenuSecondaryItemGroupProps[] = [
  {
    id: "staff-menu-group",
    groupLabel: "",
    items: [
      { label: "Current", route: "/current", count: activeEmployees },
      { label: "Inactive", route: "/inactive", count: inactiveEmployees }
    ]
  }
];

export const dashboardMenuItems: AppMenuSecondaryItemGroupProps[] = [
  {
    id: "dashboard-student-pipeline-group",
    groupLabel: "Student Pipeline",
    items: [
      {
        label: Types.todoItemDescriptiveNames["inquiry"],
        route: "/inquiry",
        count: dashboardData.find(dd => dd.type === "inquiry")!.count
      },
      {
        label: Types.todoItemDescriptiveNames["ae-today"],
        route: "/ae-today",
        count: dashboardData.find(dd => dd.type === "ae-today")!.count
      },
      {
        label: Types.todoItemDescriptiveNames["ae-no-conf"],
        route: "/ae-no-conf",
        count: dashboardData.find(dd => dd.type === "ae-no-conf")!.count
      },
      {
        label: Types.todoItemDescriptiveNames["initial-conference"],
        route: "/initial-conference",
        count: dashboardData.find(dd => dd.type === "initial-conference")!.count
      },
      {
        label: Types.todoItemDescriptiveNames["conf-not-enrolled"],
        route: "/conf-not-enrolled",
        count: dashboardData.find(dd => dd.type === "conf-not-enrolled")!.count
      },
      {
        label: Types.todoItemDescriptiveNames["re-enroll"],
        route: "/re-enroll",
        count: dashboardData.find(dd => dd.type === "re-enroll")!.count
      }
    ]
  },
  {
    id: "dashboard-student-relations-group",
    groupLabel: "Student Relations",
    items: [
      {
        label: Types.todoItemDescriptiveNames["attending-today"],
        route: "/attending-today",
        count: dashboardData.find(dd => dd.type === "attending-today")!.count
      },
      {
        label: Types.todoItemDescriptiveNames["balance-due"],
        route: "/balance-due",
        count: dashboardData.find(dd => dd.type === "balance-due")!.count
      },
      {
        label: Types.todoItemDescriptiveNames["interim-conf"],
        route: "/interim-conf",
        count: dashboardData.find(dd => dd.type === "interim-conf")!.count
      },
      {
        label: Types.todoItemDescriptiveNames["interim-test"],
        route: "/interim-test",
        count: dashboardData.find(dd => dd.type === "interim-test")!.count
      },
      {
        label: Types.todoItemDescriptiveNames["school-visit"],
        route: "/school-visit",
        count: dashboardData.find(dd => dd.type === "school-visit")!.count
      },
      {
        label: Types.todoItemDescriptiveNames["missing-info"],
        route: "/missing-info",
        count: dashboardData.find(dd => dd.type === "missing-info")!.count
      },
      {
        label: Types.todoItemDescriptiveNames["deactivated-sched"],
        route: "/deactivated-sched",
        count: dashboardData.find(dd => dd.type === "deactivated-sched")!.count
      }
    ]
  }
];

export const appMenuItems: AppMenuPrimaryItemProps[] = [
  {
    id: "dashboard-menu-item",
    label: "Dashboard",
    route: "/dashboard",
    iconClassName: "fal fa-tachometer-alt-fastest",
    secondaryItemGroups: dashboardMenuItems
  },
  {
    id: "centers-menu-item",
    label: "Centers",
    route: "/centers",
    iconClassName: "fal fa-school",
    secondaryItemGroups: []
  },
  {
    id: "staff-menu-item",
    label: "Staff",
    route: "/staff",
    iconClassName: "fal fa-id-card-alt",
    secondaryItemGroups: staffMenuItems
  },
  {
    id: "students-menu-item",
    label: "Students",
    route: "/students",
    iconClassName: "fal fa-users-class",
    secondaryItemGroups: studentsMenuItems
  },
  {
    id: "scheduling-menu-item",
    label: "Scheduling",
    route: "/scheduling",
    iconClassName: "fal fa-clock",
    secondaryItemGroups: []
  }
];
