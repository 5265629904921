import React from "react";
import moment from "moment";

import * as Types from "../../../data/types";
import * as Utils from "../../../data/utils";

import "./subjects.scss";

export interface PositionsProps {
  employments?: Types.Employment[];
}

export const Positions: React.FC<PositionsProps> = ({ employments }) => {
  return (
    <table className="table" data-testid="staff-details-tab-positions">
      <thead>
        <tr>
          <th>Position</th>
          <th>Start</th>
          <th>End</th>
          <th>Elig for Rehire</th>
        </tr>
      </thead>
      <tbody>
        {employments?.map((e, index) => {
          return (
            <tr key={`staff-details-tab-positions-key-${index}`} className="staff-details-tab-positions-row">
              <td style={{ whiteSpace: "nowrap", verticalAlign: "top", paddingRight: "20px" }}>{e.position}</td>
              <td>{moment(e.start).format("MMM D, YYYY")}</td>
              <td>{!!e.end ? moment(e.end).format("MMM D, YYYY") : ""}</td>
              <td>{Utils.formatNullableBoolean(e.eligibleForRehire)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
