import React, { useEffect, useState } from "react";
import { useModal } from "react-hooks-use-modal";
import { v4 as uuidv4 } from "uuid";

import * as Types from "../../data/types";
import * as CentersSlice from "../../data/centers-slice";
import { ItemType } from "../../hooks/use-sortable-filterable-data";

import { EditCenterResources } from "./edit-center-resources";

import {
  GeneralList,
  GeneralListColumnSpecification,
  GeneralListDataMapHandler,
  GeneralListItemSelectHandler
} from "../general-list/general-list";
import { ScheduleCell } from "../general-list/schedule-cell";

import "./center-resources.scss";

export interface CenterResourcesProps {
  centerId: Types.UniqueId;
  resources?: Types.CenterResource[];
  onSave?: (payload: CentersSlice.SaveCenterResourcePayload) => void;
}

export const CenterResources: React.FC<CenterResourcesProps> = ({ centerId, resources, onSave }) => {
  const [selected, setSelected] = useState<Types.CenterResource>();
  const [editing, setEditing] = useState<Types.CenterResource>();

  const onSelectItem: GeneralListItemSelectHandler = item => {
    setSelected(resources?.find(r => r.id === item.id));
  };

  const [EditCenterResourceModal, openEditCenterResourceModal, closeEditCenterResourceModal] = useModal(
    "utility-root",
    {
      preventScroll: true,
      closeOnOverlayClick: false
    }
  );

  const onActionButtonClick = (id: string) => {
    setEditing(resources?.find(tr => tr.id === id));
  };

  useEffect(() => {
    if (editing) {
      console.log("should open");
      openEditCenterResourceModal();
    } else {
      closeEditCenterResourceModal();
    }
  }, [editing, openEditCenterResourceModal, closeEditCenterResourceModal]);

  const onCancelEditing = () => {
    setEditing(undefined);
  };

  const onSaveEditing = (resource: Types.CenterResource) => {
    if (resource && resource.id && onSave) {
      onSave({ centerId, resource: { ...resource } });
      setEditing(undefined);
    }
  };

  const onAddResource = () => {
    setEditing({ id: uuidv4(), name: "", capacity: 0, schedules: [] });
  };

  return (
    <>
      <div>
        <h5>Resources</h5>
        <GeneralList
          data={resources}
          mapper={centerResourcesMapper}
          specs={centerResourcesSpecs}
          activeItem={selected?.id}
          onSelectItem={onSelectItem}
          onActionButtonClick={onActionButtonClick}
          pageSize={6}
        />
      </div>
      <div className="center-resource-toolbar">
        <button className="btn btn-primary" onClick={onAddResource}>
          Add New Resource
        </button>
      </div>

      {!!editing && editing && (
        <EditCenterResourceModal>
          <EditCenterResources
            resource={resources?.find(tr => tr.id === editing.id) ?? editing}
            onCancel={onCancelEditing}
            onSave={onSaveEditing}
          />
        </EditCenterResourceModal>
      )}
    </>
  );
};

export const centerResourcesMapper: GeneralListDataMapHandler = function (this, resource, index?, array?) {
  const r = resource as Types.CenterResource;
  const currentSchedule = r.schedules?.[0] ?? undefined;
  const schedule = <ScheduleCell schedule={currentSchedule} />;
  return {
    id: r.id,
    name: r.name,
    capacity: r.capacity ?? 0,
    schedule
  } as ItemType;
};

export const centerResourcesSpecs: GeneralListColumnSpecification[] = [
  {
    title: "Name",
    name: "name",
    sorterName: "name",
    className: "primary",
    isStrongCell: true,
    defaultSort: true
  },
  { title: "Capacity", name: "capacity", sorterName: "capacity" },
  { title: "Current Schedule", name: "schedule" },
  { title: "Activate", name: "activate", isActionButton: true, actionButtonIconClasses: "far fa-pencil" }
];
