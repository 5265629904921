import React from "react";
import { useModal } from "react-hooks-use-modal";
import Select from "react-select";

import * as Types from "../../../data/types";

import * as StudentMocks from "../../../data/students";

import "./family.scss";

const parentMap = StudentMocks.parents.map(p => {
  return { value: p.id, label: p.name };
});

export interface FamilyProps {
  parents?: Types.StudentRelative[];
}

export const Family: React.FC<FamilyProps> = ({ parents }) => {
  const [EditFamilyParent, openEditFamilyParent] = useModal("utility-root", {
    preventScroll: true
  });
  return (
    <>
      <div className="form" data-testid="student-details-tab-content-family">
        <h4>Parents &amp; Relatives</h4>
        {parents?.map((p, index) => {
          return (
            <React.Fragment key={`student-details-tab-family-parent-key-${p.id}`}>
              <div className="row">
                <div className="col form-floating">
                  <input type="text" className="form-control" value={p.name} readOnly />
                  <label>Name</label>
                </div>
                <div className="col form-floating">
                  <input type="text" className="form-control" value={p.phone} readOnly />
                  <label>Phone</label>
                </div>
              </div>

              <br />
            </React.Fragment>
          );
        })}

        <div style={{ textAlign: "right", paddingRight: "5px" }}>
          <button onClick={() => openEditFamilyParent()} className="btn btn-primary">
            Add Another
          </button>
        </div>
      </div>
      <EditFamilyParent>
        <div className="student-details-tab-content-family-edit-parent">
          <div className="row">
            <div className="col">
              <label>Add an existing person...</label>
              <Select options={parentMap} />
            </div>
          </div>
          <div className="row">
            <div className="button-container">
              <button className="btn btn-primary">Add</button>
            </div>
          </div>
          <div>
            <div>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col form-floating">
              <input type="text" className="form-control" value={""} />
              <label>Name</label>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col form-floating">
              <input type="text" className="form-control" value={""} />
              <label>Phone</label>
            </div>
          </div>
          <div className="row">
            <div className="button-container">
              <button className="btn btn-primary">Add</button>
            </div>
          </div>
        </div>
      </EditFamilyParent>
    </>
  );
};
