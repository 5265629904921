import * as Types from "./types";
import * as UsersMocks from "./users";

const subjects1: Types.SubjectType[] = ["Reading", "Math"];

const subjects2: Types.SubjectType[] = ["Reading", "English", "Math", "Science", "Writing"];

const subjects3: Types.SubjectType[] = ["Algebra", "Biology", "Chemistry", "Physics"];

const subjects4: Types.SubjectType[] = ["English", "French", "Spanish"];

const subjects5: Types.SubjectType[] = ["US History", "World History"];

const subjects14: Types.SubjectType[] = ["English", "French", "Spanish", "US History"];

const allServiceSubjects = [
  "LS---Math (HS+:9-14)",
  "LS---Verbal",
  "L1",
  "LS---Math (7-8)",
  "LS---Math (PK-6)",
  "E1",
  "A1---English and Writing",
  "A1---Math",
  "S1---Critical Reading and Writing",
  "S1---Math",
  "ST---History",
  "ST---Science",
  "ST---English",
  "ST---Foreign Languages",
  "ST---Math",
  "G1",
  "GS---Math (PK-6)",
  "GS---Math (HS+:9-14)",
  "GS---Math (7-8)",
  "GS---Verbal"
];

const serviceSubjects1 = [
  "LS---Verbal",
  "A1---English and Writing",
  "S1---Critical Reading and Writing",
  "ST---English",
  "GS---Verbal"
];

const serviceSubjects2 = [
  "LS---Math (HS+:9-14)",
  "LS---Math (7-8)",
  "A1---Math",
  "S1---Math",
  "ST---Math",
  "GS---Math (HS+:9-14)",
  "GS---Math (7-8)"
];

const serviceSubjects3 = ["ST---History", "ST---Science", "ST---English", "ST---Foreign Languages", "ST---Math"];

const serviceSubjects4 = ["LS---Math (PK-6)", "GS---Math (PK-6)"];

const serviceSubjects5 = ["A1---English and Writing", "A1---Math", "S1---Critical Reading and Writing", "S1---Math"];

const schedule1: Types.EmployeeWeekSchedule = {
  id: "emp-week-sched-01",
  effective: new Date("2021-05-31").getTime(),
  expiration: new Date("2023-12-31").getTime(),
  days: [
    {
      id: "sched-01-day-01",
      day: "monday",
      start: 540,
      end: 720,
      ratio: 1
    },
    {
      id: "sched-01-day-06",
      day: "monday",
      start: 900,
      end: 1260,
      ratio: 1
    },
    {
      id: "sched-01-day-02",
      day: "tuesday",
      start: 540,
      end: 1020,
      ratio: 1
    },
    {
      id: "sched-01-day-03",
      day: "wednesday",
      start: 540,
      end: 1020,
      ratio: 1
    },
    {
      id: "sched-01-day-04",
      day: "friday",
      start: 540,
      end: 1020,
      ratio: 1
    },
    {
      id: "sched-01-day-05",
      day: "saturday",
      start: 720,
      end: 960,
      ratio: 1
    }
  ]
};

const schedule2: Types.EmployeeWeekSchedule = {
  id: "emp-week-sched-02",
  effective: new Date("2021-04-30").getTime(),
  expiration: new Date("2025-7-31").getTime(),
  days: [
    {
      id: "sched-02-day-01",
      day: "monday",
      start: 720,
      end: 1200,
      ratio: 1
    },
    {
      id: "sched-02-day-02",
      day: "wednesday",
      start: 720,
      end: 1200,
      ratio: 1
    },
    {
      id: "sched-02-day-03",
      day: "friday",
      start: 720,
      end: 1200,
      ratio: 1
    }
  ]
};

const availability1: Types.EmployeeWeekSchedule = {
  id: "emp-week-sched-03",
  effective: new Date("2019-02-28").getTime(),
  expiration: new Date("2023-12-31").getTime(),
  days: [
    {
      id: "sched-03-day-01",
      day: "monday",
      start: 540,
      end: 1020,
      ratio: 1
    },
    {
      id: "sched-03-day-02",
      day: "tuesday",
      start: 540,
      end: 1260,
      ratio: 1
    },
    {
      id: "sched-03-day-03",
      day: "wednesday",
      start: 540,
      end: 1020,
      ratio: 1
    },
    {
      id: "sched-03-day-04",
      day: "thursday",
      start: 540,
      end: 1260,
      ratio: 1
    },
    {
      id: "sched-03-day-05",
      day: "friday",
      start: 540,
      end: 1020,
      ratio: 1
    },
    {
      id: "sched-03-day-06",
      day: "saturday",
      start: 720,
      end: 1200,
      ratio: 1
    }
  ]
};

const availability2: Types.EmployeeWeekSchedule = {
  id: "emp-week-sched-04",
  effective: new Date("2019-11-30").getTime(),
  days: [
    {
      id: "sched-04-day-01",
      day: "monday",
      start: 720,
      end: 1200,
      ratio: 1
    },
    {
      id: "sched-04-day-02",
      day: "tuesday",
      start: 540,
      end: 1020,
      ratio: 1
    },
    {
      id: "sched-04-day-03",
      day: "wednesday",
      start: 720,
      end: 1200,
      ratio: 1
    },
    {
      id: "sched-04-day-04",
      day: "thursday",
      start: 540,
      end: 1020,
      ratio: 1
    },
    {
      id: "sched-04-day-05",
      day: "friday",
      start: 720,
      end: 1200,
      ratio: 1
    },
    {
      id: "sched-04-day-06",
      day: "sunday",
      start: 900,
      end: 1140,
      ratio: 1
    }
  ]
};

const ratioAvailabilitySchedule1: Types.EmployeeWeekSchedule = {
  id: "emp-week-sched-04",
  effective: new Date("2019-11-30").getTime(),
  days: [
    {
      id: "sched-04-day-01",
      day: "monday",
      start: 720,
      end: 780,
      ratio: 1
    },
    {
      id: "sched-04-day-07",
      day: "monday",
      start: 780,
      end: 1140,
      ratio: 4
    },
    {
      id: "sched-04-day-08",
      day: "monday",
      start: 1140,
      end: 1200,
      ratio: 1
    },
    {
      id: "sched-04-day-02",
      day: "tuesday",
      start: 540,
      end: 1020,
      ratio: 1
    },
    {
      id: "sched-04-day-03",
      day: "wednesday",
      start: 720,
      end: 1200,
      ratio: 1
    },
    {
      id: "sched-04-day-04",
      day: "thursday",
      start: 540,
      end: 1020,
      ratio: 1
    },
    {
      id: "sched-04-day-05",
      day: "friday",
      start: 720,
      end: 1200,
      ratio: 1
    },
    {
      id: "sched-04-day-06",
      day: "sunday",
      start: 900,
      end: 1140,
      ratio: 1
    }
  ]
};

const dashboardPermission: Types.Permission = { name: "Dashboard", read: "Allow" };
const centersPermission: Types.Permission = { name: "Centers", read: "Allow" };
const staffPermission: Types.Permission = { name: "Staff", read: "Allow" };
const studentsPermission: Types.Permission = { name: "Students", read: "Allow" };
const schedulingPermission: Types.Permission = { name: "Scheduling", read: "Allow" };
const financialsPermission: Types.Permission = { name: "Financials", read: "Allow" };
const reportingPermission: Types.Permission = { name: "Reporting", read: "Allow" };

const tutorRole: Types.Role = { name: "Tutor", permissions: [dashboardPermission, centersPermission, staffPermission] };
const directerRole: Types.Role = {
  name: "Director",
  permissions: [
    { ...dashboardPermission, write: "Allow" },
    { ...centersPermission, write: "Allow" },
    { ...staffPermission, write: "Allow" },
    { ...schedulingPermission, write: "Allow" },
    { ...financialsPermission, write: "Allow" },
    { ...studentsPermission, write: "Allow" },
    { ...reportingPermission, write: "Allow" }
  ]
};

const adminRole: Types.Role = {
  name: "Admin",
  permissions: [
    { ...dashboardPermission },
    { ...centersPermission },
    { ...staffPermission },
    { ...schedulingPermission, write: "Allow" },
    { ...financialsPermission, write: "Allow" },
    { ...studentsPermission, write: "Allow" },
    { ...reportingPermission }
  ]
};

const tutorAccess: Types.AccessControl = {
  enabled: true,
  roles: [tutorRole],
  permissions: [],
  centers: []
};

const adminAccess: Types.AccessControl = {
  enabled: true,
  roles: [adminRole],
  permissions: [],
  centers: []
};

const directorAccess: Types.AccessControl = {
  enabled: true,
  roles: [directerRole],
  permissions: [],
  centers: []
};

export const employees: Types.Employee[] = [
  {
    id: "1274142",
    name: "Bayer, Giovani",
    employments: [
      {
        position: "Tutor",
        start: new Date(2012, 2, 23).getTime(),
        end: new Date(2017, 5, 1).getTime(),
        eligibleForRehire: true
      },
      {
        position: "Tutor",
        start: new Date(2019, 3, 15).getTime()
      }
    ],
    contactInformation: {
      phone: "(610) 238-6036",
      address: { line1: "11432 Breitenberg Hill", line2: "", city: "Arlington", state: "PA", zip: "24121" },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects1,
    availability: [ratioAvailabilitySchedule1],
    accessControl: tutorAccess,
    supportedDeliveryMethods: "both",
    serviceSubjects: [...allServiceSubjects.filter(s => !s.includes("History"))]
  },
  {
    id: "7601447",
    name: "Graham, Estrella",
    employments: [
      {
        position: "Tutor",
        start: new Date("2015-06-07").getTime()
      }
    ],
    contactInformation: {
      phone: "(610) 771-8424",
      address: { line1: "471 Lemke Rest", line2: "", city: "Orange", state: "PA", zip: "17915-3107" },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects2,
    availability: [schedule1],
    accessControl: tutorAccess,
    supportedDeliveryMethods: "in-person",
    serviceSubjects: [...serviceSubjects1]
  },
  {
    id: "9322861",
    name: "Green, Adonis",
    employments: [
      {
        position: "Tutor",
        start: new Date("2000-01-27").getTime()
      }
    ],
    contactInformation: {
      phone: "(610) 726-1041",
      address: {
        line1: "94253 Leila Park",
        line2: "",
        city: "National City",
        state: "PA",
        zip: "20526-3488"
      },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects3,
    availability: [availability2],
    accessControl: tutorAccess,
    supportedDeliveryMethods: "virtual",
    serviceSubjects: [...serviceSubjects2]
  },
  {
    id: "0900973",
    name: "Hamill, Nelle",
    employments: [
      {
        position: "Tutor",
        start: new Date("2007-11-12").getTime()
      }
    ],
    contactInformation: {
      phone: "(610) 089-5652",
      address: {
        line1: "183 Phoebe Ridge",
        line2: "",
        city: "Niagara Falls",
        state: "PA",
        zip: "42390-9005"
      },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects14,
    availability: [schedule2],
    accessControl: tutorAccess,
    supportedDeliveryMethods: "in-person",
    serviceSubjects: [...allServiceSubjects]
  },
  {
    id: "9146060",
    name: "Hilll, Hassie",
    employments: [
      {
        position: "Tutor",
        start: new Date("2003-02-04").getTime()
      }
    ],
    contactInformation: {
      phone: "(610) 008-3157",
      address: { line1: "9570 Leffler Ford", line2: "", city: "Avondale", state: "PA", zip: "57305-6438" },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects5,
    availability: [availability2],
    accessControl: tutorAccess,
    supportedDeliveryMethods: "in-person",
    serviceSubjects: [...serviceSubjects3]
  },
  {
    id: "8563230",
    name: "Kuvalis, Saige",
    employments: [
      {
        position: "Tutor",
        start: new Date("2008-09-18").getTime()
      }
    ],
    contactInformation: {
      phone: "(610) 777-2887",
      address: {
        line1: "4032 Jose Mills",
        line2: "",
        city: "Country Club",
        state: "PA",
        zip: "25278-2073"
      },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects1,
    availability: [availability1],
    accessControl: tutorAccess,
    supportedDeliveryMethods: "in-person",
    serviceSubjects: [...serviceSubjects4]
  },
  {
    id: "3091227",
    name: "Nienow, Kareem",
    employments: [
      {
        position: "Tutor",
        start: new Date("2017-09-15").getTime()
      }
    ],
    contactInformation: {
      phone: "(610) 498-7825",
      address: { line1: "696 Sadie Radial", line2: "", city: "Hillsboro", state: "PA", zip: "32171-3189" },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects2,
    availability: [availability2],
    accessControl: tutorAccess,
    supportedDeliveryMethods: "in-person",
    serviceSubjects: [...serviceSubjects5]
  },
  {
    id: "1993477",
    name: "Swift, Jose",
    employments: [
      {
        position: "Tutor",
        start: new Date("2005-08-21").getTime()
      }
    ],
    contactInformation: {
      phone: "(610) 227-1527",
      address: {
        line1: "75303 Hank Gateway",
        line2: "",
        city: "Coeur d'Alene",
        state: "PA",
        zip: "67461-9570"
      },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects3,
    availability: [availability1],
    accessControl: tutorAccess,
    supportedDeliveryMethods: "virtual",
    serviceSubjects: [...allServiceSubjects]
  },
  {
    id: "1979877",
    name: "Yianolis, Karen",
    employments: [
      {
        position: "Tutor",
        start: new Date("1996-03-23").getTime(),
        end: new Date(2003, 10, 30).getTime(),
        eligibleForRehire: true
      },
      {
        position: "Admin",
        start: new Date(2003, 11, 1).getTime()
      }
    ],
    contactInformation: {
      phone: "(610) 961-5942",
      address: {
        line1: "53658 Dorian Shoals",
        line2: "",
        city: "North Las Vegas",
        state: "PA",
        zip: "79817"
      },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects4,
    availability: [availability2],
    accessControl: adminAccess,
    supportedDeliveryMethods: "in-person",
    serviceSubjects: [...serviceSubjects1]
  },
  {
    id: "1986577",
    name: "McKraken, Angus",
    employments: [
      {
        position: "Admin",
        start: new Date("1999-01-13").getTime()
      }
    ],
    contactInformation: {
      phone: "(610) 211-4948",
      address: { line1: "25696 Wilber Pines", line2: "", city: "Lodi", state: "PA", zip: "10535-2737" },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: [],
    availability: [availability1],
    accessControl: { ...adminAccess, permissions: [{ ...dashboardPermission, write: "Allow" }] },
    supportedDeliveryMethods: "both",
    serviceSubjects: [...allServiceSubjects]
  },
  {
    id: "1955327",
    name: "Garcia, Juan",
    employments: [
      {
        position: "Tutor",
        start: new Date(1995, 6, 8).getTime(),
        end: new Date(2012, 2, 22).getTime(),
        eligibleForRehire: true
      },
      {
        position: "Admin",
        start: new Date(2012, 2, 23).getTime(),
        end: new Date(2017, 5, 1).getTime(),
        eligibleForRehire: true
      },
      {
        position: "Director",
        start: new Date(2017, 5, 2).getTime()
      }
    ],
    contactInformation: {
      phone: "(610) 821-6076",
      address: { line1: "527 Wiza Field", line2: "", city: "Metairie", state: "PA", zip: "55878" },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects5,
    availability: [availability2],
    accessControl: {
      ...directorAccess,
      centers: [UsersMocks.centers[UsersMocks.centers.length - 1], UsersMocks.centers[UsersMocks.centers.length - 2]]
    },
    supportedDeliveryMethods: "in-person",
    serviceSubjects: [...serviceSubjects2]
  },
  {
    id: "1999966",
    name: "Edwards, Michael",
    employments: [
      {
        position: "Tutor",
        start: new Date("2006-03-23").getTime(),
        end: new Date("2012-06-01").getTime(),
        eligibleForRehire: true
      }
    ],
    inactive: true,
    contactInformation: {
      phone: "(610) 704-7216",
      address: { line1: "7943 Leo Grove", line2: "", city: "Knoxville", state: "PA", zip: "49067-8068" },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects1,
    accessControl: { ...tutorAccess, enabled: false },
    supportedDeliveryMethods: "both",
    serviceSubjects: [...serviceSubjects3]
  },
  {
    id: "1991258",
    name: "LaFleur, Michelle",
    employments: [
      {
        position: "Tutor",
        start: new Date("2021-11-18").getTime(),
        end: new Date("2022-01-06").getTime(),
        eligibleForRehire: false
      }
    ],
    inactive: true,
    contactInformation: {
      phone: "(610) 080-7162",
      address: { line1: "696 Blanda Ramp", line2: "", city: "San Mateo", state: "PA", zip: "21583-3919" },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects2,
    accessControl: { ...tutorAccess, enabled: false },
    supportedDeliveryMethods: "in-person",
    serviceSubjects: [...serviceSubjects4]
  },
  {
    id: "2536722",
    name: "Rasputin, Gregor",
    employments: [
      {
        position: "Tutor",
        start: new Date("2019-05-07").getTime(),
        end: new Date("2021-03-22").getTime(),
        eligibleForRehire: true
      }
    ],
    inactive: true,
    contactInformation: {
      phone: "(610) 679-9729",
      address: { line1: "54198 Milford Street", line2: "", city: "Lakeland", state: "PA", zip: "27349" },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects3,
    accessControl: { ...tutorAccess, enabled: false },
    supportedDeliveryMethods: "both",
    serviceSubjects: [...serviceSubjects5]
  },
  {
    id: "12741490",
    name: "Zucherash, Jon",
    employments: [
      {
        position: "Tutor",
        start: new Date(2012, 2, 23).getTime(),
        end: new Date(2017, 5, 1).getTime(),
        eligibleForRehire: true
      },
      {
        position: "Tutor",
        start: new Date(2019, 3, 15).getTime()
      }
    ],
    contactInformation: {
      phone: "(610) 238-6036",
      address: { line1: "11432 Kinslow Hill", line2: "", city: "Arlington", state: "PA", zip: "24121" },
      businessEmail: "",
      personalEmail: "",
      emergencyContactName: "",
      emergencyContactPhone: ""
    },
    subjects: subjects1,
    availability: [availability2],
    accessControl: tutorAccess,
    supportedDeliveryMethods: "both",
    serviceSubjects: [...serviceSubjects1]
  }
];
