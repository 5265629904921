import React from "react";

import { Header } from "../../components/header/header";
import { ToDoSidebar } from "../../components/to-do-sidebar/to-do-sidebar";
import { useAuthentication } from "../../app/authentication-context";

export const Financials: React.FC = () => {
  const { user, activeCenter, logout } = useAuthentication();
  return (
    <>
      <Header activeTab="Financials" user={user} center={activeCenter} logout={logout} />
      <main>
        <div className="row">
          <div className="columns small-2 sidebar">
            <ToDoSidebar />
          </div>
          <div className="columns small-5 listing">
            <h1
              style={{
                textAlign: "center",
                marginTop: "60px"
              }}
            >
              The Financials Screen
            </h1>
          </div>
          <div className="columns small-5 details">&nbsp;</div>
        </div>
      </main>
    </>
  );
};
