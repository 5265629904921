import React from "react";

import * as Types from "../../data/types";
import * as Utils from "../../data/utils";

import "./schedule-brief-cell.scss";

export interface ScheduleBriefCellProps {
  schedule?: Types.WeekSchedule;
}

export const ScheduleBriefCell: React.FC<ScheduleBriefCellProps> = ({ schedule }) => {
  return (
    <table className="schedule-brief-cell">
      <tbody>
        {schedule?.days.map(d => {
          return (
            <tr key={d.id}>
              <td>{`${Utils.capitalize(d.day ?? "")}s`}</td>
              <td>{`${Utils.formatTime(d.start)} - ${Utils.formatTime(d.end)}`}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
