import React from "react";
import moment from "moment";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";

import { Draggable } from "react-beautiful-dnd";

import * as Types from "../types";
import * as Utils from "../utils";

import { compileStaffForSchedule } from "./compile-staff-for-schedule";
import { isAvailableForTimeRange } from "./is-available-for-time-range";
import { scheduleTimeSequence } from "./schedule-time-sequence";
import { compileStudentsForSchedule } from "./compile-students-for-schedule";
import { compileResourcesForSchedule } from "./compile-resources-for-schedule";

const intervalMinutes = 30;

export interface DroppableLookup {
  id: string;
  targetId: string;
  time: number;
}

export interface MasterSchedule {
  results: Array<Types.ScheduleCell[]>;
  lookups: DroppableLookup[];
  students: Types.StudentScheduleChartData[];
}

export const compileMasterSchedule = (
  day: number,
  staff: Types.Employee[],
  students: Types.Student[],
  center: Types.Center,
  events: Types.ScheduleEvent[],
  serviceFilter: string,
  options: string[],
  onScheduleEventEdit: Types.ScheduleEventEdit
): MasterSchedule => {
  const results: Array<Types.ScheduleCell[]> = [];
  const lookups: DroppableLookup[] = [];
  const applicableStaff = compileStaffForSchedule(day, staff, serviceFilter);
  const applicableStudents = compileStudentsForSchedule(day, intervalMinutes, students, events, staff, center);
  const applicableResources = compileResourcesForSchedule(day, center?.resources);

  const includeSubjectLine = options.includes("subjects");
  const includeLocationLine = options.includes("locations");

  // first row day/date (header of time column) in first cell followed by tutors in alpha order
  const firstRow: Types.ScheduleCell[] = [
    {
      key: uuidv4(),
      content: moment(day).format("dddd, M/D"),
      type: "leader-leader",
      className: "leader-leader day-header sticky-column-first sticky-row-first"
    },
    {
      key: uuidv4(),
      content: "Unassigned",
      type: "leader-tutor",
      className: "leader-tutor unassigned-header sticky-column-second sticky-row-first"
    }
  ];
  applicableStaff.forEach(st => {
    firstRow.push({
      key: uuidv4(),
      content: st.name,
      type: "leader-tutor",
      className: "leader-tutor day-header sticky-row-first"
    });
  });

  if (applicableResources.length) {
    firstRow.push({ key: uuidv4(), content: " ", className: "vertical-gap sticky-row-first", type: "vertical-gap" });
    applicableResources.forEach(r => {
      firstRow.push({
        key: uuidv4(),
        content: r.name,
        type: "leader-tutor",
        className: "leader-tutor day-header sticky-row-first"
      });
    });
  }

  results.push(firstRow);

  // second row "Subjects" label followed by each tutor's subject(s)
  const secondRow: Types.ScheduleCell[] = [
    {
      key: uuidv4(),
      content: "Subject(s)",
      type: "leader-leader",
      className: "leader-leader subject-header sticky-column-first sticky-row-second"
    },
    {
      key: uuidv4(),
      content: "",
      type: "leader-subject",
      className: "leader-subject unassigned-empty-header sticky-column-second sticky-row-second"
    }
  ];
  applicableStaff.forEach(st => {
    secondRow.push({
      key: uuidv4(),
      content: st.subjects,
      type: "leader-subject",
      className: "leader-subject subject-header sticky-row-second"
    });
  });

  if (applicableResources.length) {
    secondRow.push({ key: uuidv4(), content: " ", className: "vertical-gap sticky-row-second", type: "vertical-gap" });
    applicableResources.forEach(r => {
      secondRow.push({
        key: uuidv4(),
        content: r.capacity,
        type: "leader-subject",
        className: "leader-subject subject-header capacity-header sticky-row-second"
      });
    });
  }

  if (includeSubjectLine) {
    results.push(secondRow);
  }

  // third row "Zoom/In-Person" label followed by each tutor's support for either/both
  const thirdRow: Types.ScheduleCell[] = [
    {
      key: uuidv4(),
      content: "Location",
      type: "leader-leader",
      className: "leader-leader delivery-header sticky-column-first sticky-row-third"
    },
    {
      key: uuidv4(),
      content: "",
      type: "leader-delivery",
      className: "leader-delivery unassigned-empty-header sticky-column-second sticky-row-third"
    }
  ];
  applicableStaff.forEach(st => {
    thirdRow.push({
      key: uuidv4(),
      content: st.delivery,
      type: "leader-delivery",
      className: "leader-delivery delivery-header sticky-row-third"
    });
  });

  if (applicableResources.length) {
    thirdRow.push({ key: uuidv4(), content: " ", className: "vertical-gap sticky-row-third", type: "vertical-gap" });
    applicableResources.forEach(r => {
      thirdRow.push({
        key: uuidv4(),
        content: " ",
        type: "leader-delivery",
        className: "leader-delivery delivery-header sticky-row-third"
      });
    });
  }

  if (includeLocationLine) {
    results.push(thirdRow);
  }

  const sequence = scheduleTimeSequence(day, center, intervalMinutes);
  let current = sequence.next();
  while (!current.done) {
    // console.log(moment(current.value).format("LLL"));
    const timeRangeStart = moment(current.value);
    const timeRangeEnd = moment(current.value).add(intervalMinutes, "m");
    //const timeRange = `${timeRangeStart.format("LT")} - ${timeRangeEnd.format("LT")}`;
    //const timeRange = `${timeRangeStart.format("hh:mm")} - ${timeRangeEnd.format("hh:mm")}`;
    const timeRange: React.ReactNode = (
      <>
        <div>{timeRangeStart.format("hh:mm")}</div>
        <div>&#8211;</div>
        <div>{timeRangeEnd.format("hh:mm")}</div>
        <br />
        <br />
      </>
    );
    const unassigned = applicableStudents.filter(
      st => st.start >= timeRangeStart.valueOf() && st.start < timeRangeEnd.valueOf() && !st.staffId && !st.resourceId
    );
    const currentRow: Types.ScheduleCell[] = [
      { key: uuidv4(), content: timeRange, type: "leader-time", className: "leader-time sticky-column-first" },
      {
        key: uuidv4(),
        content: <Students students={unassigned} onScheduleEventEdit={onScheduleEventEdit} isUnassigned={true} />,
        type: "data",
        className: "data-cell sticky-column-second",
        datetime: timeRangeStart.valueOf()
      }
    ];

    for (let i = 0; i < applicableStaff.length; i++) {
      const available = isAvailableForTimeRange(
        timeRangeStart.valueOf(),
        timeRangeEnd.valueOf(),
        applicableStaff[i].availability,
        center
      );

      const assigned = applicableStudents.filter(
        st =>
          st.start >= timeRangeStart.valueOf() &&
          st.start < timeRangeEnd.valueOf() &&
          st.staffId === applicableStaff[i].id
      );
      if (assigned.length) {
        const data = {
          key: uuidv4(),
          content: <Students students={assigned} onScheduleEventEdit={onScheduleEventEdit} />,
          type: "data" as Types.ScheduleCellType,
          className: "data-cell",
          staffId: applicableStaff[i].id,
          available,
          datetime: timeRangeStart.valueOf()
        };
        currentRow.push(data);
        lookups.push({ id: data.key, targetId: data.staffId, time: timeRangeStart.valueOf() });
      } else {
        const className = classnames("data-cell", { "employee-unavailable": !available });
        const data = {
          key: uuidv4(),
          content: " ",
          className,
          type: "data" as Types.ScheduleCellType,
          staffId: applicableStaff[i].id,
          available,
          datetime: timeRangeStart.valueOf()
        };
        currentRow.push(data);
        lookups.push({ id: data.key, targetId: data.staffId, time: timeRangeStart.valueOf() });
      }
    }

    if (applicableResources.length) {
      currentRow.push({ key: uuidv4(), content: " ", className: "vertical-gap", type: "vertical-gap" });

      applicableResources.forEach(r => {
        const available = isAvailableForTimeRange(
          timeRangeStart.valueOf(),
          timeRangeEnd.valueOf(),
          r.availability,
          center
        );

        const assigned = applicableStudents.filter(
          st => st.start >= timeRangeStart.valueOf() && st.start < timeRangeEnd.valueOf() && st.resourceId === r.id
        );
        if (assigned) {
          const data = {
            key: uuidv4(),
            content: <Students students={assigned} onScheduleEventEdit={onScheduleEventEdit} />,
            type: "data" as Types.ScheduleCellType,
            className: "data-cell",
            staffId: r.id,
            available,
            datetime: timeRangeStart.valueOf()
          };
          currentRow.push(data);
          lookups.push({ id: data.key, targetId: data.staffId, time: timeRangeStart.valueOf() });
        } else {
          const data = {
            key: uuidv4(),
            content: " ",
            type: "data" as Types.ScheduleCellType,
            staffId: r.id,
            available,
            className: "data-cell",
            datetime: timeRangeStart.valueOf()
          };
          currentRow.push(data);
          lookups.push({ id: data.key, targetId: data.staffId, time: timeRangeStart.valueOf() });
        }
      });
    }

    results.push(currentRow);
    current = sequence.next();
  }
  return { results, lookups, students: applicableStudents };
};

export const Students: React.FC<{
  students: Types.StudentScheduleChartData[] | undefined;
  onScheduleEventEdit: Types.ScheduleEventEdit;
  isUnassigned?: boolean;
}> = ({ students, onScheduleEventEdit, isUnassigned }) => {
  // if (isUnassigned && !students?.length) {
  //   return <div className="unassigned-empty-filler-block">&nbsp;</div>;
  // }
  return (
    <>
      {students?.map((st, index) => (
        <Student chartData={st} key={st.id} onScheduleEventEdit={onScheduleEventEdit} index={index} />
      ))}
    </>
  );
};

export const Student: React.FC<{
  chartData: Types.StudentScheduleChartData | undefined;
  index: number;
  onScheduleEventEdit: Types.ScheduleEventEdit;
}> = ({ chartData, index, onScheduleEventEdit }) => {
  if (!chartData) {
    return <div> </div>;
  }

  const subjectsText = Utils.formatServiceSubject(chartData.serviceSubject, "shortservice");
  const title = `${chartData.students.map(s => s.name).join(" ")} (${subjectsText})`;

  const result = (
    <Draggable draggableId={chartData.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="student-draggable"
        >
          <div
            className="student-block"
            title={title}
            style={{ height: chartData.fill, backgroundColor: chartData.fillColor }}
            onClick={() => onScheduleEventEdit(chartData.scheduleEventId ? "instance" : "prototype", chartData)}
          >
            {chartData.students.map(s => s.name).join("\n")}
            <br />
            <span className="day-schedule-service-subject-box-text">{subjectsText}</span>
          </div>
        </div>
      )}
    </Draggable>
  );

  return result;
};
