import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import * as Types from "../types";
import { isAvailableForTimeRange } from "./is-available-for-time-range";
import { getEffectiveSchedule } from "./get-effective-schedule";
import { formatAlphabeticName } from "./formatters";

export const getStaffThatCanFulfillStudentSchedule = (
  start: number,
  end: number,
  subject: string,
  staff: Types.Employee[],
  center: Types.Center
) => {
  return staff
    .filter(s => {
      const hasAllSubjectsRequired = s.serviceSubjects?.includes(subject);

      return (
        hasAllSubjectsRequired &&
        isAvailableForTimeRange(start, end, getEffectiveSchedule(start, s.availability), center)
      );
    })
    .map(staff => {
      return { staff, schedule: getEffectiveSchedule(start, staff.availability) };
    });
};

export const compileStudentsForSchedule = (
  forDate: number,
  intervalMinutes: number,
  students: Types.Student[],
  events: Types.ScheduleEvent[],
  staff: Types.Employee[],
  center: Types.Center
): Types.StudentScheduleChartData[] => {
  const results: Types.StudentScheduleChartData[] = [];

  const startOfDate = moment(forDate).startOf("d");
  const endOfDate = moment(forDate).endOf("d");
  const dayOfWeek = Types.daysOfWeek[startOfDate.day()] as Types.DaysOfWeekType;

  students.forEach(st => {
    if (!st.schedules || !st.schedules.length) {
      return;
    }

    const subjects =
      st.schedules
        .filter(sc => !sc.deactivated && sc.days.some((d: Types.DaySchedule) => d.day === dayOfWeek))
        .map(sc => sc.serviceSubject) ?? [];
    if (!subjects.length) {
      return;
    }

    const uniqueSubjects = subjects.filter((x, i, a) => {
      return a.indexOf(x) === i;
    });

    uniqueSubjects.forEach(serviceSubject => {
      if (!serviceSubject) {
        return;
      }

      const subjectSchedule = getEffectiveSchedule(
        forDate,
        st.schedules?.filter(sc => sc.serviceSubject === serviceSubject)
      );

      if (subjectSchedule) {
        const daySchedules = subjectSchedule.days.filter(ds => ds.day === dayOfWeek);
        daySchedules.forEach(ds => {
          let size = Math.round((((ds.end ?? 0) - (ds.start ?? 0)) / intervalMinutes) * 100);
          const boxesFilled = Math.round(((ds.end ?? 0) - (ds.start ?? 0)) / intervalMinutes);
          if (boxesFilled > 1) {
            size = size + boxesFilled * (intervalMinutes >= 60 ? 5 : 4);
          }

          const status = "ATD"; // start assuming student will attend
          const fill = `${size}%`;
          const fillColor = Types.attendanceCodes[status].color;
          const item: Types.StudentScheduleChartData = {
            id: uuidv4(),

            students: [
              {
                id: st.id,
                name: formatAlphabeticName(st.name),
                dayScheduleId: ds.id,
                prototypeScheduleId: subjectSchedule.id,
                scheduleEventId: uuidv4()
              }
            ],

            serviceSubject,
            start: moment(startOfDate).add(ds.start, "m").valueOf(),
            end: moment(startOfDate).add(ds.end, "m").valueOf(),
            fill,
            fillColor,
            startMinutes: ds.start ?? 0,
            endMinutes: ds.end ?? 0,
            status: "ATD",
            comment: "",
            staffId: undefined,
            maxNumberOfStudents: 1
          };

          const extantScheduleEvent = events.find(evt => {
            const relevantStudent = item.students.find(st => st.id === evt.studentId);
            if (!relevantStudent) {
              return false;
            } else {
              return (
                relevantStudent.dayScheduleId === evt.studentScheduleDayId &&
                relevantStudent.prototypeScheduleId === evt.studentScheduleId
              );
            }
          });

          if (extantScheduleEvent) {
            results.push({
              id: extantScheduleEvent.id,

              students: [
                {
                  id: extantScheduleEvent.studentId,
                  name: formatAlphabeticName(st.name),
                  dayScheduleId: ds.id,
                  prototypeScheduleId: subjectSchedule.id,
                  scheduleEventId: extantScheduleEvent.id
                }
              ],

              serviceSubject: extantScheduleEvent.serviceSubject,
              start: extantScheduleEvent.start,
              end: extantScheduleEvent.end,
              fill,
              fillColor: Types.attendanceCodes[extantScheduleEvent.status].color,
              staffId: extantScheduleEvent.staffId,
              resourceId: extantScheduleEvent.resourceId,
              scheduleEventId: extantScheduleEvent.id,
              startMinutes: ds.start ?? 0,
              endMinutes: ds.end ?? 0,
              status: extantScheduleEvent.status,
              comment: extantScheduleEvent.comment,
              maxNumberOfStudents: extantScheduleEvent.maxNumberOfStudents
            });
          } else {
            // make a prelim auto-assignment
            const applicableStaff = getStaffThatCanFulfillStudentSchedule(
              item.start,
              item.end,
              item.serviceSubject,
              staff,
              center
            );
            if (applicableStaff.length) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              let bestStaffCandidate: any;
              let bestDayScheduleCandidate: Types.EmployeeDaySchedule | undefined = undefined;

              for (const staffCandidate of applicableStaff) {
                const employeeScheduleBlock = staffCandidate.schedule?.days?.find(eds => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  return eds.day === ds.day && item.startMinutes >= eds.start! && item.endMinutes <= eds.end!;
                });

                // is there an existing assignment for the employee at the required timeslot
                const existingAssignment = results.find(
                  res => res.start >= item.start && res.end <= item.end && res.staffId === staffCandidate.staff.id
                );
                if (existingAssignment) {
                } else {
                  bestStaffCandidate = staffCandidate;
                  bestDayScheduleCandidate = employeeScheduleBlock as Types.EmployeeDaySchedule;
                  break;
                }
              }

              if (bestStaffCandidate) {
                item.staffId = bestStaffCandidate.staff.id;

                if (bestDayScheduleCandidate) {
                  item.maxNumberOfStudents = bestDayScheduleCandidate.ratio;
                } else {
                  item.maxNumberOfStudents = 1;
                }
              }
            }
            results.push(item);
          }
        });
      }
    });
  });

  // handles events moved from their standard day
  events.forEach(evt => {
    if (results.find(r => r.id === evt.id)) {
      return;
    }
    if (evt.start >= startOfDate.valueOf() && evt.end <= endOfDate.valueOf()) {
      const startMinutes = Math.abs(startOfDate.valueOf() - evt.start) / 1000 / 60;
      const endMinutes = Math.abs(startOfDate.valueOf() - evt.end) / 1000 / 60;

      let size = Math.round(((endMinutes - startMinutes) / intervalMinutes) * 100);
      const boxesFilled = Math.round((endMinutes - startMinutes) / intervalMinutes);
      if (boxesFilled > 1) {
        size = size + boxesFilled * (intervalMinutes >= 60 ? 5 : 4);
      }

      const fill = `${size}%`;
      const fillColor = Types.attendanceCodes[evt.status].color;

      results.push({
        id: evt.id,

        students: [
          {
            id: evt.studentId,
            name: evt.name,
            dayScheduleId: evt.studentScheduleDayId,
            prototypeScheduleId: evt.studentScheduleId,
            scheduleEventId: evt.id
          }
        ],

        serviceSubject: evt.serviceSubject,
        start: evt.start,
        end: evt.end,
        fill,
        fillColor,
        staffId: evt.staffId,
        resourceId: evt.resourceId,
        scheduleEventId: evt.id,
        startMinutes,
        endMinutes,
        status: evt.status,
        comment: evt.comment,
        maxNumberOfStudents: evt.maxNumberOfStudents
      });
    }
  });

  const finalResults: Types.StudentScheduleChartData[] = [];
  const mergedResults: string[] = [];

  for (const initialResult of results) {
    if (mergedResults.includes(initialResult.id)) {
      continue;
    }
    if (initialResult.maxNumberOfStudents > 1 && initialResult.students.length < initialResult.maxNumberOfStudents) {
      const otherCandidates = results.filter(rf => {
        if (rf.id !== initialResult.id) {
          return (
            rf.serviceSubject === initialResult.serviceSubject &&
            rf.start === initialResult.start &&
            rf.end === initialResult.end &&
            rf.students.length === 1
          );
        }

        return false;
      });

      if (otherCandidates.length > 0) {
        // console.log(otherCandidates);
        for (const oc of otherCandidates) {
          if (initialResult.students.length >= initialResult.maxNumberOfStudents) {
            break;
          }
          mergedResults.push(oc.id);
          initialResult.students.push(oc.students[0]);
        }
        finalResults.push(initialResult);
      } else {
        finalResults.push(initialResult);
      }
    } else {
      finalResults.push(initialResult);
    }
  }
  return finalResults;
};
