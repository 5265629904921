import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as Types from "./types";
import * as Utils from "./utils";

import * as EmployeeMocks from "./employees";

export interface StaffState {
  staff: Types.Employee[];
}

const initialState: StaffState = {
  staff: [...EmployeeMocks.employees]
};

export interface SaveStaffAvailabilityPayload {
  centerId: Types.UniqueId;
  staffId: Types.UniqueId;
  schedule: Types.EmployeeWeekSchedule;
}

export interface ToggleSubjectPayload {
  centerId: Types.UniqueId;
  staffId: Types.UniqueId;
  subject: Types.SubjectType;
}

export interface ToggleServiceSubjectPayload {
  centerId: Types.UniqueId;
  staffId: Types.UniqueId;
  serviceSubject: string;
}

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    loadEmployees: (state, action: PayloadAction<Types.Employee[]>) => {
      state.staff = [...action.payload];
    },
    saveStaffAvailability: (state, action: PayloadAction<SaveStaffAvailabilityPayload>) => {
      if (!action.payload.schedule) {
        return;
      }
      const employee = state.staff.find(e => e.id === action.payload.staffId);
      if (employee) {
        if (!employee.availability) {
          employee.availability = [];
        }
        const schedule = employee.availability.find(sch => sch.id === action.payload.schedule.id);
        if (schedule) {
          const updated = { ...schedule, ...action.payload.schedule };
          updated.days.sort(Utils.dayScheduleSorter);
          employee.availability = [...employee.availability.filter(r => r.id !== updated.id), updated];
        } else {
          employee.availability.push(action.payload.schedule);
        }
      }
    },
    toggleSubject: (state, action: PayloadAction<ToggleSubjectPayload>) => {
      const employee = state.staff.find(e => e.id === action.payload.staffId);
      if (employee) {
        if (!employee.subjects) {
          employee.subjects = [];
        }
        if (employee.subjects.find(s => s === action.payload.subject)) {
          employee.subjects = [...employee.subjects.filter(s => s !== action.payload.subject)];
        } else {
          employee.subjects.push(action.payload.subject);
        }
      }
    },
    toggleServiceSubject: (state, action: PayloadAction<ToggleServiceSubjectPayload>) => {
      const employee = state.staff.find(e => e.id === action.payload.staffId);
      if (employee) {
        if (!employee.serviceSubjects) {
          employee.serviceSubjects = [];
        }
        if (employee.serviceSubjects.find(s => s === action.payload.serviceSubject)) {
          employee.serviceSubjects = [...employee.serviceSubjects.filter(s => s !== action.payload.serviceSubject)];
        } else {
          employee.serviceSubjects.push(action.payload.serviceSubject);
        }
      }
    }
  }
});

export const { loadEmployees, saveStaffAvailability, toggleSubject, toggleServiceSubject } = staffSlice.actions;

export default staffSlice.reducer;
