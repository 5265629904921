import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import * as Types from "../../data/types";
import * as Utils from "../../data/utils";

import "./schedule-day.scss";
import { useModal } from "react-hooks-use-modal";
import { EditStudentSchedule } from "./edit-student-schedule";
import * as ScheduleSlice from "../../data/schedule-slice";

export interface ScheduleDayProps {
  forDate: string;
  center: Types.Center;
  students: Types.Student[];
  staff: Types.Employee[];
  events: Types.ScheduleEvent[];
  serviceFilterValue: string;
  options: string[];
  onSaveScheduleEvent?: (payload: ScheduleSlice.SaveScheduleEvent) => void;
  onDeleteScheduleEvent?: (payload: ScheduleSlice.DeleteScheduleEvent) => void;
}

export const ScheduleDay: React.FC<ScheduleDayProps> = ({
  forDate,
  center,
  students,
  staff,
  events,
  onSaveScheduleEvent,
  serviceFilterValue = "ALL",
  options,
  onDeleteScheduleEvent
}) => {
  const [grid, setGrid] = useState<Types.ScheduleCell[][]>([]);
  //const [lookups, setLookups] = useState<Utils.DroppableLookup[]>([]);
  const [studentsChartData, setStudentsChartData] = useState<Types.StudentScheduleChartData[]>([]);
  const [editing, setEditing] = useState<Types.StudentScheduleChartData | undefined>(undefined);

  const onScheduleEditEvent: Types.ScheduleEventEdit = useCallback(
    (type: Types.ScheduleEventEditType, data: Types.StudentScheduleChartData) => {
      console.log("opening editor", type, data);
      setEditing(data);
    },
    []
  );

  useEffect(() => {
    const numericDate = moment(forDate, "YYYY-MM-DD").valueOf();
    const scheduleData = Utils.compileMasterSchedule(
      numericDate,
      staff,
      students,
      center,
      events,
      serviceFilterValue === "ALL" ? "" : serviceFilterValue,
      options,
      onScheduleEditEvent
    );
    //setLookups(scheduleData.lookups);
    setStudentsChartData(scheduleData.students);
    setGrid(scheduleData.results);
  }, [forDate, center, students, staff, events, serviceFilterValue, options, onScheduleEditEvent]);

  const [EditStudentScheduleModal, openEditStudentScheduleModal, closeEditStudentScheduleModal] = useModal(
    "utility-root",
    {
      preventScroll: false,
      closeOnOverlayClick: false
    }
  );

  const onScheduleEditCancel = () => {
    setEditing(undefined);
  };

  useEffect(() => {
    if (editing) {
      openEditStudentScheduleModal();
    } else {
      closeEditStudentScheduleModal();
    }
  }, [editing, openEditStudentScheduleModal, closeEditStudentScheduleModal]);

  return (
    <div style={{ width: "2400px" }}>
      <DragDropContext
        onDragEnd={result => {
          if (!result || !result.destination) {
            return;
          }

          const droparea = document.getElementById(result.destination.droppableId);
          if (!droparea) {
            return;
          }
          const newStaffId = droparea.dataset["staffid"];
          const newTime = parseInt(droparea.dataset["time"] ?? "", 10);
          //const destination = lookups.find(l => l.id === result.destination?.droppableId);
          const chartData = studentsChartData.find(s => s.id === result.draggableId);
          //console.log(newStaffId, newTime, chartData);
          if (chartData) {
            // if (!newStaffId) {
            //   onDeleteScheduleEvent && onDeleteScheduleEvent({ eventId: chartData.id });
            // } else {
            const duration = chartData.end - chartData.start;

            for (const student of chartData.students) {
              const updated: ScheduleSlice.SaveScheduleEvent = {
                event: {
                  id: student.scheduleEventId,
                  studentId: student.id,
                  name: student.name,
                  studentScheduleId: student.prototypeScheduleId,
                  studentScheduleDayId: student.dayScheduleId,
                  serviceSubject: chartData.serviceSubject,
                  staffId: newStaffId, // destination.targetId,
                  resourceId: chartData.resourceId,
                  start: newTime, // destination.time,
                  end: newTime + duration, // destination.time + duration,
                  status: chartData.status,
                  comment: chartData.comment,
                  maxNumberOfStudents: chartData.maxNumberOfStudents
                }
              };
              console.log("updating", updated);
              onSaveScheduleEvent && onSaveScheduleEvent(updated);
            }
            //}
          }
        }}
      >
        <div className="schedule-day-grids-container">
          <div className="schedule-day-grid" style={{ gridTemplateColumns: "1fr ".repeat(grid?.[0]?.length ?? 0) }}>
            {grid.map((row, rowIndex) => {
              return row.map((col, colIndex) => {
                return (
                  <Droppable key={col.key} droppableId={col.key} isDropDisabled={!col.available && !!col.staffId}>
                    {(provided, snapshot) => (
                      <div
                        id={col.key}
                        data-time={col.datetime}
                        data-staffid={col.staffId ?? ""}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={col.className}
                        style={{ gridRow: `${rowIndex + 1}`, gridColumn: colIndex + 1 }}
                      >
                        {col.content}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                );
              });
            })}
          </div>
        </div>
      </DragDropContext>
      {editing && (
        <EditStudentScheduleModal>
          <EditStudentSchedule
            schedule={editing}
            staff={staff}
            center={center}
            onCancel={onScheduleEditCancel}
            onSave={onSaveScheduleEvent}
          />
        </EditStudentScheduleModal>
      )}
    </div>
  );
};
