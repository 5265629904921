import * as Types from "../types";

export const deliveryLabels = ["Center", "Virtual"];
export const formatDeliveryMethods = (methods?: Types.EmployeeSupportedDeliveryMethods) => {
  if (!methods) {
    return "";
  } else if (methods === "both") {
    return deliveryLabels.join(" & ");
  } else if (methods === "virtual") {
    return deliveryLabels[1];
  } else {
    return deliveryLabels[0];
  }
};

export const capitalize = (s: string) => {
  return s[0].toUpperCase() + s.slice(1);
};

export const formatNullableBoolean = (b: boolean | undefined, nullFormat = "") => {
  if (b === undefined) {
    return nullFormat;
  } else {
    return b ? "Yes" : "No";
  }
};

export const formatStandardName = (name: Types.Name) => {
  return `${name?.first ?? ""} ${name?.middle ?? ""} ${name?.last ?? ""}`.replace(/\s+/g, " ").trim();
};

export const formatAlphabeticName = (name: Types.Name | undefined) => {
  if (!name) {
    return "";
  }
  return `${name?.last ?? ""}, ${name?.first ?? ""} ${name?.middle ?? ""}`.replace(/\s+/g, " ").trim();
};
