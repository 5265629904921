import React, { useCallback, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import * as Types from "../../data/types";
import * as Store from "../../data/store";

import * as StaffSlice from "../../data/staff-slice";

import { StaffSelector } from "../../components/staff-selector/staff-selector";
import { StaffDetail } from "../../components/staff-detail/staff-detail";
import { AppLayout, Rightside, Leftside, ContentPad, Utility } from "../../app/app-layout";
import { ListHeader } from "../../components/list-header/list-header";

import "./staff.scss";

export interface Actions {
  onSaveStaffAvailability: (payload: StaffSlice.SaveStaffAvailabilityPayload) => void;
  toggleSubject: (payload: StaffSlice.ToggleSubjectPayload) => void;
  onToggleStaffServiceSubject?: (payload: StaffSlice.ToggleServiceSubjectPayload) => void;
}

export interface StaffProps {
  staff?: Types.Employee[];
  dispatch?: Store.AppDispatch;
  actions?: Actions;
}

export const Staff: React.FC<StaffProps> = ({ staff, actions }) => {
  const location = useLocation();
  const tabQualifier = useMemo(
    () => (location.pathname.endsWith("inactive") ? "Inactive" : "Current"),
    [location.pathname]
  );

  const [selected, setSelected] = useState<string>("");
  const onSelectEmployee = (id: string) => {
    setSelected(id);
  };

  const onSaveStaffAvailability = (payload: StaffSlice.SaveStaffAvailabilityPayload) => {
    actions?.onSaveStaffAvailability(payload);
  };

  const onToggleStaffSubject = (payload: StaffSlice.ToggleSubjectPayload) => {
    actions?.toggleSubject(payload);
  };

  const onToggleStaffServiceSubject = (payload: StaffSlice.ToggleServiceSubjectPayload) => {
    actions?.onToggleStaffServiceSubject?.(payload);
  };

  const [filter, setFilter] = useState<string>("");
  const onFilterChange = useCallback((newvalue: string) => {
    setFilter(newvalue);
  }, []);
  const tableRef = useRef<HTMLTableElement>(null);
  const onPrintRequested = useReactToPrint({
    content: () => tableRef.current
  });

  return (
    <AppLayout activeTab="Staff" tabQualifier={tabQualifier}>
      <Leftside size={4}>
        <Utility>
          <ListHeader filter={filter} onFilterChange={onFilterChange} onPrintRequested={onPrintRequested} />
        </Utility>
        <ContentPad>
          <StaffSelector
            employees={staff}
            selected={selected}
            onSelect={onSelectEmployee}
            filter={filter}
            ref={tableRef}
          />
        </ContentPad>
      </Leftside>
      <Rightside size={8} showIf={!!selected}>
        <ContentPad>
          <StaffDetail
            employee={staff?.find(e => e.id === selected)}
            onSaveStaffAvailability={onSaveStaffAvailability}
            onToggleStaffSubject={onToggleStaffSubject}
            onToggleStaffServiceSubject={onToggleStaffServiceSubject}
          />
        </ContentPad>
      </Rightside>
    </AppLayout>
  );
};

export const mapStateToProps = (state: Store.RootState) => ({
  staff: state?.staff?.staff ?? []
});

export const mapDispatchToProps = (dispatch: Store.AppDispatch) => {
  const props: { actions: Actions; dispatch: Store.AppDispatch } = {
    actions: {
      onSaveStaffAvailability: (payload: StaffSlice.SaveStaffAvailabilityPayload) =>
        dispatch(StaffSlice.saveStaffAvailability(payload)),
      toggleSubject: (payload: StaffSlice.ToggleSubjectPayload) => dispatch(StaffSlice.toggleSubject(payload)),
      onToggleStaffServiceSubject: (payload: StaffSlice.ToggleServiceSubjectPayload) =>
        dispatch(StaffSlice.toggleServiceSubject(payload))
    },
    dispatch
  };
  return props;
};

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
