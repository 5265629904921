import React from "react";

import * as Types from "../../data/types";
import { ItemType } from "../../hooks/use-sortable-filterable-data";

import {
  GeneralList,
  GeneralListColumnSpecification,
  GeneralListDataMapHandler,
  GeneralListItemSelectHandler
} from "../general-list/general-list";

export interface CenterSelectorProps {
  centers: Types.Center[];
  selected?: string;
  onSelect?: (id: string) => void;
  onAction?: (id: string) => void;
  filter: string;
}

export const CenterSelector = React.forwardRef<HTMLTableElement, CenterSelectorProps>(
  ({ centers, selected, onSelect, onAction, filter }, tableRef) => {
    const onSelectItem: GeneralListItemSelectHandler = item => {
      onSelect && onSelect(item.id);
    };
    return (
      <div>
        <GeneralList
          filter={filter}
          data={centers}
          mapper={centerSelectorMapper}
          specs={centerSelectorSpecs}
          activeItem={selected}
          onSelectItem={onSelectItem}
          onActionButtonClick={onAction}
          ref={tableRef}
        />
      </div>
    );
  }
);

CenterSelector.displayName = "CenterSelector";

export const centerSelectorMapper: GeneralListDataMapHandler = function (this, center, index?, array?) {
  const c = center as Types.Center;
  return {
    id: c.id,
    name: c.name,
    state: c.state,
    zipcode: c.zipcode
  } as ItemType;
};

export const centerSelectorSpecs: GeneralListColumnSpecification[] = [
  //{ title: "ID#", name: "id", sorterName: "id" },
  {
    title: "Name",
    name: "name",
    sorterName: "name",
    className: "primary",
    isStrongCell: true,
    defaultSort: true
  },
  { title: "State", name: "state", sorterName: "state" },
  { title: "Zipcode", name: "zipcode", sorterName: "zipcode" },
  { title: "Activate", name: "activate", isActionButton: true }
];
