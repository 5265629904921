import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "../data/store";

import Centers from "../screens/centers/centers";
import { Dashboard } from "../screens/dashboard/dashboard";
import { Financials } from "../screens/financials/financials";
import { Reporting } from "../screens/reporting/reporting";
import Scheduling from "../screens/scheduling/scheduling";
import Staff from "../screens/staff/staff";
import Students from "../screens/students/students";

import { AuthenticationProvider, useAuthentication } from "./authentication-context";
import { Login } from "./login";
import SchedulingFramed from "../screens/scheduling/schedule-frame";

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <AuthenticationProvider>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route
            path="centers"
            element={
              <RequireAuthentication>
                <Centers />
              </RequireAuthentication>
            }
          />
          <Route
            path="staff/:subset"
            element={
              <RequireAuthentication>
                <Staff />
              </RequireAuthentication>
            }
          />
          <Route
            path="students/:subset"
            element={
              <RequireAuthentication>
                <Students />
              </RequireAuthentication>
            }
          />
          <Route
            path="students"
            element={
              <RequireAuthentication>
                <Students />
              </RequireAuthentication>
            }
          />
          <Route
            path="scheduling"
            element={
              <RequireAuthentication>
                <Scheduling />
              </RequireAuthentication>
            }
          />
          <Route
            path="financials"
            element={
              <RequireAuthentication>
                <Financials />
              </RequireAuthentication>
            }
          />
          <Route
            path="reporting"
            element={
              <RequireAuthentication>
                <Reporting />
              </RequireAuthentication>
            }
          />

          <Route
            path="dashboard/:subset"
            element={
              <RequireAuthentication>
                <Dashboard />
              </RequireAuthentication>
            }
          />

          <Route
            path="scheduling-framed"
            element={
              <RequireAuthentication>
                <SchedulingFramed />
              </RequireAuthentication>
            }
          />

          {/* in the future may want this to point elsewhere and have an explicit and/or index point to Dashbord */}
          <Route
            path="*"
            element={
              <RequireAuthentication>
                <Dashboard />
              </RequireAuthentication>
            }
          />
        </Routes>
      </AuthenticationProvider>
    </Provider>
  );
};

export const RequireAuthentication: React.FunctionComponent = ({ children }) => {
  const auth = useAuthentication();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};
