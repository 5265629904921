import React, { CSSProperties, useRef } from "react";
import { useButton } from "@react-aria/button";

export interface TableHeadingButtonProps {
  onPress: () => void;
  className?: string;
  style?: CSSProperties;
}

export const TableHeadingButton: React.FC<TableHeadingButtonProps> = props => {
  const { onPress, children, style, ...rest } = props;

  const header = useRef<HTMLTableHeaderCellElement>(null);
  const { buttonProps } = useButton(
    {
      onPress,
      elementType: "th"
    },
    header
  );

  return (
    <th ref={header} {...buttonProps} style={{ cursor: "pointer", outlineColor: "transparent", ...style }} {...rest}>
      {children}
    </th>
  );
};
