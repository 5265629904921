import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import * as EmployeeMocks from "../../data/employees";
import * as Types from "../../data/types";
import * as Store from "../../data/store";

import * as StudentsSlice from "../../data/students-slice";

import { StudentSelector } from "../../components/student-selector/student-selector";
import { StudentDetail } from "../../components/student-detail/student-detail";
import { AppLayout, Leftside, Rightside, ContentPad, Utility } from "../../app/app-layout";
import { ListHeader } from "../../components/list-header/list-header";

import "./students.scss";

export interface Actions {
  onSaveStudentTuitionRate: (payload: StudentsSlice.SaveStudentTuitionRatePayload) => void;
  onSaveStudentSchedule: (payload: StudentsSlice.SaveStudentSchedulePayload) => void;
}

export interface StudentsProps {
  students?: Types.Student[];
  parents?: Types.StudentRelative[];
  dispatch?: Store.AppDispatch;
  actions?: Actions;
}

export const Students: React.FC<StudentsProps> = ({ students = [], parents = [], actions }) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const tabQualifier = useMemo(() => {
    if (location.pathname.endsWith("inactive")) {
      return "Inactive";
    } else if (location.pathname.endsWith("current")) {
      return "Current";
    } else {
      return "";
    }
  }, [location.pathname]);

  const [selected, setSelected] = useState<string>("");
  const onSelectStudent = (id: string) => {
    setSelected(id);
  };

  // useEffect(() => {
  //   const id = searchParams.get("id");
  //   if (id) {
  //     setSelected(id);
  //   }
  // }, [searchParams]);

  const data = useMemo(() => {
    if (students) {
      if (tabQualifier === "Inactive") {
        return students.filter(s => s.status === "DROP");
      } else if (tabQualifier === "Current") {
        return students.filter(s => s.status !== "DROP");
      } else {
        return students;
      }
    } else {
      return [];
    }
  }, [students, tabQualifier]);

  useEffect(() => {
    if (data.length) {
      const id = searchParams.get("id");
      if (id) {
        setSelected(id);
      } else if (!selected) {
        setSelected(data[0].id);
      } else {
        if (!data.find(s => s.id === selected)) {
          setSelected(data[0].id);
        }
      }
    } else {
      setSelected("");
    }
  }, [data, selected, searchParams]);

  const [filter, setFilter] = useState<string>("");
  const onFilterChange = useCallback((newvalue: string) => {
    setFilter(newvalue);
  }, []);
  const tableRef = useRef<HTMLTableElement>(null);
  const onPrintRequested = useReactToPrint({
    content: () => tableRef.current
  });

  return (
    <AppLayout activeTab="Students" tabQualifier={tabQualifier}>
      <Leftside size={4}>
        <Utility>
          <ListHeader filter={filter} onFilterChange={onFilterChange} onPrintRequested={onPrintRequested} />
        </Utility>
        <ContentPad>
          <StudentSelector
            students={data}
            selected={selected}
            onSelect={onSelectStudent}
            filter={filter}
            ref={tableRef}
          />
        </ContentPad>
      </Leftside>
      <Rightside size={8} showIf={!!selected}>
        <ContentPad>
          <StudentDetail
            student={data.find(s => s.id === selected)}
            employees={EmployeeMocks.employees}
            parents={parents.filter(p => p.studentId === selected)}
            mode="extended"
            actions={actions}
          />
        </ContentPad>
      </Rightside>
    </AppLayout>
  );
};

export const mapStateToProps = (state: Store.RootState) => ({
  students: state?.students?.students ?? [],
  parents: state?.students?.parents ?? []
});

export const mapDispatchToProps = (dispatch: Store.AppDispatch) => {
  const props: { actions: Actions; dispatch: Store.AppDispatch } = {
    actions: {
      onSaveStudentTuitionRate: (payload: StudentsSlice.SaveStudentTuitionRatePayload) =>
        dispatch(StudentsSlice.saveStudentTuitionRate(payload)),
      onSaveStudentSchedule: (payload: StudentsSlice.SaveStudentSchedulePayload) =>
        dispatch(StudentsSlice.saveStudentSchedule(payload))
    },
    dispatch
  };
  return props;
};

export default connect(mapStateToProps, mapDispatchToProps)(Students);
