import React, { CSSProperties, useEffect, useMemo } from "react";
import classnames from "classnames";

import * as Types from "../../data/types";
import * as Utils from "../../data/utils";

import { ItemType, SortParameters, useSortableFilterableData } from "../../hooks/use-sortable-filterable-data";
import { TableHeadingButton } from "../table-heading-button/table-heading-button";

import "./general-list.scss";

export type GeneralListItemSelectHandler = (item: { id: string }) => void;

export type GeneralListDataMapHandler = (
  this: { employees?: Types.Employee[] },
  item: {},
  index?: number,
  array?: {}[]
) => ItemType;

export interface GeneralListColumnSpecification {
  // what is show in the header cell for the column
  title: string;
  // the name of the property to use for the value from the data array
  name: string;
  // indicates sortability + allows alernate value for sorting
  // i.e., can be the same values as name but not required as long
  // as the name specified exists in your mapper return
  sorterName?: string;
  // className passed unmodified to cell component
  className?: string;
  // if this column should be the default sort, you only want one to have this in any given specs
  defaultSort?: boolean;
  // cell includes the <strong> tag around value
  isStrongCell?: boolean;
  // special purpose alert cell that shows a red dot if it's value is true
  isAlertCell?: boolean;
  // special purpose that shows ready times symbol/check symbol circle glyph based on boolean
  isReadyCell?: boolean;
  isActionButton?: boolean;
  actionButtonIconClasses?: string;

  tooltip?: string;
}

// the standard mapper that will be used if a alternative isn't provided, note how this
// uses OG function and not arrow, if you need to pass in the extra data stored on this
// you must use the OG style
export const generalMapper: GeneralListDataMapHandler = function (this, student, index?, array?) {
  // this will depend on a second arg being passed to map like { employees: <thedata> }
  const employees = this.employees ?? [];
  const s = student as Types.Student;
  return {
    id: s.id,
    name: Utils.formatAlphabeticName(s.name),
    nameSorter: Utils.formatAlphabeticName(s.name),
    assigned: employees.length && !!s.assignedTo ? employees.find(e => e.id === s.assignedTo)?.name || "" : "",
    alert: !!s.alert,
    service: s.service || "",
    ready: !!s.assignedTo
  } as ItemType;
};

// the standard specs that will be used if a alternative isn't provided
export const generalSpecs: GeneralListColumnSpecification[] = [
  { title: "Ready", name: "ready", isReadyCell: true },
  {
    title: "Student",
    name: "nameSorter",
    sorterName: "nameSorter",
    className: "primary",
    isStrongCell: true,
    defaultSort: true
  },
  { title: "Service", name: "service", className: "text-center" },
  { title: "Assigned", name: "assigned", sorterName: "assigned", isStrongCell: true }
  //{ title: "", name: "alert", isAlertCell: true }
];

export interface GeneralListProps {
  data?: { id: string }[];
  filter?: string;
  employees?: Types.Employee[];
  onSelectItem?: GeneralListItemSelectHandler;
  mapper?: GeneralListDataMapHandler;
  specs?: GeneralListColumnSpecification[];
  pageSize?: number;
  tableMaximumHeight?: number;
  activeItem?: string;
  onActionButtonClick?: (id: string) => void;
}

export const GeneralList = React.forwardRef<HTMLTableElement, GeneralListProps>(
  (
    {
      data,
      filter = "",
      employees,
      mapper = generalMapper,
      specs = generalSpecs,
      onSelectItem,
      pageSize,
      tableMaximumHeight,
      activeItem,
      onActionButtonClick
    },
    tableRef
  ) => {
    const students = useMemo(() => data?.map(mapper, { employees }) || [], [data, employees, mapper]);
    const sortOptions = useMemo(() => {
      const defaultSort = specs.find(sp => sp.defaultSort);

      if (defaultSort && defaultSort.sorterName) {
        return {
          key: defaultSort.sorterName,
          direction: "ascending"
        } as SortParameters;
      } else {
        return undefined;
      }
    }, [specs]);

    const { items, pagedItems, requestSort, requestFilter, page, totalPages, requestPage } = useSortableFilterableData(
      students,
      sortOptions,
      pageSize
    );

    const emptyRows = useMemo(() => {
      if (pageSize === undefined || totalPages < 2) {
        return [];
      } else {
        return [...Array.from(Array(pageSize - pagedItems.length).keys())];
      }
    }, [pagedItems, pageSize, totalPages]);

    const onClickRow = (id: string) => {
      if (data && onSelectItem) {
        const item = data.find(d => d.id === id);
        if (item) {
          onSelectItem(item);
        }
      }
    };

    useEffect(() => {
      requestFilter(filter);
    }, [filter, requestFilter]);

    const { tableContainerStyle, thStyle } = useMemo(() => {
      const results: { tableContainerStyle: CSSProperties; thStyle: CSSProperties } = {
        tableContainerStyle: {},
        thStyle: {}
      };
      if (tableMaximumHeight) {
        results.tableContainerStyle = { maxHeight: `${tableMaximumHeight}px`, overflowY: "scroll" };
        results.thStyle = { position: "sticky", top: 0, zIndex: 4, backgroundColor: "#fafafa" };
      }
      return results;
    }, [tableMaximumHeight]);

    return (
      <>
        <div>
          <div style={tableContainerStyle}>
            <table ref={tableRef} className="general-list-table table">
              <thead>
                <tr>
                  {specs.map(colspec => {
                    const key = `general-list-header-cell-${colspec.name}`;
                    if (colspec.sorterName) {
                      return (
                        <TableHeadingButton
                          style={thStyle}
                          key={key}
                          data-testid={key}
                          className={colspec.className || ""}
                          onPress={() => requestSort(`${colspec.sorterName}`)}
                        >
                          {colspec.title}
                        </TableHeadingButton>
                      );
                    } else if (colspec.isAlertCell || colspec.isActionButton) {
                      return <th style={thStyle} key={key} data-testid={key} className={colspec.className || ""}></th>;
                    } else if (colspec.isReadyCell) {
                      return (
                        <th style={{ ...thStyle, textAlign: "center" }} key={key} data-testid={key} className="status">
                          {colspec.title}
                        </th>
                      );
                    } else {
                      return (
                        <th style={thStyle} key={key} data-testid={key} className={colspec.className || ""}>
                          {colspec.title}
                        </th>
                      );
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {!items.length && (
                  <tr>
                    <td colSpan={specs.length} className="text-center">
                      <strong>no data found</strong>
                    </td>
                  </tr>
                )}
                {pagedItems.map(s => {
                  const actionButtonClick = () => {
                    onActionButtonClick && onActionButtonClick(`${s.id}`);
                  };
                  const rowClick = () => {
                    onClickRow(`${s.id}`);
                  };
                  return (
                    <tr
                      key={`general-list-item-key-${s.id}`}
                      data-testid={`general-list-item-testid-${s.id}`}
                      className={classnames("general-list-data-row", { "active-row": activeItem === s.id })}
                    >
                      {specs.map(colspec => {
                        const key = `general-list-value-cell-${colspec.name}-${s.id}`;
                        if (colspec.isStrongCell) {
                          return (
                            <td key={key} onClick={rowClick} data-testid={key} className={colspec.className || ""}>
                              <strong>{s[colspec.name]}</strong>
                            </td>
                          );
                        } else if (colspec.isReadyCell) {
                          const ready = !!s[colspec.name];
                          return (
                            <td key={key} onClick={rowClick} data-testid={key} className="status">
                              <i
                                className={classnames("fal", {
                                  active: ready,
                                  "fa-check-circle": ready,
                                  "fa-times-circle": !ready
                                })}
                              ></i>
                            </td>
                          );
                        } else if (colspec.isAlertCell) {
                          return (
                            <td key={key} onClick={rowClick} data-testid={key} title={colspec.tooltip}>
                              {!!s.alert && <span style={{ color: "red", fontSize: "16px" }}>&#9679;</span>}
                            </td>
                          );
                        } else if (colspec.isActionButton) {
                          const buttonClasses = colspec.actionButtonIconClasses ?? "fal icon fa-at";
                          return (
                            <td
                              key={key}
                              data-testid={key}
                              onClick={activeItem !== s.id ? rowClick : undefined}
                              className="general-list-action-button-cell"
                            >
                              <button
                                onClick={actionButtonClick}
                                style={{ visibility: activeItem === s.id ? "visible" : "hidden" }}
                                className="unstyled general-list-action-button show-on-row-hover"
                              >
                                <i className={buttonClasses}></i>
                              </button>
                            </td>
                          );
                        } else {
                          return (
                            <td key={key} onClick={rowClick} data-testid={key} className={colspec.className || ""}>
                              {s[colspec.name]}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
                {emptyRows.map((itm, index) => {
                  return (
                    <tr key={`general-list-empty-row-${index}`} className="general-list-empty-row">
                      <td colSpan={specs?.length} className="general-list-empty-row">
                        &nbsp;
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {totalPages > 1 && (
                <tfoot>
                  <tr style={{ verticalAlign: "middle" }}>
                    <td colSpan={specs.length} className="text-center">
                      <button
                        disabled={page === 0}
                        className={"general-list-footer-arrow"}
                        onClick={() => requestPage(page - 1)}
                      >
                        &#9664;
                      </button>
                      <span style={{ display: "inline-block" }}>
                        Page {page + 1} of {totalPages}
                      </span>
                      <button
                        disabled={page === totalPages - 1}
                        className={"general-list-footer-arrow"}
                        onClick={() => requestPage(page + 1)}
                      >
                        &#9654;
                      </button>
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </div>
      </>
    );
  }
);

GeneralList.displayName = "GeneralList";
