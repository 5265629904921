import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as Types from "./types";
import * as UsersMocks from "./users";

export interface CentersState {
  centers: Types.Center[];
}

export interface SaveCenterTuitionRatePayload {
  centerId: Types.UniqueId;
  rate: Types.CenterTuitionRate;
}

export interface SaveCenterResourcePayload {
  centerId: Types.UniqueId;
  resource: Types.CenterResource;
}

const initialState: CentersState = {
  centers: [...UsersMocks.centers]
};

export const centersSlice = createSlice({
  name: "centers",
  initialState,
  reducers: {
    loadCenters: (state, action: PayloadAction<Types.Center[]>) => {
      state.centers = [...action.payload];
    },
    saveCenterTuitionRate: (state, action: PayloadAction<SaveCenterTuitionRatePayload>) => {
      if (!action.payload.rate) {
        return;
      }
      const center = state.centers.find(c => c.id === action.payload.centerId);
      if (center) {
        if (!center.tuitionRates) {
          center.tuitionRates = [];
        }
        const rate = center.tuitionRates.find(r => r.id === action.payload.rate.id);
        if (rate) {
          // you cannot edit the id or type of an existing rate
          rate.description = action.payload.rate.description;
          rate.hours = action.payload.rate.hours;
          rate.rate = action.payload.rate.rate;
        } else {
          center.tuitionRates.push(action.payload.rate);
        }
      }
    },
    saveCenterResource: (state, action: PayloadAction<SaveCenterResourcePayload>) => {
      if (!action.payload.resource) {
        return;
      }
      const center = state.centers.find(c => c.id === action.payload.centerId);
      if (center) {
        if (!center.resources) {
          center.resources = [];
        }
        const resource = center.resources.find(r => r.id === action.payload.resource.id);
        if (resource) {
          const updated = { ...action.payload.resource, schedules: [...action.payload.resource.schedules] };
          center.resources = [...center.resources.filter(r => r.id !== updated.id), updated];
        } else {
          center.resources.push(action.payload.resource);
        }
      }
    }
  }
});

export const { loadCenters, saveCenterTuitionRate, saveCenterResource } = centersSlice.actions;

export default centersSlice.reducer;
