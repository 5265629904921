import React, { useCallback, useState } from "react";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";

import * as Types from "../../data/types";

import "./edit-center-resources.scss";
import { ScheduleEdit } from "../schedule-edit/schedule-edit";

export interface EditCenterResourcesProps {
  resource?: Types.CenterResource;
  onSave?: (rate: Types.CenterResource) => void;
  onCancel?: () => void;
}

export interface FieldProps {
  value?: string;
  mode?: "edit" | "view";
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Field: React.FC<FieldProps> = ({ value, mode, onChange }) => {
  if (mode === "view") {
    return <>{value}</>;
  } else {
    return <input type="text" value={value} style={{ width: "100%" }} className="form-control" onChange={onChange} />;
  }
};

export const EditCenterResources: React.FC<EditCenterResourcesProps> = ({ resource, onSave, onCancel }) => {
  const [localResource, setLocalResource] = useState<Types.CenterResource>(
    resource ? { ...resource } : { id: uuidv4(), name: "", capacity: 0, schedules: [] }
  );
  const localSave = useCallback(() => {
    if (onSave) {
      console.log("saving", localResource);
      onSave(localResource);
    }
    if (onCancel) {
      onCancel();
    }
  }, [onSave, onCancel, localResource]);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalResource(prev => {
      return { ...prev, name: e.target.value };
    });
  };

  const onChangeCapacity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = !!e.target.value ? parseInt(e.target.value, 10) : 0;
    setLocalResource(prev => {
      return { ...prev, capacity: userInput };
    });
  };

  const onChangeSchedule = useCallback((schedule: Types.WeekSchedule) => {
    setLocalResource(prev => {
      const otherSchedules = prev.schedules?.filter(s => s.id !== schedule.id) ?? [];
      const newValue = { ...prev, schedules: [...otherSchedules, schedule] };
      //console.log(prev, newValue);
      return newValue;
    });
  }, []);

  return (
    <div className={classnames("edit-center-resource-outer-container")}>
      <h2>Center Resource</h2>
      <div className="edit-center-resource-container">
        <div className="row">
          <div className="col form-floating">
            <Field value={localResource?.name ?? ""} mode={"edit"} onChange={onChangeName} />
            <label>Name</label>
          </div>
        </div>
        <div className="row">
          <div className="col form-floating">
            <Field value={localResource?.capacity?.toString() ?? ""} mode={"edit"} onChange={onChangeCapacity} />
            <label>Capacity</label>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div>
              <ScheduleEdit schedule={localResource?.schedules?.[0]} onChangeSchedule={onChangeSchedule} />
            </div>
          </div>
        </div>
      </div>

      <div className={classnames("edit-center-resource-button-row")}>
        <button className="btn btn-secondary" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={localSave}>
          Save
        </button>
      </div>
    </div>
  );
};
