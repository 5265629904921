import moment from "moment";

import * as Types from "../types";

import { getMinutesFromTimestamp } from "./minutes-from-timestamp";

export const isAvailableForTimeRange = (
  start: number | undefined,
  end: number | undefined,
  schedule: Types.WeekSchedule | undefined,
  center: Types.Center | undefined
) => {
  if (center && schedule && start && end) {
    const day = Types.daysOfWeek[moment(start).day()];
    const startMinutes = getMinutesFromTimestamp(start);
    const endMinutes = getMinutesFromTimestamp(end);

    if (center.holidays?.length) {
      for (const holiday of center.holidays) {
        const holidayDate = new Date(holiday.date + ", " + moment(start).year().toString());
        if (moment(holidayDate).startOf("d").valueOf() === moment(start).startOf("d").valueOf()) {
          return false;
        }
      }
    }

    if (center.hoursOfOperation?.length) {
      const hoo = center.hoursOfOperation.find(ho => ho.day === day);
      const startTime = hoo?.startAppointments !== undefined ? hoo.startAppointments : hoo?.start;
      if (!startTime || startTime > endMinutes) {
        return false;
      }

      const endTime = hoo?.endAppointments !== undefined ? hoo.endAppointments : hoo?.end;
      if (!endTime || endTime < startMinutes) {
        return false;
      }
    }

    const ranges = schedule.days.filter(d => d.day?.toString() === day);
    if (ranges?.length) {
      return ranges.some(r => startMinutes >= (r.start ?? 0) && endMinutes <= (r.end ?? 0));
    }
  }
  return false;
};
