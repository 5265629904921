import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useModal } from "react-hooks-use-modal";
import { useReactToPrint } from "react-to-print";

import * as Types from "../../data/types";
import * as Store from "../../data/store";
import * as CentersSlice from "../../data/centers-slice";

import { useAuthentication } from "../../app/authentication-context";
import { CenterSelector } from "../../components/center-selector/center-selector";
import { CenterHours } from "../../components/center-hours/center-hours";
import { CenterInformation } from "../../components/center-information/center-information";
import { CenterNotes } from "../../components/center-notes/center-notes";
import { CenterRates } from "../../components/center-rates/center-rates";
import { CenterResources } from "../../components/center-resources/center-resources";
import { CenterServiceRatios } from "../../components/center-service-ratios/center-service-ratios";
import { AppLayout, Rightside, Leftside, ContentPad, Utility } from "../../app/app-layout";
import { ListHeader } from "../../components/list-header/list-header";

import "./centers.scss";

export interface Actions {
  onSaveCenterTuitionRate: (payload: { centerId: Types.UniqueId; rate: Types.CenterTuitionRate }) => void;
  onSaveCenterResource: (payload: { centerId: Types.UniqueId; resource: Types.CenterResource }) => void;
}

export interface CentersProps {
  centers?: Types.Center[];
  dispatch?: Store.AppDispatch;
  actions?: Actions;
}

export const Centers: React.FC<CentersProps> = ({ centers = [], actions }) => {
  const { user, activeCenter, changeActiveCenter } = useAuthentication();

  const infoRef = useRef<HTMLDivElement>(null);
  const hoursRef = useRef<HTMLDivElement>(null);
  const notesRef = useRef<HTMLDivElement>(null);
  const tuitionRatesRef = useRef<HTMLDivElement>(null);
  const discountProgramsRef = useRef<HTMLDivElement>(null);
  const resourcesRef = useRef<HTMLDivElement>(null);
  const ratiosRef = useRef<HTMLDivElement>(null);

  const [selectedCenter, setSelectedCenter] = useState<Types.Center | undefined>(
    !!activeCenter?.id ? centers.find(c => c.id === activeCenter.id) : undefined
  );
  const [switchCandidate, setSwitchCandidate] = useState<Types.Center>();

  const [SwitchCenterConfirm, openSwitchCenterConfirm, closeSwitchCenterConfirm] = useModal("utility-root", {
    preventScroll: true
  });

  const [EditCenterInformation, openEditCenterInformation, closeEditCenterInformation] = useModal("utility-root", {
    preventScroll: true
  });

  const onSelectCenter = (id: string) => {
    if (id && user?.centers?.length && user.centers.length > 1) {
      setSelectedCenter(user.centers.find(c => c.id === id));
    } else {
      setSelectedCenter(undefined);
    }
  };

  const onCenterSelectorActionButtonClick = (id: string) => {
    if (id && user?.centers?.length && user.centers.length > 1) {
      setSwitchCandidate(user.centers.find(c => c.id === id));
    }
  };

  useEffect(() => {
    if (switchCandidate) {
      openSwitchCenterConfirm();
    } else {
      closeSwitchCenterConfirm();
    }
  }, [switchCandidate, openSwitchCenterConfirm, closeSwitchCenterConfirm]);

  const onConfirmSwitchCenter = () => {
    if (switchCandidate) {
      changeActiveCenter(switchCandidate);
      setSwitchCandidate(undefined);
    }
  };

  const onSaveCenterTuitionRate = (payload: CentersSlice.SaveCenterTuitionRatePayload) => {
    actions?.onSaveCenterTuitionRate(payload);
  };

  const onSaveCenterResource = (payload: CentersSlice.SaveCenterResourcePayload) => {
    actions?.onSaveCenterResource(payload);
  };

  const [filter, setFilter] = useState<string>("");
  const onFilterChange = useCallback((newvalue: string) => {
    setFilter(newvalue);
  }, []);
  const tableRef = useRef<HTMLTableElement>(null);
  const onPrintRequested = useReactToPrint({
    content: () => tableRef.current
  });

  if (!user) {
    return null;
  }

  return (
    <AppLayout activeTab="Centers">
      <Leftside size={5}>
        <Utility>
          <ListHeader filter={filter} onFilterChange={onFilterChange} onPrintRequested={onPrintRequested} />
        </Utility>
        <ContentPad>
          <CenterSelector
            centers={user.centers}
            selected={selectedCenter?.id}
            filter={filter}
            onSelect={onSelectCenter}
            onAction={onCenterSelectorActionButtonClick}
            ref={tableRef}
          />
          <SwitchCenterConfirm>
            <div className="centers-confirm-active-school-modal">
              <h5>Confirm</h5>
              <p>
                Do you want to change your currently active school to {switchCandidate?.name}, {switchCandidate?.state}
              </p>
              <div className="centers-confirm-active-school-modal-button-row">
                <button className="btn btn-primary" onClick={onConfirmSwitchCenter}>
                  Yes
                </button>
                <button className="btn btn-secondary" onClick={() => setSwitchCandidate(undefined)}>
                  No
                </button>
              </div>
            </div>
          </SwitchCenterConfirm>
        </ContentPad>
      </Leftside>
      <Rightside size={7} showIf={!!selectedCenter}>
        <ContentPad>
          <div ref={infoRef}>
            <CenterInformation
              center={selectedCenter}
              onEdit={
                user.roles?.includes("owner")
                  ? () => {
                      openEditCenterInformation();
                    }
                  : undefined
              }
            />
            <EditCenterInformation>
              <CenterInformation
                center={selectedCenter}
                mode={"edit"}
                onSave={() => closeEditCenterInformation()}
                onCancel={() => closeEditCenterInformation()}
              />
            </EditCenterInformation>
          </div>

          <br />

          <div ref={hoursRef}>
            <CenterHours hours={selectedCenter?.hoursOfOperation} holidays={selectedCenter?.holidays} />
          </div>

          {user.roles?.includes("owner") && !!selectedCenter && (
            <>
              <br />
              <div ref={tuitionRatesRef}>
                <CenterRates
                  centerId={selectedCenter.id}
                  type={"tuition"}
                  onSave={onSaveCenterTuitionRate}
                  rates={
                    centers.find(c => c.id === selectedCenter.id)?.tuitionRates?.filter(tr => tr.type === "tuition") ??
                    []
                  }
                />
              </div>

              <br />
              <div ref={discountProgramsRef}>
                <CenterRates
                  centerId={selectedCenter.id}
                  type={"discount"}
                  onSave={onSaveCenterTuitionRate}
                  rates={
                    centers.find(c => c.id === selectedCenter.id)?.tuitionRates?.filter(tr => tr.type === "discount") ??
                    []
                  }
                />
              </div>

              <br />
              <div ref={resourcesRef}>
                <CenterResources
                  resources={centers.find(c => c.id === selectedCenter.id)?.resources ?? []}
                  centerId={selectedCenter.id}
                  onSave={onSaveCenterResource}
                />
              </div>

              <br />
              <div ref={notesRef}>
                <CenterNotes notes={selectedCenter?.notes} />
              </div>

              <br />
              <div ref={ratiosRef}>
                <CenterServiceRatios
                  //onSave={onSaveCenterTuitionRate}
                  ratios={centers.find(c => c.id === selectedCenter.id)?.ratios ?? []}
                />
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </>
          )}
        </ContentPad>
      </Rightside>
    </AppLayout>
  );
};

export const mapStateToProps = (state: Store.RootState) => ({
  centers: state?.centers?.centers ?? []
});

export const mapDispatchToProps = (dispatch: Store.AppDispatch) => {
  const props: { actions: Actions; dispatch: Store.AppDispatch } = {
    actions: {
      onSaveCenterTuitionRate: (payload: CentersSlice.SaveCenterTuitionRatePayload) =>
        dispatch(CentersSlice.saveCenterTuitionRate(payload)),

      onSaveCenterResource: (payload: CentersSlice.SaveCenterResourcePayload) =>
        dispatch(CentersSlice.saveCenterResource(payload))
    },
    dispatch
  };
  return props;
};

export default connect(mapStateToProps, mapDispatchToProps)(Centers);
