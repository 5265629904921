import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import * as Types from "../../../data/types";
import * as Utils from "../../../data/utils";
import { ItemType } from "../../../hooks/use-sortable-filterable-data";
import {
  GeneralList,
  GeneralListColumnSpecification,
  GeneralListDataMapHandler,
  GeneralListItemSelectHandler
} from "../../general-list/general-list";
import { ScheduleCell } from "../../general-list/schedule-cell";

import "./scheduling.scss";
import { EditStaffAvailability } from "./edit-staff-availability";
import { useModal } from "react-hooks-use-modal";

import * as StaffSlice from "../../../data/staff-slice";

export interface SchedulingProps {
  centerId: Types.UniqueId;
  employeeId: Types.UniqueId;
  schedule?: Types.EmployeeWeekSchedule[];
  availability?: Types.EmployeeWeekSchedule[];
  onSaveStaffAvailability?: (payload: StaffSlice.SaveStaffAvailabilityPayload) => void;
}

export const Scheduling: React.FC<SchedulingProps> = ({
  availability,
  centerId,
  employeeId,
  onSaveStaffAvailability
}) => {
  return (
    <div className="staff-scheduling-container">
      <div>
        <Schedule
          schedules={availability}
          centerId={centerId}
          employeeId={employeeId}
          onSaveStaffAvailability={onSaveStaffAvailability}
        />
      </div>
    </div>
  );
};

export interface ScheduleProps {
  centerId: Types.UniqueId;
  employeeId: Types.UniqueId;
  schedules?: Types.EmployeeWeekSchedule[];
  onSaveStaffAvailability?: (payload: StaffSlice.SaveStaffAvailabilityPayload) => void;
}

export const Schedule: React.FC<ScheduleProps> = ({ centerId, employeeId, schedules, onSaveStaffAvailability }) => {
  const [selected, setSelected] = useState<Types.EmployeeWeekSchedule>();
  const [editing, setEditing] = useState<Types.EmployeeWeekSchedule>();

  const onSelectItem: GeneralListItemSelectHandler = item => {
    setSelected(schedules?.find(s => s.id === item.id));
  };

  const [EditCenterRateModal, openEditCenterRateModal, closeEditCenterRateModal] = useModal("utility-root", {
    preventScroll: false,
    closeOnOverlayClick: false
  });

  const onActionButtonClick = (id: string) => {
    setEditing(schedules?.find(s => s.id === id));
  };

  useEffect(() => {
    if (editing) {
      openEditCenterRateModal();
    } else {
      closeEditCenterRateModal();
    }
  }, [editing, openEditCenterRateModal, closeEditCenterRateModal]);

  const onCancelEditing = () => {
    setEditing(undefined);
  };

  const onSaveEditing = (schedule: Types.EmployeeWeekSchedule) => {
    if (schedule && schedule.id && onSaveStaffAvailability) {
      console.log("saving", schedule);
      onSaveStaffAvailability({ centerId, staffId: employeeId, schedule: { ...schedule } });
      setEditing(undefined);
    }
  };

  const onAddResource = () => {
    setEditing({ id: uuidv4(), days: [{ id: uuidv4(), ratio: 1 }] });
  };

  return (
    <>
      <div>
        <h5>Availability</h5>
        <GeneralList
          data={schedules}
          mapper={employeeSchedulesMapper}
          specs={employeeSchedulesSpecs}
          activeItem={selected?.id}
          onSelectItem={onSelectItem}
          onActionButtonClick={onActionButtonClick}
          pageSize={6}
        />
      </div>
      <div className="center-resource-toolbar">
        <button className="btn btn-primary" onClick={onAddResource}>
          Add New Schedule
        </button>
      </div>

      {!!editing && editing && (
        <EditCenterRateModal>
          <EditStaffAvailability
            schedule={schedules?.find(s => s.id === editing.id) ?? editing}
            onCancel={onCancelEditing}
            onSave={onSaveEditing}
          />
        </EditCenterRateModal>
      )}
    </>
  );
};

export const employeeSchedulesMapper: GeneralListDataMapHandler = function (this, schedule, index?, array?) {
  const s = schedule as Types.EmployeeWeekSchedule;
  const scheduleCell = <ScheduleCell schedule={s} />;
  return {
    id: s.id,
    effective: Utils.formatDate(s.effective),
    effectiveSorter: s.effective,
    expiration: Utils.formatDate(s.expiration),
    expirationSorter: s.expiration,
    scheduleCell
  } as ItemType;
};

export const employeeSchedulesSpecs: GeneralListColumnSpecification[] = [
  {
    title: "Start Date",
    name: "effective",
    sorterName: "effectiveSorter",
    className: "primary",
    isStrongCell: true,
    defaultSort: true
  },
  { title: "End Date", name: "expiration", sorterName: "expirationSorter" },
  { title: "Schedule", name: "scheduleCell" },
  { title: "Activate", name: "activate", isActionButton: true, actionButtonIconClasses: "far fa-pencil" }
];
