import * as Types from "./types";
import * as Utils from "./utils";
import { v4 as uuidv4 } from "uuid";

const hoo1: Types.CenterScheduleItem[] = [
  { id: "001", day: "sunday", start: 600, end: 960 },
  { id: "002", day: "monday", start: 600, end: 1200, startAppointments: 660, endAppointments: 1140 },
  { id: "003", day: "tuesday", start: 600, end: 1200, startAppointments: 660, endAppointments: 1140 },
  { id: "004", day: "wednesday", start: 600, end: 1200, startAppointments: 660, endAppointments: 1140 },
  { id: "005", day: "thursday", start: 600, end: 1200, startAppointments: 660, endAppointments: 1140 },
  { id: "006", day: "friday", start: 720, end: 960 },
  { id: "007", day: "saturday", start: 540, end: 900 }
];

const hoo2: Types.CenterScheduleItem[] = [
  { id: "001", day: "sunday", start: 0, end: 0 },
  { id: "002", day: "monday", start: 600, end: 1200, startAppointments: 660, endAppointments: 1140 },
  { id: "003", day: "tuesday", start: 600, end: 1200, startAppointments: 660, endAppointments: 1140 },
  { id: "004", day: "wednesday", start: 600, end: 1200, startAppointments: 660, endAppointments: 1140 },
  { id: "005", day: "thursday", start: 600, end: 1200, startAppointments: 660, endAppointments: 1140 },
  { id: "006", day: "friday", start: 720, end: 960 },
  { id: "007", day: "saturday", start: 540, end: 1020 }
];

const holidays: Types.CenterHolidayItem[] = [
  { id: "001", name: "New Year's Day", date: "Jan 1" },
  { id: "002", name: "Memorial Day", date: "May 30" },
  { id: "003", name: "Independence Day", date: "Jul 4" },
  //{ id: "004", name: "Labor Day", date: "Sept 5" },
  { id: "005", name: "Thanksgiving Day", date: "Nov 24" },
  { id: "006", name: "Black Friday", date: "Nov 25" },
  { id: "007", name: "Christmas", date: "Dec 25" }
];

const notes: Types.CenterNote[] = [
  {
    id: "001",
    date: new Date("2021-11-04").getTime(),
    title: "Integer id dapibus felis",
    content:
      "Cras convallis, orci vitae aliquam condimentum, lorem lorem convallis lacus, hendrerit mollis enim orci at est. Donec vel ante facilisis, viverra lorem id, malesuada arcu. Vestibulum nec tellus elit. Etiam augue nunc, egestas sit amet erat id, iaculis mollis eros. Aenean vitae nulla non purus interdum egestas. Nullam aliquet volutpat leo, ac congue arcu ultrices ac. In elit quam, convallis a lectus quis, feugiat commodo tellus. Donec turpis lectus, euismod vitae mattis id, fermentum sit amet neque. Cras eu tellus dolor."
  },
  {
    id: "002",
    date: new Date("2021-12-13").getTime(),
    title: "Curabitur tristique malesuada nibh, sed rhoncus magna vulputate ac",
    content:
      "Nunc vel blandit neque. Mauris in iaculis arcu. Donec dui urna, congue et pellentesque in, aliquam eget nibh. Nulla semper eleifend sapien, mattis eleifend diam. Mauris non arcu elementum ipsum mattis ornare at sagittis lectus. Morbi semper mi eget sodales gravida. In tempor condimentum purus. Duis varius consectetur risus, at elementum elit feugiat non."
  },
  {
    id: "003",
    date: new Date("2022-01-02").getTime(),
    title: "Donec luctus magna libero",
    content:
      "Aenean nec lacinia libero. Duis volutpat accumsan justo non pretium. Sed dictum feugiat nulla, non finibus magna facilisis quis. Nam ut varius odio, vel congue purus. Mauris tempus elit id ipsum interdum lacinia. Pellentesque quam turpis, vestibulum ut sem eget, tempor commodo ex."
  },
  {
    id: "004",
    date: new Date("2022-01-29").getTime(),
    title: "Fusce semper consequat mi",
    content:
      "Proin lobortis nisi vel tellus fringilla consectetur. Ut vel sollicitudin massa, ac facilisis tellus. Ut et mollis neque. Nullam sit amet interdum massa. Curabitur efficitur mollis lorem, ac dignissim augue tempus sit amet."
  },
  {
    id: "005",
    //date: new Date("2022-02-08").getTime(),
    date: Date.parse("2022-02-08"),
    title: "Sed interdum",
    content:
      "Sed eu orci imperdiet, interdum neque in, congue eros. Suspendisse potenti. Nam congue facilisis augue, at imperdiet mauris varius sit amet."
  }
];

const notes1: Types.CenterNote[] = [notes[0], notes[2], notes[4]];
const notes2: Types.CenterNote[] = [notes[1], notes[3]];
const notes3: Types.CenterNote[] = [notes[2]];

const rates: Types.CenterTuitionRate[] = [
  { id: "112302L1", type: "tuition", rate: 45, description: "Learning Center 1-1" },
  { id: "091706LC", type: "tuition", rate: 40, description: "Learning Center" },
  { id: "032012LS", type: "tuition", rate: 50, description: "Learning Center Standard" },
  { id: "BACK_TO_SCHOOL", type: "discount", rate: 0.1, description: "Back to School Discount", hours: 50 },
  { id: "BULK_125", type: "discount", rate: 0.08, description: "Bulk Purchase Discount", hours: 100 }
];

export const resourceSchedule1: Types.WeekSchedule = {
  id: "week-schedule-01",
  days: [
    { id: "week-schedule-01-sunday", day: "sunday", start: Utils.timeHelper(9), end: Utils.timeHelper(15) },
    { id: "week-schedule-01-monday", day: "monday", start: Utils.timeHelper(10), end: Utils.timeHelper(21) },
    { id: "week-schedule-01-tuesday", day: "tuesday", start: Utils.timeHelper(10), end: Utils.timeHelper(21) },
    { id: "week-schedule-01-wednesday", day: "wednesday", start: Utils.timeHelper(10), end: Utils.timeHelper(21) },
    { id: "week-schedule-01-thursday", day: "thursday", start: Utils.timeHelper(10), end: Utils.timeHelper(21) },
    { id: "week-schedule-01-friday", day: "friday", start: Utils.timeHelper(16), end: Utils.timeHelper(19) },
    { id: "week-schedule-01-saturday", day: "saturday", start: Utils.timeHelper(9), end: Utils.timeHelper(15) }
  ]
};

export const resourceSchedule2: Types.WeekSchedule = {
  id: "week-schedule-02",
  days: [
    { id: "week-schedule-02-sunday", day: "sunday", start: Utils.timeHelper(9), end: Utils.timeHelper(15) },
    { id: "week-schedule-02-monday", day: "monday", start: Utils.timeHelper(10), end: Utils.timeHelper(21) },
    { id: "week-schedule-02-tuesday", day: "tuesday", start: Utils.timeHelper(10), end: Utils.timeHelper(21) },
    { id: "week-schedule-02-wednesday", day: "wednesday", start: Utils.timeHelper(10), end: Utils.timeHelper(21) },
    { id: "week-schedule-02-thursday", day: "thursday", start: Utils.timeHelper(10), end: Utils.timeHelper(21) },
    { id: "week-schedule-02-friday", day: "friday", start: Utils.timeHelper(16), end: Utils.timeHelper(19) },
    { id: "week-schedule-02-saturday", day: "saturday", start: Utils.timeHelper(9), end: Utils.timeHelper(15) }
  ]
};

const centerRatios: Types.CenterServiceRatio[] = Types.serviceTypes.map(st => {
  return {
    id: uuidv4(),
    service: st,
    toOne: st === "LS" ? 4 : 1
  };
});

export const centers: Types.Center[] = [
  {
    id: "123123",
    name: "Hunters Creek, Orlando",
    state: "FL",
    zipcode: "32804",
    hoursOfOperation: hoo1,
    holidays: holidays,
    notes: notes1,
    line1: "13526 Village Park Drive",
    line2: "Suite 214",
    city: "Orlando",
    phone: "407-613-5970",
    fax: "",
    email: "",
    url: "https://huntingtonhelps.com/center/hunterscreek",
    tuitionRates: [...rates],
    resources: [
      { id: "center-resource-01", name: "Testing Room", schedules: [resourceSchedule1], capacity: 15 },
      { id: "center-resource-02", name: "Phonics Room", schedules: [resourceSchedule2], capacity: 4 }
    ],
    ratios: [...centerRatios]
  },
  {
    id: "231231",
    name: "Kirkwood, Newark",
    state: "DE",
    zipcode: "19701",
    hoursOfOperation: hoo1,
    holidays: holidays,
    notes: notes2,
    line1: "34 Possum Park Mall",
    line2: "",
    gps: "700 Capitol Trail",
    city: "Newark",
    phone: "302-737-1050",
    fax: "",
    email: "",
    url: "https://huntingtonhelps.com/center/newark",
    tuitionRates: [...rates],
    ratios: [...centerRatios]
  },
  {
    id: "343343",
    name: "Greenville, Wilmington",
    state: "DE",
    zipcode: "19808",
    hoursOfOperation: hoo1,
    holidays: holidays,
    notes: notes3,
    line1: "3615 Silverside Rd",
    line2: "Talleyville Shopping Center",
    city: "Wilmington",
    phone: "302-478-4242",
    fax: "",
    email: "",
    url: "https://huntingtonhelps.com/center/wilmington-de",
    tuitionRates: [...rates],
    ratios: [...centerRatios]
  },
  {
    id: "643564",
    name: "Upper East Side, New York",
    state: "NY",
    zipcode: "10128",
    hoursOfOperation: hoo1,
    holidays: holidays,
    notes: notes1,
    line1: "1556 Third Avenue, Suite 209",
    line2: "Use entrance at 177 East 87th Street",
    city: "New York",
    phone: "212-534-3200",
    fax: "",
    email: "",
    url: "https://huntingtonhelps.com/center/upper-east-side",
    tuitionRates: [...rates],
    ratios: [...centerRatios]
  },
  {
    id: "931267",
    name: "Bay Ridge, Brooklyn",
    state: "NY",
    zipcode: "11209",
    hoursOfOperation: hoo1,
    holidays: holidays,
    notes: notes2,
    line1: "514 86th Street",
    line2: "2nd Floor",
    city: "Brooklyn",
    phone: "718-491-0900",
    fax: "",
    email: "",
    url: "https://huntingtonhelps.com/center/bay-ridge",
    tuitionRates: [...rates],
    ratios: [...centerRatios]
  },
  {
    id: "753123",
    name: "Clear Lake, Houston",
    state: "TX",
    zipcode: "77062",
    hoursOfOperation: hoo1,
    holidays: holidays,
    notes: notes1,
    line1: "2323 Clear Lake City Blvd, Suite 170",
    line2:
      "We are located in the Randall's shopping center at the intersection of Clear Lake City Blvd and Space Center Blvd near the entrance to Bay Oaks subdivision",
    city: "Houston",
    phone: "832-464-7500",
    fax: "",
    email: "",
    url: "https://huntingtonhelps.com/center/clear-lake-tx",
    tuitionRates: [...rates],
    ratios: [...centerRatios]
  },
  {
    id: "675489",
    name: "Perry Hall, MD",
    state: "MD",
    zipcode: "21236",
    hoursOfOperation: hoo1,
    holidays: holidays,
    notes: notes3,
    line1: "8804 Belair Road",
    line2: "",
    city: "Baltimore",
    phone: "410-256-3390",
    fax: "",
    email: "",
    url: "https://huntingtonhelps.com/center/perry-hall",
    tuitionRates: [...rates],
    ratios: [...centerRatios]
  },
  {
    id: "494564",
    name: "Weddington, Mathews",
    state: "NC",
    zipcode: "28104",
    hoursOfOperation: hoo1,
    holidays: holidays,
    line1: "5945 Weddington-Monroe Road",
    line2: "Suite 104",
    city: "Mathews",
    phone: "704-243-5784",
    fax: "",
    email: "",
    url: "https://huntingtonhelps.com/center/weddington",
    tuitionRates: [...rates],
    ratios: [...centerRatios]
  },
  {
    id: "912346",
    name: "Woodbridge-Lake Ridge",
    state: "VA",
    zipcode: "22192",
    hoursOfOperation: hoo1,
    holidays: holidays,
    notes: notes1,
    line1: "12379 Dillingham Square",
    line2: "",
    city: "Lake Ridge",
    phone: "703-670-9997",
    fax: "",
    email: "",
    url: "https://huntingtonhelps.com/center/woodbridge-lake-ridge-va",
    tuitionRates: [...rates],
    ratios: [...centerRatios]
  },
  {
    id: "718956",
    name: "Fishers, McCordsville",
    state: "IN",
    zipcode: "46055",
    hoursOfOperation: hoo1,
    holidays: holidays,
    line1: "9665 Olio Road",
    line2: "We are located on the Northeast corner of 96th Street and Olio Rd. next door to Subway",
    city: "McCordsville",
    phone: "317-344-9300",
    fax: "",
    email: "",
    url: "https://huntingtonhelps.com/center/fishers",
    tuitionRates: [...rates],
    ratios: [...centerRatios]
  },
  {
    id: "336698",
    name: "Washington Road, Evans",
    state: "GA",
    zipcode: "30809",
    hoursOfOperation: hoo2,
    holidays: holidays,
    notes: notes3,
    line1: "4446 Washington Road",
    line2: "",
    city: "Evans",
    phone: "706-229-8833",
    fax: "",
    email: "",
    url: "https://huntingtonhelps.com/center/evans",
    tuitionRates: [...rates],
    ratios: [...centerRatios]
  }
];

export const admin: Types.User = {
  id: "111",
  username: "admin",
  name: "Main Administrator",
  sortableName: "Administrator, Main",
  initials: "MA",
  centers: [...centers],
  roles: ["admin", "owner", "faculty"]
};

export const owner1: Types.User = {
  id: "222",
  username: "owner1",
  name: "Standard Owner",
  sortableName: "Owner, Standard",
  initials: "SO",
  centers: [centers[1]],
  roles: ["owner", "faculty"]
};

export const owner2: Types.User = {
  id: "222",
  username: "owner2",
  name: "Multiple Owner",
  sortableName: "Owner, Multiple",
  initials: "MO",
  centers: [...centers.slice(0, 3)],
  roles: ["owner", "faculty"]
};

export const faculty1: Types.User = {
  id: "222",
  username: "faculty1",
  name: "Jon Faculty",
  sortableName: "Faculty, Jon",
  initials: "JF",
  centers: [centers[1]],
  roles: ["faculty"]
};

export const users = [admin, owner1, owner2, faculty1];
