import React, { useMemo } from "react";
import { connect } from "react-redux";

import { useAuthentication } from "../../app/authentication-context";

import * as Types from "../../data/types";
import * as Store from "../../data/store";
import * as ScheduleSlice from "../../data/schedule-slice";
import { AppLayout, Leftside } from "../../app/app-layout";
// import { ScheduleMasterControls } from "../../components/schedule-master/schedule-master-controls";
// import { ScheduleDay } from "../../components/schedule-master/schedule-day";

export interface Actions {
  onSaveScheduleEvent: (payload: ScheduleSlice.SaveScheduleEvent) => void;
  onDeleteScheduleEvent?: (payload: ScheduleSlice.DeleteScheduleEvent) => void;
}

export interface SchedulingProps {
  centers?: Types.Center[];
  students?: Types.Student[];
  staff?: Types.Employee[];
  events?: Types.ScheduleEvent[];
  actions?: Actions;
}

export const Scheduling: React.FC<SchedulingProps> = ({ centers, students, staff, events, actions }) => {
  const { activeCenter } = useAuthentication();
  const center = useMemo(() => centers?.find(c => c.id === activeCenter?.id), [centers, activeCenter?.id]);

  // const [dates, setDates] = useState<string[]>([]);
  // const [serviceFilter, setServiceFilter] = useState<string>("ALL");
  // const [options, setOptions] = useState<string[]>(["subjects"]);

  // const onChangeDates = useCallback((newdates: string[]) => {
  //   setDates(newdates);
  // }, []);

  // const onChangeServiceFilter = useCallback((newvalue: string) => {
  //   setServiceFilter(newvalue);
  // }, []);

  // const onChangeOptions = useCallback((newvalue: string[]) => {
  //   setOptions(newvalue);
  // }, []);

  if (!activeCenter || !center || !students || !staff || !events) {
    return null;
  }

  return (
    <AppLayout activeTab="Scheduling">
      <Leftside size={12}>
        <iframe src="/scheduling-framed" title="Schedule" width="100%" height="100%" style={{ overflow: "scroll" }} />
        {/* <Utility>
          <ScheduleMasterControls
            onChangeDates={onChangeDates}
            onChangeServiceFilter={onChangeServiceFilter}
            onChangeOptions={onChangeOptions}
          />
        </Utility>
        <ContentPad>
          {dates.map(dt => (
            <ScheduleDay
              key={dt}
              forDate={dt}
              center={center}
              students={students}
              staff={staff}
              events={events}
              serviceFilterValue={serviceFilter}
              options={options}
              onSaveScheduleEvent={actions?.onSaveScheduleEvent}
              onDeleteScheduleEvent={actions?.onDeleteScheduleEvent}
            />
          ))}
        </ContentPad> */}
      </Leftside>
    </AppLayout>
  );
};

export const mapStateToProps = (state: Store.RootState) => ({
  centers: state?.centers?.centers ?? [],
  students: state?.students?.students ?? [],
  staff: state?.staff?.staff ?? [],
  events: state?.schedule?.events ?? []
});

export const mapDispatchToProps = (dispatch: Store.AppDispatch) => {
  const props: { actions: Actions; dispatch: Store.AppDispatch } = {
    actions: {
      onSaveScheduleEvent: (payload: ScheduleSlice.SaveScheduleEvent) =>
        dispatch(ScheduleSlice.saveScheduleEvent(payload)),
      onDeleteScheduleEvent: (payload: ScheduleSlice.DeleteScheduleEvent) =>
        dispatch(ScheduleSlice.deleteScheduleEvent(payload))
    },
    dispatch
  };
  return props;
};

export default connect(mapStateToProps, mapDispatchToProps)(Scheduling);
