import React from "react";

import * as Types from "../../data/types";
import * as Utils from "../../data/utils";

import "./schedule-cell.scss";

export interface ScheduleCellProps {
  schedule?: Types.EmployeeWeekSchedule | Types.WeekSchedule;
}

export const constructCellContentsForDay = (
  day: Types.DaysOfWeekType,
  schedule: Types.EmployeeWeekSchedule | Types.WeekSchedule | undefined
) => {
  const subset = schedule?.days
    ?.filter(d => d.day === day)
    ?.map(d => {
      const tag =
        "ratio" in d
          ? ` (${d.delivery ?? "Center"} ${(d as Types.EmployeeDaySchedule).ratio}-1)`
          : ` (${d.delivery ?? "Center"})`;
      return `${Utils.formatTime(d.start, false)}-${Utils.formatTime(d.end, false)}${tag}`;
    });
  if (!subset?.length) {
    return "none";
  } else {
    return subset.join("\n");
  }
};

export const ScheduleCell: React.FC<ScheduleCellProps> = ({ schedule }) => {
  return (
    <table className="schedule-cell">
      <thead>
        <tr>
          <th>Sun</th>
          <th>Mon</th>
          <th>Tue</th>
          <th>Wed</th>
          <th>Thu</th>
          <th>Fri</th>
          <th>Sat</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{constructCellContentsForDay("sunday", schedule)}</td>
          <td>{constructCellContentsForDay("monday", schedule)}</td>
          <td>{constructCellContentsForDay("tuesday", schedule)}</td>
          <td>{constructCellContentsForDay("wednesday", schedule)}</td>
          <td>{constructCellContentsForDay("thursday", schedule)}</td>
          <td>{constructCellContentsForDay("friday", schedule)}</td>
          <td>{constructCellContentsForDay("saturday", schedule)}</td>
        </tr>
      </tbody>
    </table>
  );
};
