import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as Types from "./types";
import * as Utils from "./utils";
import * as StudentsMocks from "./students";

export interface StudentsState {
  students: Types.Student[];
  parents: Types.StudentRelative[];
}

export interface SaveStudentTuitionRatePayload {
  studentId: Types.UniqueId;
  rate: Types.StudentTuitionRate;
}

export interface SaveStudentSchedulePayload {
  studentId: Types.UniqueId;
  schedule: Types.StudentSchedule;
}

const initialState: StudentsState = {
  students: [...StudentsMocks.students],
  parents: [...StudentsMocks.parents]
};

export const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    saveStudentTuitionRate: (state, action: PayloadAction<SaveStudentTuitionRatePayload>) => {
      if (!action.payload.rate) {
        return;
      }
      const student = state.students.find(s => s.id === action.payload.studentId);
      if (student) {
        if (!student.tuitionRates) {
          student.tuitionRates = [{ ...action.payload.rate }];
          return;
        }
        const rate = student.tuitionRates.find(r => r.id === action.payload.rate.id);
        if (rate) {
          // you cannot edit the id or type of an existing rate
          rate.description = action.payload.rate.description;
          rate.hours = action.payload.rate.hours;
          rate.rateId = action.payload.rate.rateId;
          rate.service = action.payload.rate.service;
          rate.defaultRate = action.payload.rate.defaultRate;
          rate.discountId = action.payload.rate.discountId;
          rate.discountPercentage = action.payload.rate.discountPercentage;
          rate.netTuitionRate = action.payload.rate.netTuitionRate;
          rate.recalculateRate = action.payload.rate.recalculateRate;
          rate.effectiveDate = action.payload.rate.effectiveDate;
        } else {
          student.tuitionRates.push(action.payload.rate);
        }
      }
    },
    saveStudentSchedule: (state, action: PayloadAction<SaveStudentSchedulePayload>) => {
      if (!action.payload.schedule) {
        return;
      }
      const student = state.students.find(s => s.id === action.payload.studentId);
      if (student) {
        if (!student.schedules) {
          student.schedules = [];
        }
        const schedule = student.schedules.find(r => r.id === action.payload.schedule.id);
        if (schedule) {
          const updated = { ...schedule, ...action.payload.schedule };
          updated.days.sort(Utils.dayScheduleSorter);
          student.schedules = [...student.schedules.filter(s => s.id !== updated.id), updated];
        } else {
          student.schedules.push(action.payload.schedule);
        }
      }
    }
  }
});

export const { saveStudentTuitionRate, saveStudentSchedule } = studentsSlice.actions;

export default studentsSlice.reducer;
