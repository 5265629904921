import * as Types from "../types";

import { getEffectiveSchedule } from "./get-effective-schedule";

export const compileResourcesForSchedule = (asOf: number, resources: Types.CenterResource[] | undefined) => {
  const results: Types.ResourceScheduleChartData[] = [];
  resources?.forEach(r => {
    // console.log(st);
    const availability = getEffectiveSchedule(asOf, r.schedules);
    const name = r.name;
    const capacity = r.capacity;

    if (availability && name) {
      results.push({
        id: r.id,
        name,
        availability,
        capacity
      });
    }
  });
  return results;
};
