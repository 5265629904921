import * as Types from "../types";

export const formatServiceSubject = (
  source: string | undefined,
  format: "subjectonly" | "shortservice" | "longservice"
) => {
  if (!source) {
    return "";
  }
  const [serviceAbbreviation, subject] = source.split("---");

  const longService = Types.serviceDescriptiveNames.find(sdn => sdn.service === serviceAbbreviation)?.name ?? "N/A";
  if (!source.includes("---")) {
    return longService;
  } else {
    if (format === "subjectonly") {
      return subject;
    } else if (format === "shortservice") {
      return `${serviceAbbreviation} - ${subject}`;
    } else {
      return `${longService} - ${subject}`;
    }
  }
};
