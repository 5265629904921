import React from "react";

import * as Types from "../../data/types";
import * as Utils from "../../data/utils";

import { ItemType } from "../../hooks/use-sortable-filterable-data";

import {
  GeneralList,
  GeneralListColumnSpecification,
  GeneralListDataMapHandler,
  GeneralListItemSelectHandler
} from "../general-list/general-list";

import "./center-hours.scss";

export interface CenterHoursProps {
  hours?: Types.CenterScheduleItem[];
  holidays?: Types.CenterHolidayItem[];
  selected?: string;
  onSelect?: (id: string) => void;
  onAction?: (id: string) => void;
}

export const CenterHours: React.FC<CenterHoursProps> = ({ hours, holidays, selected, onSelect, onAction }) => {
  const onSelectItem: GeneralListItemSelectHandler = item => {
    onSelect && onSelect(item.id);
  };
  return (
    <>
      <div className="center-hours-container">
        <h5>Hours of Operation</h5>
        <GeneralList
          data={hours}
          mapper={centerHoursMapper}
          specs={centerHoursSpecs}
          activeItem={selected}
          onSelectItem={onSelectItem}
          onActionButtonClick={onAction}
        />
      </div>
      <br />
      <div className="center-hours-container">
        <h5>Holidays</h5>
        <GeneralList
          data={holidays}
          mapper={centerHolidaysMapper}
          specs={centerHolidaysSpecs}
          activeItem={selected}
          onSelectItem={onSelectItem}
          onActionButtonClick={onAction}
        />
      </div>
    </>
  );
};

export const centerHoursMapper: GeneralListDataMapHandler = function (this, scheduleItem, index?, array?) {
  const c = scheduleItem as Types.CenterScheduleItem;
  return {
    id: c.id,
    day: Utils.capitalize(c.day),
    open: c.start > 0 ? Utils.formatTime(c.start) : "(closed)",
    close: c.start > 0 ? Utils.formatTime(c.end) : "",
    startAppointments:
      c.startAppointments !== undefined && c.startAppointments > 0 ? Utils.formatTime(c.startAppointments) : "",
    endAppointments: c.endAppointments !== undefined && c.endAppointments > 0 ? Utils.formatTime(c.endAppointments) : ""
  } as ItemType;
};

export const centerHoursSpecs: GeneralListColumnSpecification[] = [
  {
    title: "Day",
    name: "day",
    className: "primary",
    isStrongCell: true
  },
  { title: "Open", name: "open" },
  { title: "Close", name: "close" },
  { title: "Start Appts", name: "startAppointments" },
  { title: "End Appts", name: "endAppointments" }
];

export const centerHolidaysMapper: GeneralListDataMapHandler = function (this, holiday, index?, array?) {
  const h = holiday as Types.CenterHolidayItem;
  return {
    id: h.id,
    name: h.name,
    date: h.date
  } as ItemType;
};

export const centerHolidaysSpecs: GeneralListColumnSpecification[] = [
  {
    title: "Name",
    name: "name",
    className: "primary",
    isStrongCell: true
  },
  { title: "Date", name: "date" }
];
