import React, { useMemo, useState } from "react";
import { useInterval } from "react-use";
import moment from "moment";

import * as Types from "../../data/types";
import * as Utils from "../../data/utils";

export interface UpcomingAeStartProps {
  aeToday: Types.Student[];
}

const reformatTimeString = (original: string | undefined): { display: string; numeric: number } => {
  if (!original || !original.includes(":")) {
    return { display: "", numeric: 0 };
  } else {
    const parts = original.split(":").map(p => parseInt(p, 10));
    const numeric = parts.length === 1 ? parts[0] : parts[0] * 60 + parts[1];
    return { display: Utils.formatTime(numeric), numeric };
  }
};

export const UpcomingAeStart: React.FC<UpcomingAeStartProps> = ({ aeToday }) => {
  const [currentTime, setCurrentTime] = useState<moment.Moment>(moment(new Date()));

  useInterval(() => {
    setCurrentTime(moment(new Date()));
  }, 20 * 1000);

  const data = useMemo(() => {
    const sorted = [...aeToday];
    sorted.sort((a, b) => (a.aeTime ?? "").localeCompare(b.aeTime ?? ""));
    const timeFilter = moment(new Date()).format("hh:mm a");
    return sorted
      .filter(st => timeFilter.localeCompare(st.aeTime || "") <= 0)
      .slice(0, 4)
      .map(st => {
        return {
          id: st.id,
          name: Utils.formatAlphabeticName(st.name),
          aeTime: reformatTimeString(st.aeTime).display,
          alert: st.alert
        };
      });
  }, [aeToday]);

  return (
    <div className="card">
      <div className="card-header">
        <h2>Upcoming AE Start</h2>
        <p>
          <span className="timestamp">Current time: {currentTime.format("h:mm a")}</span>
        </p>
      </div>
      <div className="card-body">
        <table className="table table-hover table-collapse-md table-has-icons">
          <thead>
            <tr>
              <th scope="col" className="th-icon">
                Ready
              </th>
              <th scope="col">Student</th>
              <th scope="col">Start</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => {
              return (
                <tr key={`upcoming-ae-start-row-${item.id}`} data-testid={`upcoming-ae-start-row-${item.id}`}>
                  <td className="td-icon">
                    <span className="fal fa-check-circle active"></span>
                    <span className="sr-only">Ready</span>
                  </td>
                  <td className="td-primary">{item.name}</td>
                  <td>
                    <span className="th-label">Start:</span>
                    {item.aeTime}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
