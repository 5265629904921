import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import * as Types from "../../data/types";

import "./header.scss";

export type HeaderTab = "Dashboard" | "Centers" | "Staff" | "Students" | "Scheduling" | "Financials" | "Reporting";

export interface HeaderProps {
  activeTab?: HeaderTab;
  tabQualifier?: string;
  user?: Types.User;
  center?: Types.Center;
  logout?: () => void;
}

export interface HeaderLinkProps {
  to: string;
}

export const HeaderLink: React.FC<HeaderLinkProps> = ({ to, children }) => {
  return (
    <Link
      style={{
        marginBottom: "inherit",
        padding: "inherit",
        display: "inline",
        color: "inherit",
        backgroundColor: "transparent"
      }}
      to={to}
    >
      {children}
    </Link>
  );
};

export const Header: React.FC<HeaderProps> = ({ activeTab = "Dashboard", user, center, logout, tabQualifier }) => {
  // const [centersLabel, setCentersLabel] = useState<string>("");
  // const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false);

  // const onClickBurger = () => {
  //   setShowBurgerMenu(true);
  // };

  useEffect(() => {
    // todo: once we have a new way to determine the users number of centers, restore this
    //setCentersLabel(user && user.centers && user.centers.length > 1 ? "Centers" : "Center");
  }, []);

  return (
    <header className="app-header">
      <button className="nav-trigger collapsed" data-bs-toggle="collapse" data-bs-target="#col-sidebar-primary">
        <span className="fal fa-bars" aria-hidden="true"></span>
        <span className="sr-only">Menu</span>
      </button>
      <div className="hlc-brand">
        <a href="/dashboard">
          <img src="/img/logo-huntington.png" alt="Huntington Learning Center" />
        </a>
      </div>
      <div className="page-header">
        <h1>
          {activeTab}
          {!!tabQualifier ? ` // ` : ""}
          <strong>{!!tabQualifier ? `${tabQualifier}` : ""}</strong>
        </h1>
        <p className="secondary">{""}</p>
      </div>
      <div className="header-icon-block move-on-mobile">
        <button className="header-icon" onClick={logout}>
          <span className="fal fa-user-circle"></span>

          <span className="icon-text">
            <span className="icon-text-secondary">{center?.name}</span>
            <span className="icon-name">{user?.name}</span>
          </span>
        </button>
      </div>
    </header>
  );
};
