import React, { useEffect, useState } from "react";
import { useModal } from "react-hooks-use-modal";

import * as Types from "../../data/types";
import * as CentersSlice from "../../data/centers-slice";
import { ItemType } from "../../hooks/use-sortable-filterable-data";

import { EditCenterRate } from "./edit-center-rate";

import {
  GeneralList,
  GeneralListColumnSpecification,
  GeneralListDataMapHandler,
  GeneralListItemSelectHandler
} from "../general-list/general-list";

export interface CenterRatesProps {
  centerId: Types.UniqueId;
  rates: Types.CenterTuitionRate[];
  type: Types.CenterTuitionRateType;
  onSave?: (payload: CentersSlice.SaveCenterTuitionRatePayload) => void;
}

export const CenterRates: React.FC<CenterRatesProps> = ({ centerId, rates, type, onSave }) => {
  const [selected, setSelected] = useState<Types.CenterTuitionRate>();
  const [editing, setEditing] = useState<Types.CenterTuitionRate>();

  const onSelectItem: GeneralListItemSelectHandler = item => {
    setSelected(rates?.find(r => r.id === item.id));
  };

  const [EditCenterRateModal, openEditCenterRateModal, closeEditCenterRateModal] = useModal("utility-root", {
    preventScroll: true,
    closeOnOverlayClick: false
  });

  const onActionButtonClick = (id: string) => {
    setEditing(rates?.find(tr => tr.id === id));
  };

  useEffect(() => {
    if (editing) {
      openEditCenterRateModal();
    } else {
      closeEditCenterRateModal();
    }
  }, [editing, openEditCenterRateModal, closeEditCenterRateModal]);

  const onCancelEditing = () => {
    setEditing(undefined);
  };

  const onSaveEditing = (rate: Types.CenterTuitionRate) => {
    if (rate && rate.id && onSave) {
      onSave({ centerId, rate });
      setEditing(undefined);
    }
  };

  // const onSelectCenterTuitionRate = (id: string) => {
  //   if (id && selectedCenter?.tuitionRates?.length) {
  //     setSelectedCenterTuitionRate(selectedCenter.tuitionRates.find(tr => tr.id === id));
  //   } else {
  //     setSelectedCenterTuitionRate(undefined);
  //   }
  // };

  return (
    <>
      <div>
        <h5>{type === "tuition" ? "Tuition Rates" : "Discount Programs"}</h5>
        <GeneralList
          data={rates}
          mapper={centerRatesMapper}
          specs={type === "tuition" ? centerTuitionRatesSpecs : centerDiscountProgramsSpecs}
          activeItem={selected?.id}
          onSelectItem={onSelectItem}
          onActionButtonClick={onActionButtonClick}
          pageSize={6}
        />
      </div>

      {!!editing && (
        <EditCenterRateModal>
          <EditCenterRate rate={editing} onCancel={onCancelEditing} onSave={onSaveEditing} />
        </EditCenterRateModal>
      )}
    </>
  );
};

export const centerRatesMapper: GeneralListDataMapHandler = function (this, rate, index?, array?) {
  const r = rate as Types.CenterTuitionRate;
  return {
    id: r.id,
    rate: r.type === "tuition" ? r.rate.toFixed(2) : `${(r.rate * 100).toFixed(0)}%`,
    description: r.description,
    hours: r.hours ?? 0
  } as ItemType;
};

export const centerTuitionRatesSpecs: GeneralListColumnSpecification[] = [
  { title: "ID#", name: "id", sorterName: "id" },
  {
    title: "Service",
    name: "description",
    sorterName: "description",
    className: "primary",
    isStrongCell: true,
    defaultSort: true
  },
  { title: "Rate", name: "rate", sorterName: "rate" },
  { title: "Activate", name: "activate", isActionButton: true, actionButtonIconClasses: "far fa-pencil" }
];

export const centerDiscountProgramsSpecs: GeneralListColumnSpecification[] = [
  { title: "ID#", name: "id", sorterName: "id" },
  {
    title: "Description",
    name: "description",
    sorterName: "description",
    className: "primary",
    isStrongCell: true,
    defaultSort: true
  },
  { title: "Percentage", name: "rate", sorterName: "rate" },
  { title: "Hours", name: "hours", sorterName: "hours" },
  { title: "Activate", name: "activate", isActionButton: true, actionButtonIconClasses: "far fa-pencil" }
];
