/* eslint-disable no-loop-func */

import * as Types from "../types";
import { formatTime } from "./time";

export const randomInteger = (min: number, max: number) => Math.floor(Math.random() * (max - min) + min);

export const generateSimilarPhoneNumber = (original: string | undefined) => {
  if (!original) {
    return "";
  } else {
    return (
      original.slice(0, original.length - 4) +
      `${randomInteger(0, 9)}${randomInteger(0, 9)}${randomInteger(0, 9)}${randomInteger(0, 9)}`
    );
  }
};

export const constructAttendanceHistory = (student: Types.Student | undefined): Types.Attendance[] => {
  if (!student || student.status !== "ENR") {
    return [];
  }

  const enrollment = student.histories?.find(h => h.text === "enrolled");
  const schedule = student.schedules?.[0];
  if (!enrollment || !schedule || !schedule.days?.length) {
    return [];
  }

  const now = Date.now();
  let current = enrollment.date + 60000 * 60 * 24 * 7;
  const results: Types.Attendance[] = [];
  while (current < now) {
    schedule.days.forEach(d => {
      results.push({
        date: current,
        dow: d.day ?? "",
        time: `${formatTime(d.start)} - ${formatTime(d.end)}`,
        present: Math.random() > 0.1
      });
    });
    current = current + 60000 * 60 * 24 * 7;
  }

  return results;
};
