import React, { useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export interface ListHeaderProps {
  filter?: string;
  onFilterChange?: (value: string) => void;
  onPrintRequested?: () => void;
}

export const ListHeader: React.FC<ListHeaderProps> = ({ filter, onFilterChange, onPrintRequested }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useHotkeys(
    "ctrl+f, cmd+f",
    e => {
      e.preventDefault();
      inputRef.current?.focus();
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
  );

  return (
    <>
      <div className="form-floating has-icon">
        <input
          type="text"
          ref={inputRef}
          className="form-control"
          id="contentSearch"
          placeholder="Search"
          value={filter}
          onChange={e => onFilterChange?.(e.target.value)}
        />
        <label htmlFor="contentSearch">Search</label>
        <span className="fal fa-search"></span>
      </div>
      <div className="icon">
        <button className="btn" onClick={onPrintRequested}>
          <span className="fal icon fa-print"></span>
          <span className="sr-only">Print</span>
        </button>
      </div>
    </>
  );
};
