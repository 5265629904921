import { configureStore } from "@reduxjs/toolkit";

import centersReducer from "./centers-slice";
import studentsReducer from "./students-slice";
import staffReducer from "./staff-slice";
import scheduleReducer from "./schedule-slice";

export const store = configureStore({
  reducer: {
    centers: centersReducer,
    students: studentsReducer,
    staff: staffReducer,
    schedule: scheduleReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
