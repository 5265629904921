import moment from "moment";

import * as Types from "../types";

export const format24HourTime = (t: number | undefined) => {
  if (t === undefined) {
    return "";
  }
  return `${Math.floor(t / 60)
    .toString()
    .padStart(2, "0")}:${(t % 60).toString().padStart(2, "0")}`;
};

export const parse24HourTime = (t: string) => {
  if (!t || !t.includes(":")) {
    return 0;
  }
  const parts = t.split(":").map(tp => parseInt(tp, 10));

  return parts[0] * 60 + parts[1];
};

const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
export const getDayOfWeekMultiplier = (dow: string) => days.findIndex(d => d === dow);

// const convertTimeStringToNumber = (ts: string) => {
//   const parts = ts.split(":").map(p => parseInt(p, 10));
//   const numeric = parts.length === 1 ? parts[0] : parts[0] * 60 + parts[1];
//   return numeric;
// };

export const convertScheduleToTimestampPair = (s: Types.StudentSchedule, baseDate: number) => {
  return { start: "", end: "" };
  // if (!s) {
  //   return { start: undefined, end: undefined };
  // }

  // const base = baseDate + 24 * 60 * 60 * 1000 * getDayOfWeekMultiplier(s.dayOfWeek);
  // return {
  //   start: base + convertTimeStringToNumber(s.start) * 60000,
  //   end: base + convertTimeStringToNumber(s.end) * 60000
  // };
};

export const timeHelper = (numeric: number) => Math.round(numeric * 60);

export const formatDate = (date: number | undefined, emptyResponse = "") => {
  if (!date) {
    return emptyResponse;
  } else {
    return moment(date).format("MMM D, YYYY");
  }
};

export const formatTime = (t: number | undefined, useDesignator = true) => {
  if (t === undefined) {
    return "";
  }

  const designator = t >= 720 ? " pm" : " am";
  const effective = t > 720 ? t - 720 : t;

  return `${Math.floor(effective / 60)}:${(effective % 60).toString().padStart(2, "0")}${
    useDesignator ? designator : ""
  }`;
};

export const daysOfWeekSorter = (left: Types.DaysOfWeekType, right: Types.DaysOfWeekType) => {
  if (!left || !right) {
    return 0;
  }

  if (left === right) {
    return 0;
  }
  const leftIndex = Types.daysOfWeek.findIndex(x => x === left);
  const rightIndex = Types.daysOfWeek.findIndex(x => x === right);
  return leftIndex - rightIndex;
};

export const dayScheduleSorter = (left: Types.DaySchedule, right: Types.DaySchedule) => {
  const daySort = daysOfWeekSorter(left?.day ?? "saturday", right?.day ?? "sunday");
  if (daySort === 0) {
    const startSort = (left?.start ?? 0) - (right?.start ?? 0);
    if (startSort === 0) {
      return (left?.end ?? 0) - (right?.end ?? 0);
    } else {
      return startSort;
    }
  } else {
    return daySort;
  }
};
