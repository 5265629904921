import * as Types from "../types";
import * as Utils from "../utils";

import { formatDeliveryMethods } from "./formatters";
import { getEffectiveSchedule } from "./get-effective-schedule";

export const compileStaffForSchedule = (asOf: number, staff: Types.Employee[], serviceFilter = "") => {
  const results: Types.EmployeeScheduleChartData[] = [];
  staff?.forEach(st => {
    // console.log(st);
    const availability = getEffectiveSchedule(asOf, st.availability);
    const name = st.name;

    const subjects = Array.from(
      new Set(st.serviceSubjects?.map(s => Utils.formatServiceSubject(s, "subjectonly")))
    ).join(", ");
    const delivery = formatDeliveryMethods(st.supportedDeliveryMethods);

    if (serviceFilter) {
      if (!st.serviceSubjects?.includes(serviceFilter)) {
        return;
      }
    }

    if (availability && name && subjects && delivery) {
      results.push({
        id: st.id,
        name,
        availability,
        subjects,
        delivery
      });
    }
  });

  return results;
};
