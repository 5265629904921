import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as Types from "./types";

export interface ScheduleState {
  events: Types.ScheduleEvent[];
}

export interface SaveScheduleEvent {
  event: Types.ScheduleEvent;
}

export interface DeleteScheduleEvent {
  eventId: string;
}

const initialState: ScheduleState = {
  events: []
};

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    saveScheduleEvent: (state, action: PayloadAction<SaveScheduleEvent>) => {
      if (!action.payload.event) {
        return;
      }
      const event = state.events.find(e => e.id === action.payload.event.id);
      if (event) {
        const updated = { ...event, ...action.payload.event };
        state.events = [...state.events.filter(e => e.id !== updated.id), updated];
      } else {
        state.events.push(action.payload.event);
      }
    },

    deleteScheduleEvent: (state, action: PayloadAction<DeleteScheduleEvent>) => {
      console.log(action);
      if (!action.payload.eventId) {
        return;
      }
      state.events = [...state.events.filter(e => e.id !== action.payload.eventId)];
    }
  }
});

export const { saveScheduleEvent, deleteScheduleEvent } = scheduleSlice.actions;

export default scheduleSlice.reducer;
