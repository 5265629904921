import React from "react";

export type UniqueId = string;

export interface Name {
  first: string;
  middle: string;
  last: string;
}

export interface Address {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
}

export interface ContactInformation {
  phone: string;
  address?: Address;
  parent1?: string;
  parent2?: string;
  businessEmail?: string;
  personalEmail?: string;
  emergencyContactName?: string;
  emergencyContactPhone?: string;
}

export const deliveryValues = ["Center", "Virtual", "Off-site"];
export type DeliveryType = typeof deliveryValues[number];

export interface StudentSchedule extends WeekSchedule {
  serviceSubject?: string;
  delivery?: DeliveryType;
}

export interface Financial {
  date: number;
  type: "credit" | "debit";
  amount: number;
}

export interface History {
  date: number;
  text: string;
}

export interface Note {
  date: number;
  text: string;
}

export interface StudentTuitionRate {
  id: UniqueId;
  rateId: UniqueId;
  service: string;
  defaultRate?: number;
  discountId?: UniqueId;
  discountPercentage?: number;
  netTuitionRate?: number;
  description: string;
  recalculateRate?: number;
  // just going to store as string now for demo simplicity
  effectiveDate?: string;
  hours?: number;
}

export interface Student {
  id: UniqueId;
  name: Name;
  contactInformation?: ContactInformation;
  lastInteractionTime: number;
  currentBalance: number;
  lastPaymentDate: number | undefined;
  status?: StudentStatusType;
  startingToday?: boolean;
  alert?: boolean;
  alertDescription?: string;
  schedules?: StudentSchedule[];
  financials?: Financial[];
  histories?: History[];
  notes?: Note[];
  assignedTo?: string;
  ready?: boolean;
  service?: string;
  aeTime?: string;
  missingInfo?: boolean;
  reenrolling?: boolean;
  interimConference?: boolean;
  tuitionRates?: StudentTuitionRate[];
}

export type PositionType = "Tutor" | "Admin" | "Director";

export interface Employment {
  position: PositionType;
  start?: number;
  end?: number;
  eligibleForRehire?: boolean;
}

export type PermissionAssignment = "Allow" | "Deny";

export interface Permission {
  name: string;
  read?: PermissionAssignment;
  write?: PermissionAssignment;
}

export interface Role {
  name: string;
  permissions: Permission[];
}

export interface AccessControl {
  enabled: boolean;
  roles?: Role[];
  permissions?: Permission[];
  centers?: Center[];
}

export type EmployeeSupportedDeliveryMethods = "virtual" | "in-person" | "both";

export interface Employee {
  id: UniqueId;
  name: string;
  inactive?: boolean;
  contactInformation?: ContactInformation;
  subjects?: SubjectType[];
  availability?: EmployeeWeekSchedule[];
  employments?: Employment[];
  accessControl?: AccessControl;
  supportedDeliveryMethods?: EmployeeSupportedDeliveryMethods;
  serviceSubjects?: string[];
}

export interface AcademicEvaluation {
  id: UniqueId;
  ready?: boolean;
  studentId: UniqueId;
  startTime: number;
  service: string;
  assignedEmployeeId: UniqueId;
  flagged?: boolean;
}

export interface StartingToday {
  id: UniqueId;
  ready?: boolean;
  studentId: UniqueId;
  startTime: number;
  flagged?: boolean;
}

export interface OutstandingBalance {
  id: UniqueId;
  studentId: UniqueId;
  balance: number;
}

export interface Alert {
  id: UniqueId;
  message: string;
}

export const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
export type DaysOfWeekType = typeof daysOfWeek[number];

export const subjectTypes = [
  //"ACT Prep",
  "Algebra",
  "Biology",
  "Chemistry",
  "English",
  "French",
  "Math",
  //"NLCB Prep",
  "Physics",
  "Reading",
  //"SAT Prep",
  "Science",
  "Spanish",
  "US History",
  "World History",
  "Writing"
];
export type SubjectType = typeof subjectTypes[number];

export const todoItemTypes = [
  "ae-today",
  "starting-today",
  "balance-due",
  "alert-list",
  "ae-no-conf",
  "conf-not-enrolled",
  "missing-info",
  "deactivated-sched",
  "re-enroll",
  "interim-test",
  "interim-conf",
  "make-up",
  "school-visit",
  "initial-conference",
  "attending-today",
  "inquiry"
] as const;
export type TodoItemType = typeof todoItemTypes[number];

export const todoItemDescriptiveNames: Record<TodoItemType, string> = {
  "ae-today": "Academic Evaluations",
  "starting-today": "Starting Today",
  "balance-due": "Balance > $0",
  "alert-list": "Alerts",
  "ae-no-conf": "AE, No Conference",
  "conf-not-enrolled": "Conference, Not Enrolled",
  "missing-info": "Missing Information",
  "deactivated-sched": "Deactivated Schedules",
  "re-enroll": "Re-enrollment",
  "interim-test": "Interim Test",
  "interim-conf": "Interim Conferences",
  "make-up": "Make Up",
  "school-visit": "School Visit",
  "initial-conference": "Initial Conferences",
  "attending-today": "Attending Today",
  inquiry: "Inquiry"
};

export interface TodoItem {
  type: TodoItemType;
  count: number;
}

export interface CenterScheduleItem {
  id: UniqueId;
  day: DaysOfWeekType;
  start: number;
  end: number;
  startAppointments?: number;
  endAppointments?: number;
}

export interface CenterHolidayItem {
  id: UniqueId;
  name: string;
  date: string;
}

export interface CenterNote {
  id: UniqueId;
  date: number;
  title: string;
  content: string;
}

export interface CenterServiceRatio {
  id: UniqueId;
  service: ServiceType;
  toOne: number;
}

export type CenterTuitionRateType = "tuition" | "discount";

export interface CenterTuitionRate {
  id: UniqueId;
  type: CenterTuitionRateType;
  rate: number;
  description: string;
  hours?: number;
}

export interface DaySchedule {
  id: UniqueId;
  day?: DaysOfWeekType;
  start?: number;
  end?: number;
  delivery?: DeliveryType;
}

export interface EmployeeDaySchedule extends DaySchedule {
  ratio: number;
}

export interface WeekSchedule {
  id: UniqueId;
  effective?: number;
  expiration?: number;
  days: DaySchedule[];
  deactivated?: boolean;
}

export interface EmployeeWeekSchedule extends WeekSchedule {
  days: EmployeeDaySchedule[];
}

export interface CenterResource {
  id: UniqueId;
  name: string;
  capacity: number;
  schedules: WeekSchedule[];
}

export interface Center {
  id: UniqueId;
  name: string;
  state: string;
  zipcode: string;
  city: string;
  line1: string;
  line2: string;
  phone: string;
  fax: string;
  email: string;
  url: string;
  gps?: string;
  hoursOfOperation?: CenterScheduleItem[];
  holidays?: CenterHolidayItem[];
  notes?: CenterNote[];
  tuitionRates?: CenterTuitionRate[];
  resources?: CenterResource[];
  ratios?: CenterServiceRatio[];
}

export type UserRole = "admin" | "owner" | "faculty";

export interface User {
  id: UniqueId;
  name: string;
  sortableName: string;
  initials: string;
  username: string;
  centers: Center[];
  roles: UserRole[];
}

export const serviceTypes = ["LS", "L1", "E1", "A1", "S1", "ST", "G1", "GS"];
//export const serviceTypes = ["A1", "AC", "AP", "E1", "G1", "GS", "L1", "LC", "LS", "NS", "PC", "S1", "SC", "SP", "ST"];
export type ServiceType = typeof serviceTypes[number];

export const serviceDescriptiveNames: { service: ServiceType; name: string }[] = [
  { service: "A1", name: "ACT 1-1" },
  // { service: "AC", name: "ACT Course" },
  // { service: "AP", name: "ACT Practice & Review" },
  { service: "E1", name: "Exam Prep" },
  { service: "G1", name: "NCLB 1-1" },
  { service: "GS", name: "NCLB Standard" },
  { service: "L1", name: "Learning Center 1-1" },
  // { service: "LC", name: "Learning Center" },
  { service: "LS", name: "Learning Center Standard" },
  // { service: "NS", name: "Not Specified" },
  // { service: "PC", name: "PSAT Course" },
  { service: "S1", name: "SAT 1-1" },
  // { service: "SC", name: "SAT Course" },
  // { service: "SP", name: "SAT Practice & Review" },
  { service: "ST", name: "Subject Tutoring" }
];

export const serviceSubjectMap: Record<ServiceType, SubjectType[]> = {
  A1: ["English and Writing", "Math"],
  //AC: ["Reading", "Math", "Science", "Writing", "English"],
  //AP: ["Reading", "Math", "Science", "Writing", "English"],
  E1: [],
  G1: [],
  GS: ["Math (PK-6)", "Math (7-8)", "Math (HS+:9-14)", "Verbal"],
  L1: [],
  //LC: ["Algebra", "Biology", "Chemistry", "English", "French", "Physics", "Spanish", "US History", "World History"],
  LS: ["Math (PK-6)", "Math (7-8)", "Math (HS+:9-14)", "Verbal"],
  //NS: [...subjectTypes],
  //PC: ["Reading", "Math"],
  S1: ["Critical Reading and Writing", "Math"],
  //SC: ["Reading", "Math"],
  //SP: ["Reading", "Math"],
  ST: ["History", "Science", "English", "Foreign Languages", "Math"]
};

export type ServiceSubjectCombinedMapItem = {
  service: ServiceType;
  serviceDescription: string;
  subjects: { description: string; code: string; fullDescription: string }[];
};

export const serviceSubjectCombinedMap: ServiceSubjectCombinedMapItem[] = serviceTypes.map(st => {
  const result: ServiceSubjectCombinedMapItem = {
    service: st,
    serviceDescription: serviceDescriptiveNames.find(sdn => sdn.service === st)?.name ?? "",
    subjects: []
  };
  const mySubjects = serviceSubjectMap[st];
  if (mySubjects) {
    result.subjects = mySubjects.map(sub => {
      return {
        description: `${sub}`,
        code: `${st}---${sub}`,
        fullDescription: `${st} - ${sub}`
      };
    });
  }

  return result;
});

export const attendanceCodes: Record<string, { description: string; notes: string; charge: boolean; color: string }> = {
  ABN: { description: "Absent/No Charge", notes: "used only for GS/G1 students", charge: false, color: "#ff6961" },
  ABS: { description: "Absent", notes: "student not in attendance", charge: true, color: "#ffb480" },
  ANM: {
    description: "Absent/No Make Up",
    notes: "student not in attendance, charged for the session, not included in calculations for Make Up Hours Needd",
    charge: true,
    color: "#f8f38d"
  },
  ATD: {
    description: "Attended",
    notes: "student attended a regularly scheduled session",
    charge: true,
    color: "#42d6a4"
  },
  EXT: {
    description: "Extra",
    notes: "student attended a session outside of their regular schedule",
    charge: true,
    color: "#08cad1"
  },
  MU: {
    description: "Make Up",
    notes:
      "student attended a session outside of their regular schedule, it should be because of a corresponding ABS session in the past or future",
    charge: false,
    color: "#59adf6"
  },
  VAC: {
    description: "Vacation",
    notes: "student not in attendance for a regular session",
    charge: false,
    color: "#9d94ff"
  },
  WOH: {
    description: "Write Off Hour",
    notes: "student not in attendance for a MU session",
    charge: false,
    color: "#c780e8"
  }
};

const attendanceCodeValues = [];
for (const code in attendanceCodes) {
  attendanceCodeValues.push(code);
}
export { attendanceCodeValues };

export const studentStatusTypes = ["INQ", "DT", "CONF", "ENR", "DROP"];
export type StudentStatusType = typeof studentStatusTypes[number];

export const studentStatusDescriptiveNames: { status: StudentStatusType; name: string }[] = [
  { status: "INQ", name: "Inquiry" },
  { status: "DT", name: "Academic Evaluation" },
  { status: "CONF", name: "Conferenced" },
  { status: "ENR", name: "Enrolled" },
  { status: "DROP", name: "Dropped" }
];

export const studentRelationshipTypes = ["parent", "grandparent", "guardian", "aunt/uncle"];
export type StudentRelationshipType = typeof studentRelationshipTypes[number];

export interface StudentRelative {
  id: UniqueId;
  studentId: UniqueId;
  phone: string;
  name: string;
  relationship?: StudentRelationshipType;
}

export interface Attendance {
  date: number;
  dow: string;
  time: string;
  present: boolean;
}

export type ScheduleEventStatusType = "regular" | "make-up" | "canceled";

// used when the prototype (repeating) schedule of a student
// is realized into an actual specific day/time calendar event
export interface ScheduleEvent {
  id: UniqueId;
  studentId: UniqueId;
  name: string;
  studentScheduleId: UniqueId;
  studentScheduleDayId: UniqueId;
  serviceSubject: string;
  staffId?: UniqueId;
  resourceId?: UniqueId;
  start: number;
  end: number;
  status: string;
  comment: string;
  maxNumberOfStudents: number;
}

// used to create a fully populated denormalized group
// of cells for rendering on the page
export type ScheduleCellType =
  | "data"
  | "leader-tutor"
  | "leader-subject"
  | "leader-delivery"
  | "leader-time"
  | "leader-leader"
  | "vertical-gap";

// text will vary according to the type
// data - student name + other description text
// leader-tutor - name of the tutor
// leader-subject - subject(s) the tutor is trained in
// leader-delivery - in-person and/or zoom
// leader-time - the time for the blocks

export interface ScheduleCell {
  type: ScheduleCellType;
  event?: ScheduleEvent; // for an "empty" cell this will be undefined
  available?: boolean; // whether or not the employee in question is available
  content: React.ReactNode;
  datetime?: number;
  key: string; // unique key string for targetting
  staffId?: string;
  className?: string;
}

export type ScheduleColumn = ScheduleCell[];

export type ScheduleRow = ScheduleColumn[];

export interface EmployeeScheduleChartData {
  id: string;
  name: string;
  availability: WeekSchedule;
  subjects: string;
  delivery: string;
}

export interface StudentScheduleChartStudentData {
  id: UniqueId;
  name: string;
  dayScheduleId: UniqueId;
  prototypeScheduleId: UniqueId;
  scheduleEventId: UniqueId;
}

export interface StudentScheduleChartData {
  id: string;
  students: StudentScheduleChartStudentData[];
  serviceSubject: string;
  start: number;
  startMinutes: number;
  end: number;
  endMinutes: number;
  fill: string;
  fillColor: string;
  staffId?: string;
  resourceId?: string;
  scheduleEventId?: string;
  status: string;
  comment: string;
  maxNumberOfStudents: number;
}

export interface ResourceScheduleChartData {
  id: string;
  name: string;
  availability: WeekSchedule;
  capacity: number;
}

export type ScheduleEventEditType = "prototype" | "instance";

export type ScheduleEventEdit = (type: ScheduleEventEditType, data: StudentScheduleChartData) => void;
