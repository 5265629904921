import React from "react";

export interface CardProps {
  count?: number;
  label?: string;
  id: string;
}

export const Card: React.FC<CardProps> = ({ count = 0, label = "Count", id = "id" }) => {
  return (
    <div className="col col-md-6 col-xl-3" data-testid={`count-card-testid-${id}`}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" className="card">
        <div className="number">{count}</div>
        <div className="label">{label}</div>
      </a>
    </div>
  );
};

export interface CountCardsProps {
  data?: CardProps[];
}

export const CountCards: React.FC<CountCardsProps> = ({ data }) => {
  return (
    <div className="grid grid-number-cards">
      <div className="row">
        {data?.map(d => (
          <Card {...d} key={`count-card-key-${d.id}`} />
        ))}
      </div>
    </div>
  );
};
