import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import { OverlayProvider } from "@react-aria/overlays";

import { App } from "./app/app";

ReactDOM.render(
  <React.StrictMode>
    <OverlayProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </OverlayProvider>
  </React.StrictMode>,
  document.getElementById("lcos-root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug);
