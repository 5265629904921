import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import * as Types from "../../data/types";

import { ItemType } from "../../hooks/use-sortable-filterable-data";
import {
  GeneralList,
  GeneralListColumnSpecification,
  GeneralListDataMapHandler,
  GeneralListItemSelectHandler
} from "../general-list/general-list";

import "./staff-selector.scss";

export interface StaffSelectorProps {
  employees?: Types.Employee[];
  selected?: string;
  onSelect?: (id: string) => void;
  onAction?: (id: string) => void;
  filter: string;
}

export const StaffSelector = React.forwardRef<HTMLTableElement, StaffSelectorProps>(
  ({ employees, selected, onSelect, onAction, filter }, tableRef) => {
    const location = useLocation();
    const useInactive = useMemo(() => location.pathname.endsWith("inactive"), [location.pathname]);
    const data = useMemo(() => employees?.filter(e => useInactive === !!e.inactive) || [], [employees, useInactive]);

    useEffect(() => {
      if (data && selected && onSelect) {
        onSelect(data.find(d => d.id === selected)?.id ?? "");
      } else if (data && data.length && onSelect) {
        onSelect(data[0].id);
      }
    }, [data, selected, onSelect]);

    const onSelectItem: GeneralListItemSelectHandler = item => {
      onSelect && onSelect(item.id);
    };
    return (
      <div>
        <GeneralList
          filter={filter}
          ref={tableRef}
          data={data}
          mapper={staffListMapper}
          specs={staffListSpecs}
          activeItem={selected}
          onSelectItem={onSelectItem}
          onActionButtonClick={onAction}
        />
      </div>
    );
  }
);

StaffSelector.displayName = "StaffSelector";

export const staffListMapper: GeneralListDataMapHandler = function (this, employee, index?, array?) {
  const e = employee as Types.Employee;
  const position = e.employments?.length
    ? e.employments.reduce<Types.Employment | undefined>(
        (a: Types.Employment | undefined, b: Types.Employment | undefined) => {
          const left = a?.start || 0;
          const right = b?.start || 0;

          if (left < right) {
            return b;
          } else {
            return a;
          }
        },
        undefined
      )
    : undefined;
  return {
    id: e.id,
    name: e.name,
    position: position?.position || ""
  } as ItemType;
};

export const staffListSpecs: GeneralListColumnSpecification[] = [
  //{ title: "ID#", name: "id", sorterName: "id" },
  {
    title: "Name",
    name: "name",
    sorterName: "name",
    className: "primary",
    isStrongCell: true,
    defaultSort: true
  },
  { title: "Position", name: "position", sorterName: "position" }
];
