import React, { useMemo } from "react";
import { useAuthentication } from "../../../app/authentication-context";

import * as Types from "../../../data/types";
import "./access.scss";

export interface AccessProps {
  accessControl?: Types.AccessControl;
}

export const Access: React.FC<AccessProps> = ({ accessControl }) => {
  const { activeCenter } = useAuthentication();

  // const effectivePermissions = useMemo(() => {
  //   return Types.effectivePermissions(accessControl);
  // }, [accessControl]);

  const centers: Array<Types.Center | undefined> = useMemo(() => {
    if (!accessControl?.centers || accessControl?.centers?.some(c => c.id === activeCenter?.id)) {
      return [activeCenter];
    } else {
      return [...accessControl.centers, activeCenter];
    }
  }, [accessControl?.centers, activeCenter]);

  return (
    <div className="staff-detail-access-container">
      <div className="row">
        <div className="col">Access Enabled</div>
        <div className="col">
          <input
            type="checkbox"
            checked={accessControl?.enabled === true}
            onChange={() => {
              // no op to stop console complaints
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">Roles</div>
        <div className="col">
          <table>
            <thead>
              <tr>
                <th className="leftmost-cell">Position</th>
              </tr>
            </thead>
            <tbody>
              {accessControl?.roles?.map((r, index) => {
                return (
                  <tr key={`staff-details-tab-access-roles-key-${index}`}>
                    <td className="leftmost-cell">{r.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row">
        <div className="col">Permissions</div>
        <div className="col">
          <table>
            <thead>
              <tr>
                <th className="leftmost-cell">Permission</th>
                <th>Read</th>
                <th>Write</th>
              </tr>
            </thead>
            <tbody>
              {accessControl?.permissions?.length === 0 && (
                <tr>
                  <td colSpan={3}>(none)</td>
                </tr>
              )}
              {accessControl?.permissions?.map((p, index) => {
                return (
                  <tr key={`staff-details-tab-access-granted-permissions-key-${index}`}>
                    <td className="leftmost-cell">{p.name}</td>
                    <td>{p.read}</td>
                    <td>{p.write ?? "Deny"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* <div>
        <div>Effective Permissions</div>
        <div className="right">
          <table>
            <thead>
              <tr>
                <th className="leftmost-cell">Permission</th>
                <th>Read</th>
                <th>Write</th>
              </tr>
            </thead>
            <tbody>
              {effectivePermissions?.length === 0 && (
                <tr>
                  <td colSpan={3}>(none)</td>
                </tr>
              )}
              {effectivePermissions?.map((p, index) => {
                return (
                  <tr key={`staff-details-tab-access-effective-permissions-key-${index}`}>
                    <td className="leftmost-cell">{p.name}</td>
                    <td>{p.read}</td>
                    <td>{p.write ?? "Deny"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div> */}

      <div className="row">
        <div className="col">Centers</div>
        <div className="col">
          <table>
            <thead>
              <tr>
                <th>Center Name</th>
              </tr>
            </thead>
            <tbody>
              {centers?.map((c, index) => {
                if (!c) {
                  return null;
                }
                return (
                  <tr key={`staff-details-tab-centers-key-${index}`}>
                    <td className="leftmost-cell">{c.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
