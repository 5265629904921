import moment from "moment";
import React from "react";

import * as Types from "../../data/types";
import * as Utils from "../../data/utils";

import { StudentDetailTabs } from "./student-detail-tabs";
import { NavLink } from "react-router-dom";
import { Actions } from "../../screens/students/students";

export interface StudentDetailProps {
  student?: Types.Student;
  employees?: Types.Employee[];
  parents?: Types.StudentRelative[];
  onClose?: () => void;
  mode?: "summary" | "extended";
  actions?: Actions;
}

export const StudentDetail: React.FC<StudentDetailProps> = ({
  student,
  onClose,
  parents,
  mode = "summary",
  actions
}) => {
  if (!student) {
    return null;
  }

  return (
    <>
      <h2>
        {mode === "summary" && (
          <div className="row">
            <div className="col" style={{ verticalAlign: "middle", paddingTop: "5px" }}>
              <NavLink to={`/students?id=${student?.id}`} style={{ textDecoration: "underline" }}>
                {Utils.formatAlphabeticName(student.name)}
              </NavLink>
            </div>
            <div className="col" style={{ verticalAlign: "middle", textAlign: "right" }}>
              <button onClick={onClose} className="unstyled">
                <span className="fal fa-times close" />
              </button>
            </div>
          </div>
        )}
        {mode === "extended" && <>{Utils.formatAlphabeticName(student.name)}</>}
      </h2>
      <p>
        Last interaction:
        <span style={{ marginLeft: "15px" }}>
          {student?.lastInteractionTime ? moment(student?.lastInteractionTime).format("MMM D, YYYY, h:mm a") : ""}
        </span>
      </p>
      <StudentDetailTabs student={student} mode={mode} parents={parents} actions={actions} />
    </>
  );
};
