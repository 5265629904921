import React from "react";
import classnames from "classnames";

import { useLocation, useNavigate } from "react-router-dom";
import { appMenuItems } from "./app-menu-data";

export interface AppMenuSecondaryItemProps {
  label: string;
  count: number;
  route: string;
}

export interface AppMenuSecondaryItemGroupProps {
  id: string;
  groupLabel: string;
  items: AppMenuSecondaryItemProps[];
}

export interface AppMenuPrimaryItemProps {
  id: string;
  label: string;
  route: string;
  iconClassName: string;
  secondaryItemGroups: AppMenuSecondaryItemGroupProps[];
}

export const AppMenuSecondaryItemGroup: React.FC<AppMenuSecondaryItemGroupProps> = () => {
  return <div></div>;
};

export const AppMenuPrimaryItem: React.FC<AppMenuPrimaryItemProps> = ({
  id,
  label,
  route,
  iconClassName,
  secondaryItemGroups
}) => {
  const history = useNavigate();
  const location = useLocation();
  const hasSecondaries = !!secondaryItemGroups.length;
  const collapsiblePanelId = `${id}-collapsible-panel`;
  const expanded = location.pathname.startsWith(route);

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={id}>
        <button
          className={classnames("accordion-button", { collapsed: !expanded, "accordion-nochild": !hasSecondaries })}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={hasSecondaries ? `#${collapsiblePanelId}` : undefined}
          aria-controls={hasSecondaries ? collapsiblePanelId : undefined}
          onClick={!hasSecondaries ? () => history(route) : undefined}
        >
          <span className={iconClassName} aria-hidden="true"></span>
          {label}
        </button>
      </h2>
      {hasSecondaries && (
        <div
          id={hasSecondaries ? collapsiblePanelId : undefined}
          className={classnames("accordion-collapse", { collapse: !expanded })}
          aria-labelledby={id}
          data-bs-parent="#nav-primary"
        >
          <div className="accordion-body">
            {secondaryItemGroups.map(sig => {
              return (
                <React.Fragment key={sig.id}>
                  {!!sig.groupLabel && <h3 id={sig.id}>{sig.groupLabel}</h3>}
                  <ul className="list-unstyled" aria-labelledby={sig.id}>
                    {sig.items.map(item => {
                      return (
                        <li key={item.route}>
                          <button onClick={() => history(`${route}${item.route}`)}>
                            {item.label}
                            <span className="badge">{item.count}</span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export const AppMenu: React.FC = () => {
  return (
    <>
      <aside className="col col-sidebar-primary collapse" id="col-sidebar-primary">
        <div className="content-pad">
          <nav className="nav-primary" aria-label="Main Navigation">
            <div className="accordion" id="nav-primary">
              {appMenuItems.map(pi => (
                <AppMenuPrimaryItem key={pi.id} {...pi} />
              ))}
            </div>
          </nav>
        </div>
      </aside>
      <div className="dim-primary-sidebar"></div>
    </>
  );
};
