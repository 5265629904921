/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { useHotkeys } from "react-hotkeys-hook";

import * as Types from "../../../data/types";
import * as Utils from "../../../data/utils";

import "./edit-student-scheduling.scss";
import { DateField } from "../../fields/date-field";
import { TimeField } from "../../fields/time-field";

export interface EditStudentSchedulingProps {
  schedule?: Types.StudentSchedule;
  onSave?: (schedule: Types.StudentSchedule) => void;
  onCancel?: () => void;
}

export const EditStudentScheduling: React.FC<EditStudentSchedulingProps> = ({ schedule, onSave, onCancel }) => {
  const [localSchedule, setLocalSchedule] = useState<Types.StudentSchedule>(
    schedule ? { ...schedule } : { id: uuidv4(), days: [{ id: uuidv4(), day: undefined }], serviceSubject: undefined }
  );
  const [service, setService] = useState<string>(schedule ? schedule.serviceSubject?.split("---")?.[0] ?? "" : "");
  //const [subjectHeight, setSubjectHeight] = useState<number>(20);
  const [subjectOptions, setSubjectOptions] = useState<{ value: string; display: string }[]>([]);
  useEffect(() => {
    const newOptions: { value: string; display: string }[] = [];
    if (service) {
      if (Types.serviceSubjectMap[service]?.length) {
        // won't use blank option for multiple select
        // if (Types.serviceSubjectMap[localSchedule.service].length > 1) {
        //   newOptions.push({ value: "", display: "" });
        // }
        newOptions.push({ value: "", display: "" });
        Types.serviceSubjectMap[service].forEach(subj => {
          newOptions.push({ value: `${service}---${subj}`, display: subj });
        });
      } else {
        newOptions.push({
          value: `${service}`,
          display: Utils.formatServiceSubject(service, "longservice")
        });
      }
    }
    setSubjectOptions(newOptions);
    // if (newOptions.length) {
    //   setSubjectHeight(Math.min(newOptions.length * 30, 200));
    // } else {
    //   setSubjectHeight(30);
    // }
  }, [service]);

  const localSave = useCallback(() => {
    if (onSave) {
      // sort the days before saving
      const daysCopy = [...localSchedule.days];
      daysCopy.sort(Utils.dayScheduleSorter);
      onSave({
        ...localSchedule,
        days: [...daysCopy]
      });
    }
    if (onCancel) {
      onCancel();
    }
  }, [onSave, onCancel, localSchedule]);

  const onChangeExpiration = useCallback((value: number | undefined) => {
    setLocalSchedule(prev => {
      return { ...prev, expiration: value };
    });
  }, []);

  const onChangeEffective = useCallback((value: number | undefined) => {
    setLocalSchedule(prev => {
      return { ...prev, effective: value };
    });
  }, []);

  const onDeleteDayRow = (id: string) => {
    if (id) {
      setLocalSchedule(prev => {
        return { ...prev, days: prev.days.filter(d => d.id !== id) };
      });
    }
  };

  const onChangeRowDay = (dayId: string, value: string) => {
    if (dayId) {
      setLocalSchedule(prev => {
        const currentDay = prev.days.find(p => p.id === dayId);
        const rest = prev.days.filter(p => p.id !== dayId);
        if (currentDay) {
          return { ...prev, days: [...rest, { ...currentDay, day: value }] };
        } else {
          return prev;
        }
      });
    }
  };

  const onChangeRowStart = useCallback(
    (dayId: string) => (value: number | undefined) => {
      setLocalSchedule(prev => {
        const currentDay = prev.days.find(p => p.id === dayId);
        const rest = prev.days.filter(p => p.id !== dayId);
        if (currentDay) {
          return { ...prev, days: [...rest, { ...currentDay, start: value }] };
        } else {
          return prev;
        }
      });
    },
    []
  );

  const onChangeRowEnd = useCallback(
    (dayId: string) => (value: number | undefined) => {
      setLocalSchedule(prev => {
        const currentDay = prev.days.find(p => p.id === dayId);
        const rest = prev.days.filter(p => p.id !== dayId);
        if (currentDay) {
          return { ...prev, days: [...rest, { ...currentDay, end: value }] };
        } else {
          return prev;
        }
      });
    },
    []
  );

  const onChangeService = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setService(e.target.value);
  };

  const onChangeSubject = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLocalSchedule(prev => {
      return { ...prev, serviceSubject: e.target.value };
    });
  };

  const onChangeDelivery = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLocalSchedule(prev => {
      return { ...prev, delivery: e.target.value };
    });
  };

  const sortedDays = () => {
    // disabling immediate sorting
    return localSchedule.days;
    // const copy = [...localSchedule.days];
    // copy.sort(Utils.dayScheduleSorter);
    // return copy;
  };

  const onAddDay = () => {
    setLocalSchedule(prev => {
      return { ...prev, days: [...prev.days, { id: uuidv4() }] };
    });
  };

  useHotkeys("escape", () => onCancel && onCancel(), { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] });
  useHotkeys("ctrl+s, cmd+s", () => localSave(), { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] });
  useHotkeys("ctrl+d, cmd+d", () => onAddDay(), { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] });

  const serviceRef = useRef<HTMLSelectElement>(null);
  const subjectRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (localSchedule?.days) {
      const blank = localSchedule.days.find(d => !d.day);
      if (blank) {
        document.getElementById(`staff-availability-day-select-${blank.id}`)?.focus();
      }
    }
  }, [localSchedule?.days?.length]);

  useEffect(() => {
    serviceRef.current?.focus();
  }, []);

  const onDayRowKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Tab" && !e.shiftKey) {
      onAddDay();
    }
  };

  return (
    <div className={classnames("edit-student-scheduling-outer-container")}>
      <h2>Student Scheduling</h2>
      <div className="edit-student-scheduling-container">
        <div className="row">
          <div className="col form-floating">
            <select value={service} onChange={onChangeService} ref={serviceRef} className="form-control">
              <option value="">Select a Service</option>
              {Types.serviceTypes.map(st => {
                return (
                  <option key={st} value={st}>
                    {Types.serviceDescriptiveNames.find(n => n.service === st)?.name ?? st}
                  </option>
                );
              })}
            </select>
            <label>Service</label>
          </div>
        </div>
        <div className="row">
          <div className="col form-floating">
            <select
              value={localSchedule?.serviceSubject ?? ""}
              onChange={onChangeSubject}
              ref={subjectRef}
              className="form-control"
            >
              {subjectOptions.map(so => {
                return (
                  <option key={so.value} value={so.value}>
                    {so.display}
                  </option>
                );
              })}
            </select>
            <label>Subject</label>
          </div>
        </div>

        <div className="row">
          <div className="col form-floating">
            <select value={localSchedule?.delivery ?? ""} onChange={onChangeDelivery} className="form-control">
              {Types.deliveryValues.map(dv => {
                return (
                  <option key={dv} value={dv}>
                    {dv}
                  </option>
                );
              })}
            </select>
            <label>Location</label>
          </div>
        </div>

        <div className="row">
          <div className="col form-floating">
            <DateField value={localSchedule?.effective} mode={"edit"} onChange={onChangeEffective} />
            <label>Start Date</label>
          </div>
        </div>
        <div className="row">
          <div className="col form-floating">
            <DateField value={localSchedule?.expiration} mode={"edit"} onChange={onChangeExpiration} />
            <label>End Date</label>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <table className="table edit-student-scheduling-days-table">
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Start</th>
                  <th>End</th>
                </tr>
              </thead>

              <tbody>
                {sortedDays().map((sd, index, list) => {
                  const isLastRow = index >= list.length - 1;
                  return (
                    <tr key={`staff-availability-day-${sd.id}`}>
                      <td>
                        <select
                          value={sd.day}
                          className="form-control"
                          onChange={e => onChangeRowDay(sd.id, e.target.value)}
                          id={`staff-availability-day-select-${sd.id}`}
                        >
                          <option value="">Select a Day</option>
                          {Types.daysOfWeek.map(d => {
                            return (
                              <option value={d} key={d}>
                                {Utils.capitalize(d)}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <TimeField value={sd.start} onChange={onChangeRowStart(sd.id)} />
                      </td>
                      <td>
                        <TimeField value={sd.end} onChange={onChangeRowEnd(sd.id)} />
                      </td>
                      <td>
                        <button
                          className="unstyled"
                          onClick={() => onDeleteDayRow(sd.id)}
                          onKeyDown={isLastRow ? onDayRowKeyDown : undefined}
                        >
                          <i className="fal icon fa-trash" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className={classnames("edit-student-scheduling-button-row")}>
        <button className="btn btn-primary" onClick={onAddDay}>
          Add Day
        </button>
        <button className="btn btn-secondary" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={localSave}>
          Save
        </button>
      </div>
    </div>
  );
};
