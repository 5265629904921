import React, { useCallback, useState, useEffect } from "react";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import * as Types from "../../../data/types";
import * as Utils from "../../../data/utils";

import "./edit-staff-availability.scss";

export interface EditStaffAvailabilityProps {
  schedule?: Types.EmployeeWeekSchedule;
  onSave?: (schedule: Types.EmployeeWeekSchedule) => void;
  onCancel?: () => void;
}

export interface DateFieldProps {
  value?: number;
  mode?: "edit" | "view";
  onChange?: (newvalue: number | undefined) => void;
}

export const convertNumericDateToStringDate = (value: number | undefined) => {
  if (!value) {
    return "";
  } else {
    return moment(value).format("YYYY-MM-DD");
  }
};

export const convertStringDateToNumericDate = (value: string | undefined) => {
  if (!value) {
    return undefined;
  } else {
    return moment(value, "YYYY-MM-DD").valueOf();
  }
};

export const DateField: React.FC<DateFieldProps> = ({ value, mode, onChange }) => {
  const [localValue, setLocalValue] = useState<number | undefined>(value);
  const localOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    setLocalValue(convertStringDateToNumericDate(e.target.value));
  };

  useEffect(() => {
    onChange && onChange(localValue);
  }, [localValue, onChange]);

  if (mode === "view") {
    return <>{convertNumericDateToStringDate(localValue)}</>;
  } else {
    return (
      <input
        type="date"
        className="form-control"
        value={convertNumericDateToStringDate(localValue)}
        style={{ width: "100%" }}
        onChange={localOnChange}
      />
    );
  }
};

export interface TimeFieldProps {
  value?: number;
  mode?: "edit" | "view";
  onChange?: (newvalue: number | undefined) => void;
}

export const TimeField: React.FC<TimeFieldProps> = ({ value, mode, onChange }) => {
  const [localValue, setLocalValue] = useState<number | undefined>(value);
  const localOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(Utils.parse24HourTime(e.target.value));
  };

  useEffect(() => {
    onChange && onChange(localValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue]);

  if (mode === "view") {
    return <>{Utils.formatTime(localValue)}</>;
  } else {
    return (
      <input
        type="time"
        className="form-control"
        value={Utils.format24HourTime(localValue)}
        style={{ width: "100%" }}
        onChange={localOnChange}
      />
    );
  }
};

export const EditStaffAvailability: React.FC<EditStaffAvailabilityProps> = ({ schedule, onSave, onCancel }) => {
  const [localSchedule, setLocalSchedule] = useState<Types.EmployeeWeekSchedule>(
    schedule ? { ...schedule } : { id: uuidv4(), days: [{ id: uuidv4(), ratio: 1 }] }
  );
  const localSave = useCallback(() => {
    if (onSave) {
      // sort the days before saving
      const daysCopy = [...localSchedule.days];
      daysCopy.sort(Utils.dayScheduleSorter);
      onSave({ ...localSchedule, days: [...daysCopy] });
    }
    if (onCancel) {
      onCancel();
    }
  }, [onSave, onCancel, localSchedule]);

  const onChangeExpiration = useCallback((value: number | undefined) => {
    setLocalSchedule(prev => {
      return { ...prev, expiration: value };
    });
  }, []);

  const onChangeEffective = useCallback((value: number | undefined) => {
    setLocalSchedule(prev => {
      return { ...prev, effective: value };
    });
  }, []);

  const onDeleteDayRow = (id: string) => {
    if (id) {
      setLocalSchedule(prev => {
        return { ...prev, days: prev.days.filter(d => d.id !== id) };
      });
    }
  };

  const onChangeRowDay = (dayId: string, value: string) => {
    if (dayId) {
      setLocalSchedule(prev => {
        return {
          ...prev,
          days: [
            ...prev.days.map(d => {
              if (d.id === dayId) {
                return { ...d, day: value };
              } else {
                return d;
              }
            })
          ]
        };
      });
    }
  };

  const onChangeRowRatio = (dayId: string, value: string) => {
    if (dayId) {
      setLocalSchedule(prev => {
        return {
          ...prev,
          days: [
            ...prev.days.map(d => {
              if (d.id === dayId) {
                return { ...d, ratio: parseInt(value, 10) };
              } else {
                return d;
              }
            })
          ]
        };
      });
    }
  };

  const onChangeRowStart = useCallback(
    (dayId: string) => (value: number | undefined) => {
      setLocalSchedule(prev => {
        return {
          ...prev,
          days: [
            ...prev.days.map(d => {
              if (d.id === dayId) {
                return { ...d, start: value };
              } else {
                return d;
              }
            })
          ]
        };
      });
    },
    []
  );

  const onChangeRowEnd = useCallback(
    (dayId: string) => (value: number | undefined) => {
      setLocalSchedule(prev => {
        return {
          ...prev,
          days: [
            ...prev.days.map(d => {
              if (d.id === dayId) {
                return { ...d, end: value };
              } else {
                return d;
              }
            })
          ]
        };
      });
    },
    []
  );

  const onChangeRowLocation = (dayId: string, value: Types.DeliveryType) => {
    if (dayId) {
      setLocalSchedule(prev => {
        return {
          ...prev,
          days: [
            ...prev.days.map(d => {
              if (d.id === dayId) {
                return { ...d, delivery: value };
              } else {
                return d;
              }
            })
          ]
        };
      });
    }
  };

  const sortedDays = () => {
    // disabling immediate sorting
    return localSchedule.days;
    // const copy = [...localSchedule.days];
    // copy.sort(Utils.dayScheduleSorter);
    // return copy;
  };

  const onAddDay = () => {
    setLocalSchedule(prev => {
      return { ...prev, days: [...prev.days, { id: uuidv4(), ratio: 1 }] };
    });
  };

  return (
    <div className={classnames("edit-staff-availability-outer-container")}>
      <h2>Staff Availability</h2>
      <div className="edit-staff-availability-container">
        <div className="row">
          <div className="col form-floating">
            <DateField value={localSchedule?.effective} mode={"edit"} onChange={onChangeEffective} />
            <label>Start Date</label>
          </div>
        </div>
        <div className="row">
          <div className="col form-floating">
            <DateField value={localSchedule?.expiration} mode={"edit"} onChange={onChangeExpiration} />
            <label>End Date</label>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <table className="edit-staff-availability-days-table table">
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Location</th>
                  <th>Ratio</th>
                </tr>
              </thead>

              <tbody>
                {sortedDays().map(sd => {
                  return (
                    <tr key={`staff-availability-day-${sd.id}`}>
                      <td>
                        <select
                          className="form-control"
                          value={sd.day}
                          onChange={e => onChangeRowDay(sd.id, e.target.value)}
                        >
                          <option value="">Select a Day</option>
                          {Types.daysOfWeek.map(d => {
                            return (
                              <option value={d} key={d}>
                                {Utils.capitalize(d)}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <TimeField value={sd.start} onChange={onChangeRowStart(sd.id)} />
                      </td>
                      <td>
                        <TimeField value={sd.end} onChange={onChangeRowEnd(sd.id)} />
                      </td>
                      <td>
                        <select
                          className="form-control"
                          value={sd.delivery ?? ""}
                          onChange={e => onChangeRowLocation(sd.id, e.target.value)}
                        >
                          {Types.deliveryValues.map(dv => {
                            return (
                              <option key={dv} value={dv}>
                                {dv}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-control"
                          value={sd.ratio || "1"}
                          onChange={e => onChangeRowRatio(sd.id, e.target.value)}
                        >
                          <option value="1">1:1</option>
                          <option value="4">4:1</option>
                        </select>
                      </td>
                      <td>
                        <button className="unstyled" onClick={() => onDeleteDayRow(sd.id)}>
                          <i className="fal icon fa-trash" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className={classnames("edit-staff-availability-button-row")}>
        <button className="btn btn-primary" onClick={onAddDay}>
          Add Day
        </button>
        <button className="btn btn-secondary" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={localSave}>
          Save
        </button>
      </div>
    </div>
  );
};
