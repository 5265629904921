import React from "react";

import * as Types from "../../../data/types";

import { states } from "../../../data/states";

export interface ContactProps {
  contactInformation?: Types.ContactInformation;
  mode?: "summary" | "extended";
}

export const Contact: React.FC<ContactProps> = ({ contactInformation, mode = "summary" }) => {
  return (
    <div className="form" data-testid="student-details-tab-content-contact">
      <div className="row">
        <div className="col form-floating">
          <input type="text" className="form-control" value={contactInformation?.phone || ""} readOnly />
          <label>Phone</label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col form-floating">
          <input type="text" className="form-control" value={contactInformation?.address?.line1 || ""} readOnly />
          <label>Address</label>
        </div>
        <div className="col form-floating">
          <input type="text" className="form-control" value={contactInformation?.address?.line2 || ""} readOnly />
          <label>Suite/Apartment</label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col form-floating">
          <input type="text" className="form-control" value={contactInformation?.address?.city || ""} readOnly />
          <label>City</label>
        </div>
        <div className="col form-floating">
          <select
            className="form-control"
            value={contactInformation?.address?.state || ""}
            disabled
            onChange={() => {
              // just to stop linter complaints for now
              // istanbul ignore next
              return;
            }}
          >
            <option value="">Select a State</option>
            {states.map(s => (
              <option
                key={`student-detail-tab-content-contact-state-list-key-${s.abbreviation}`}
                value={s.abbreviation}
              >
                {s.name}
              </option>
            ))}
          </select>
          <label>State</label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col form-floating">
          <input type="text" className="form-control" value={contactInformation?.address?.zip || ""} readOnly />
          <label>Zip</label>
        </div>
      </div>
      <br />
      {mode === "summary" && (
        <div className="row">
          <div className="col form-floating">
            <input type="text" className="form-control" value={contactInformation?.parent1 || ""} readOnly />
            <label>Parent 1</label>
          </div>
          <div className="col form-floating">
            <input type="text" className="form-control" value={contactInformation?.parent2 || ""} readOnly />
            <label>Parent 2</label>
          </div>
        </div>
      )}
    </div>
  );
};
