import React, { useEffect, useState } from "react";

import { useAuthentication } from "./authentication-context";
import { LoadingOverlay } from "../components/loading-overlay/loading-overlay";

import "./login.scss";

export const users = [
  { username: "admin", password: "admin" },
  { username: "owner1", password: "owner1" },
  { username: "staff", password: "staff" },
  { username: "owner2", password: "owner2" }
];

export const Login: React.FC = () => {
  const { login, error, loading } = useAuthentication();
  const [username, setUsername] = useState<string>("admin");
  const [password, setPassword] = useState<string>("admin");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onLoginClick = () => {
    setErrorMessage("");
    login(username, password);
  };

  useEffect(() => {
    if (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      } else if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    } else {
      setErrorMessage("");
    }
  }, [error]);

  return (
    <>
      <div className="login-container">
        <div className="content-pad" style={{ maxWidth: "400px" }}>
          <LoadingOverlay loading={loading}>
            <div className="card">
              <div className="login-card-row">
                <div className="login-card-logo">
                  <img src="/img/logo-huntington.png" alt="Huntington Learning Center" />
                </div>
              </div>
              <br />
              <h2>Learning Center Operations System</h2>
              <br />
              <div className="form">
                <div className="row">
                  <div className="col form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={username}
                      onChange={e => setUsername(e.target.value)}
                    />
                    <label>Username</label>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col form-floating">
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                    <label>Password</label>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col form-floating">
                    <button className="btn btn-primary" onClick={onLoginClick} disabled={!username || !password}>
                      Login
                    </button>
                  </div>
                </div>
                <div className="row login-error">{errorMessage}</div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      </div>
    </>
  );
};
