import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";

import { TimeField } from "../fields/time-field";
import { DateField } from "../fields/date-field";
import * as ScheduleSlice from "../../data/schedule-slice";

import * as Types from "../../data/types";
import * as Utils from "../../data/utils";

import "./edit-student-schedule.scss";

export interface EditStudentScheduleProps {
  schedule: Types.StudentScheduleChartData;
  staff: Types.Employee[];
  center: Types.Center;
  onSave?: (payload: ScheduleSlice.SaveScheduleEvent) => void;
  onCancel?: () => void;
}

export interface UpdateStudentsOptions extends Types.StudentScheduleChartStudentData {
  selected: boolean;
}

export const EditStudentSchedule: React.FC<EditStudentScheduleProps> = ({
  schedule,
  staff,
  center,
  onSave,
  onCancel
}) => {
  const [localSchedule, setLocalSchedule] = useState<Types.StudentScheduleChartData>({ ...schedule });
  const [date, setDate] = useState<number | undefined>(schedule.start);
  const [start, setStart] = useState<number | undefined>(schedule.startMinutes);
  const [end, setEnd] = useState<number | undefined>(schedule.endMinutes);
  const [updateStudents, setUpdateStudents] = useState<UpdateStudentsOptions[]>(
    schedule.students.map(s => ({ ...s, selected: true }))
  );

  useHotkeys("escape", () => onCancel && onCancel(), { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] });
  useHotkeys("ctrl+s, cmd+s", () => localSave(), { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] });

  const tutorRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    tutorRef.current?.focus();
  }, []);

  const tutors = useMemo(() => {
    return staff.filter(s => {
      if (!localSchedule?.serviceSubject) {
        return true;
      }

      const hasRequiredSubject = s.serviceSubjects?.includes(localSchedule.serviceSubject);

      return (
        hasRequiredSubject &&
        Utils.isAvailableForTimeRange(
          localSchedule.start,
          localSchedule.end,
          Utils.getEffectiveSchedule(localSchedule.start, s.availability),
          center
        )
      );
    });
  }, [staff, localSchedule, center]);

  const resources = useMemo(() => (center.resources?.length ? center.resources : []), [center]);

  const localSave = useCallback(() => {
    if (localSchedule) {
      for (const student of localSchedule.students) {
        if (updateStudents.find(us => us.id === student.id && us.selected)) {
          const updated: ScheduleSlice.SaveScheduleEvent = {
            event: {
              id: student.scheduleEventId,
              studentId: student.id,
              name: student.name,
              studentScheduleId: student.prototypeScheduleId,
              studentScheduleDayId: student.dayScheduleId,
              serviceSubject: localSchedule.serviceSubject,
              staffId: localSchedule.staffId,
              resourceId: localSchedule.resourceId,
              start: localSchedule.start,
              end: localSchedule.end,
              status: localSchedule.status,
              comment: localSchedule.comment,
              maxNumberOfStudents: localSchedule.maxNumberOfStudents
            }
          };
          console.log("updating w/new values", student, updated);
          onSave && onSave(updated);
        } else {
          const notUpdated: ScheduleSlice.SaveScheduleEvent = {
            event: {
              id: student.scheduleEventId,
              studentId: student.id,
              name: student.name,
              studentScheduleId: student.prototypeScheduleId,
              studentScheduleDayId: student.dayScheduleId,
              serviceSubject: schedule.serviceSubject,
              staffId: schedule.staffId,
              resourceId: schedule.resourceId,
              start: schedule.start,
              end: schedule.end,
              status: schedule.status,
              comment: schedule.comment,
              maxNumberOfStudents: schedule.maxNumberOfStudents
            }
          };
          console.log("updating w/original values", student, notUpdated);
          onSave && onSave(notUpdated);
        }
      }
    }
    onCancel && onCancel();
  }, [onCancel, onSave, localSchedule, updateStudents, schedule]);

  const onChangeTutor = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLocalSchedule(prev => {
      return { ...prev, staffId: e.target.value };
    });
  };

  const onChangeResource = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLocalSchedule(prev => {
      return { ...prev, resourceId: e.target.value };
    });
  };

  const onChangeUpdateStudents = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValues = Array.from(e.target.selectedOptions, option => option.value);
    setUpdateStudents(prev => {
      return prev.map(value => {
        if (newValues.includes(value.id)) {
          return { ...value, selected: true };
        } else {
          return { ...value, selected: false };
        }
      });
    });
  };

  const onChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalSchedule(prev => {
      return { ...prev, comment: e.target.value };
    });
  };

  const onChangeDate = (value: number | undefined) => {
    setDate(value);
  };

  const onChangeStart = (value: number | undefined) => {
    setStart(value);
  };

  const onChangeEnd = (value: number | undefined) => {
    setEnd(value);
  };

  useEffect(() => {
    if (!start || !end || !date) {
      return;
    }

    const newStart = moment(date).startOf("d").clone().add(start, "m").valueOf();
    const newEnd = moment(date).startOf("d").clone().add(end, "m").valueOf();

    setLocalSchedule(prev => {
      return { ...prev, start: newStart, startMinutes: start, end: newEnd, endMinutes: end };
    });
  }, [start, end, date]);

  if (!schedule || !localSchedule) {
    return null;
  }

  return (
    <div className={classnames("edit-student-schedule-outer-container")}>
      <h2>Update Schedule Assignment</h2>
      <div className="edit-student-schedule-container form">
        <div className="row">
          <div className="col form-floating">
            <select
              multiple
              className="form-control"
              value={updateStudents.filter(us => us.selected).map(us => us.id)}
              onChange={onChangeUpdateStudents}
              style={{ height: "100px" }}
            >
              {updateStudents.map(us => {
                return (
                  <option value={us.id} key={us.id}>
                    {us.name}
                  </option>
                );
              })}
            </select>
            <label>Update Student(s)</label>
          </div>
        </div>
        <div className="row">
          <div className="col form-floating">
            <input
              value={Utils.formatServiceSubject(localSchedule.serviceSubject, "longservice")}
              readOnly
              className="form-control"
            />
            <label>Service/Subject</label>
          </div>
        </div>
        <div className="row">
          <div className="col form-floating">
            <select
              value={localSchedule?.staffId ?? ""}
              onChange={onChangeTutor}
              ref={tutorRef}
              className="form-control"
            >
              <option value="">Select a Tutor</option>
              {tutors.map(t => {
                return (
                  <option key={t.id} value={t.id}>
                    {t.name}
                  </option>
                );
              })}
            </select>
            <label>Tutor</label>
          </div>
        </div>
        <div className="row">
          <div className="col form-floating">
            <select value={localSchedule?.resourceId ?? ""} onChange={onChangeResource} className="form-control">
              <option value="">Select a Resource</option>
              {resources.map(r => {
                return (
                  <option key={r.id} value={r.id}>
                    {r.name}
                  </option>
                );
              })}
            </select>
            <label>Resource</label>
          </div>
        </div>
        {/* <div className="single">
          <div className="data-cell">
            <span>Session Status</span>
            <span>
              <select value={localSchedule?.status ?? "regular"} onChange={onChangeStatus}>
                <option value="">Select a Status</option>
                {Types.attendanceCodeValues.map((c: string) => {
                  return (
                    <option key={c} value={c}>
                      {Types.attendanceCodes[c].description}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>
        </div> */}

        <div className="row">
          <div className="col form-floating">
            <textarea
              value={localSchedule?.comment ?? ""}
              onChange={onChangeComment}
              className="form-control"
              style={{ height: "100px" }}
            ></textarea>
            <label>Comment</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col form-floating table">
          <table className="edit-student-schedule-days-table" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Start</th>
                <th>End</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <DateField value={date} onChange={onChangeDate} />
                </td>
                <td>
                  <TimeField value={start} onChange={onChangeStart} />
                </td>
                <td>
                  <TimeField value={end} onChange={onChangeEnd} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className={classnames("edit-student-schedule-button-row")}>
        <button className="btn btn-secondary" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={localSave} disabled={!updateStudents.some(us => us.selected)}>
          Save
        </button>
      </div>
    </div>
  );
};
