import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import * as Types from "../../data/types";
import * as Utils from "../../data/utils";

import "./schedule-edit.scss";

export interface ScheduleEditProps {
  schedule: Types.WeekSchedule;
  onChangeSchedule?: (schedule: Types.WeekSchedule) => void;
}

export interface ScheduleDayEditProps {
  day: Types.DaysOfWeekType;
  schedule: Types.WeekSchedule;
  onChangeScheduleDay: (daySchedule: Types.DaySchedule) => void;
}

export const ScheduleDayEdit: React.FC<ScheduleDayEditProps> = ({ day, schedule, onChangeScheduleDay }) => {
  const [localValues, setLocalValues] = useState<Types.DaySchedule>(
    schedule?.days?.find(d => d.day === day) ?? { id: uuidv4(), day }
  );

  useEffect(() => {
    onChangeScheduleDay(localValues);
  }, [onChangeScheduleDay, localValues]);

  const onStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValues(prev => {
      return { ...prev, start: Utils.parse24HourTime(e.target.value) };
    });
  };

  const onEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValues(prev => {
      return { ...prev, end: Utils.parse24HourTime(e.target.value) };
    });
  };

  return (
    <div className="schedule-day-edit-cell-contents">
      <input
        type="time"
        className="form-control"
        step={15 * 60}
        value={Utils.format24HourTime(localValues.start)}
        onChange={onStartChange}
      />
      <div>until</div>
      <input
        type="time"
        className="form-control"
        step={15 * 60}
        value={Utils.format24HourTime(localValues.end)}
        onChange={onEndChange}
      />
    </div>
  );
};

export const ScheduleEdit: React.FC<ScheduleEditProps> = ({ schedule, onChangeSchedule }) => {
  //const [localSchedule, setLocalSchedule] = useState<Types.WeekSchedule>(schedule ?? { id: uuidv4(), days: [] });
  const [localSchedule, setLocalSchedule] = useState<Types.WeekSchedule>(
    schedule ? { ...schedule } : { id: uuidv4(), days: [] }
  );
  //const [localSchedule, setLocalSchedule] = useState<Types.WeekSchedule>({ ...schedule, days: [...schedule?.days] });

  useEffect(() => {
    onChangeSchedule && onChangeSchedule(localSchedule);
  }, [localSchedule, onChangeSchedule]);

  const onChangeScheduleDay = useCallback((daySchedule: Types.DaySchedule) => {
    setLocalSchedule(prev => {
      const otherDays = prev.days.filter(d => d.id !== daySchedule.id);
      return { ...prev, days: [...otherDays, daySchedule] };
    });
  }, []);

  if (!schedule) {
    return null;
  }

  return (
    <table className="schedule-edit table">
      <thead>
        <tr>
          <th>Sun</th>
          <th>Mon</th>
          <th>Tue</th>
          <th>Wed</th>
          <th>Thu</th>
          <th>Fri</th>
          <th>Sat</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <ScheduleDayEdit day={"sunday"} schedule={localSchedule} onChangeScheduleDay={onChangeScheduleDay} />
          </td>
          <td>
            <ScheduleDayEdit day={"monday"} schedule={localSchedule} onChangeScheduleDay={onChangeScheduleDay} />
          </td>
          <td>
            <ScheduleDayEdit day={"tuesday"} schedule={localSchedule} onChangeScheduleDay={onChangeScheduleDay} />
          </td>
          <td>
            <ScheduleDayEdit day={"wednesday"} schedule={localSchedule} onChangeScheduleDay={onChangeScheduleDay} />
          </td>
          <td>
            <ScheduleDayEdit day={"thursday"} schedule={localSchedule} onChangeScheduleDay={onChangeScheduleDay} />
          </td>
          <td>
            <ScheduleDayEdit day={"friday"} schedule={localSchedule} onChangeScheduleDay={onChangeScheduleDay} />
          </td>
          <td>
            <ScheduleDayEdit day={"saturday"} schedule={localSchedule} onChangeScheduleDay={onChangeScheduleDay} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
