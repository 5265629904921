import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import * as Types from "../../data/types";
import * as Utils from "../../data/utils";
import * as EmployeeMocks from "../../data/employees";
import * as StudentsMocks from "../../data/students";

import { TodaysOverview } from "../../components/todays-overview/todays-overview";
import { StudentDetail } from "../../components/student-detail/student-detail";
import {
  GeneralList,
  GeneralListColumnSpecification,
  GeneralListDataMapHandler
} from "../../components/general-list/general-list";
import { ItemType } from "../../hooks/use-sortable-filterable-data";
import { AppLayout, Rightside, Leftside, ContentPad, Utility } from "../../app/app-layout";
import { ListHeader } from "../../components/list-header/list-header";

const datasets = StudentsMocks.getDashboardDatasets();
const unassigned = StudentsMocks.students.filter(s => !s.assignedTo);

export const alertsMapper: GeneralListDataMapHandler = function (this, student, index?, array?) {
  const s = student as Types.Student;
  return {
    id: s.id,
    ready: true,
    name: Utils.formatAlphabeticName(s.name),
    nameSorter: Utils.formatAlphabeticName(s.name),
    alertDescription: s.alertDescription
  } as ItemType;
};

export const alertsSpecs: GeneralListColumnSpecification[] = [
  { title: "Ready", name: "ready", isReadyCell: true },
  {
    title: "Student",
    name: "nameSorter",
    sorterName: "nameSorter",
    className: "primary",
    isStrongCell: true,
    defaultSort: true
  },
  { title: "Description", name: "alertDescription" }
];

const reformatTimeString = (original: string | undefined): { display: string; numeric: number } => {
  if (!original || !original.includes(":")) {
    return { display: "", numeric: 0 };
  } else {
    const parts = original.split(":").map(p => parseInt(p, 10));
    const numeric = parts.length === 1 ? parts[0] : parts[0] * 60 + parts[1];
    return { display: Utils.formatTime(numeric), numeric };
  }
};

export const evalsMapper: GeneralListDataMapHandler = function (this, student, index?, array?) {
  const employees = this?.employees ?? [];
  const s = student as Types.Student;
  const start = reformatTimeString(s.aeTime);
  return {
    id: s.id,
    ready: !!s.assignedTo,
    name: Utils.formatAlphabeticName(s.name),
    nameSorter: Utils.formatAlphabeticName(s.name),
    start: start.display,
    startSorter: start.numeric,
    assigned: employees.length && !!s.assignedTo ? employees.find(e => e.id === s.assignedTo)?.name || "" : "",
    alert: !!s.alert,
    service: s.service || ""
  } as ItemType;
};

export const startsMapper: GeneralListDataMapHandler = function (this, student, index?, array?) {
  const employees = this?.employees ?? [];
  const s = student as Types.Student;
  //const start = reformatTimeString(s.schedules?.[0]?.start);
  return {
    id: s.id,
    ready: !!s.assignedTo,
    name: Utils.formatAlphabeticName(s.name),
    nameSorter: Utils.formatAlphabeticName(s.name),
    start: "", // start.display,
    startSorter: 0, // start.numeric,
    assigned: employees.length && !!s.assignedTo ? employees.find(e => e.id === s.assignedTo)?.name || "" : "",
    alert: !!s.alert,
    service: s.service || ""
  } as ItemType;
};

// Academic Evaluations and Start Today have identical specs so they can share specs
export const evalsAndStartsSpecs: GeneralListColumnSpecification[] = [
  { title: "Ready", name: "ready", isReadyCell: true },
  {
    title: "Student",
    name: "nameSorter",
    sorterName: "nameSorter",
    className: "primary",
    isStrongCell: true,
    defaultSort: true
  },
  { title: "Start", name: "start", sorterName: "startSorter" },
  { title: "Service", name: "service", className: "text-center" },
  { title: "Assigned", name: "assigned", sorterName: "assigned", isStrongCell: true }
  //{ title: "", name: "alert", isAlertCell: true }
];

export const balanceDueMapper: GeneralListDataMapHandler = function (this, student, index?, array?) {
  const s = student as Types.Student;
  const balance = s.financials?.reduce((acc, item) => acc + item.amount, 0) || 0;
  return {
    id: s.id,
    ready: !!s.assignedTo,
    name: Utils.formatAlphabeticName(s.name),
    nameSorter: Utils.formatAlphabeticName(s.name),
    balanceSorter: balance,
    balance: balance.toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    })
  } as ItemType;
};

export const balanceDueSpecs: GeneralListColumnSpecification[] = [
  { title: "Ready", name: "ready", isReadyCell: true },
  {
    title: "Student",
    name: "nameSorter",
    sorterName: "nameSorter",
    className: "primary",
    isStrongCell: true,
    defaultSort: true
  },
  { title: "Balance", name: "balance", sorterName: "balanceSorter" }
];

export const attendingTodaySpecs: GeneralListColumnSpecification[] = [
  { title: "Ready", name: "ready", isReadyCell: true },
  {
    title: "Student",
    name: "nameSorter",
    sorterName: "nameSorter",
    className: "primary",
    isStrongCell: true,
    defaultSort: true
  },
  { title: "Service", name: "service", className: "text-center" },
  { title: "Assigned", name: "assigned", sorterName: "assigned", isStrongCell: true },
  { title: "", name: "alert", isAlertCell: true, tooltip: "Starting Today" }
];

export const Dashboard: React.FC = () => {
  // const { user, activeCenter, logout } = useAuthentication();

  const [showStudentDetailFor, setShowStudentDetailFor] = useState<Types.Student>();
  const [toDoSubset, setToDoSubset] = useState<Types.TodoItemType>("ae-today");

  const location = useLocation();
  useEffect(() => {
    const subparts = location.pathname.split("/");
    if (!subparts.length) {
      setToDoSubset("ae-today");
    } else {
      const lastpart = subparts[subparts.length - 1];
      if (lastpart.toLowerCase().trim() === "dashboard") {
        setToDoSubset("ae-today");
      } else {
        setToDoSubset(lastpart as Types.TodoItemType);
      }
    }
  }, [location.pathname]);

  // you'll want the three of these state values set according to what is the default start view
  const [mapper, setMapper] = useState<GeneralListDataMapHandler | undefined>(() => evalsMapper);
  const [specs, setSpecs] = useState<GeneralListColumnSpecification[] | undefined>(evalsAndStartsSpecs);
  const [active, setActive] = useState<string>("");

  const onSelectCenterListItem = (student: { id: string }) => {
    setShowStudentDetailFor(student as Types.Student);
    setActive(student.id);
  };

  const onCloseStudentDetail = () => {
    setShowStudentDetailFor(undefined);
    setActive("");
  };

  useEffect(() => {
    switch (toDoSubset) {
      case "ae-today": {
        setMapper(() => evalsMapper);
        setSpecs(evalsAndStartsSpecs);
        break;
      }
      case "starting-today": {
        setMapper(() => startsMapper);
        setSpecs(evalsAndStartsSpecs);
        break;
      }
      case "alert-list": {
        setMapper(() => alertsMapper);
        setSpecs(alertsSpecs);
        break;
      }
      case "balance-due": {
        setMapper(() => balanceDueMapper);
        setSpecs(balanceDueSpecs);
        break;
      }
      case "attending-today": {
        setSpecs(attendingTodaySpecs);
        setMapper(undefined);
        break;
      }
      default: {
        setMapper(undefined);
        setSpecs(undefined);
      }
    }
  }, [toDoSubset]);

  const [filter, setFilter] = useState<string>("");
  const onFilterChange = useCallback((newvalue: string) => {
    setFilter(newvalue);
  }, []);
  const tableRef = useRef<HTMLTableElement>(null);
  const onPrintRequested = useReactToPrint({
    content: () => tableRef.current
  });

  return (
    <AppLayout activeTab="Dashboard" tabQualifier={Types.todoItemDescriptiveNames[toDoSubset]}>
      <Leftside>
        <Utility>
          <ListHeader filter={filter} onFilterChange={onFilterChange} onPrintRequested={onPrintRequested} />
        </Utility>
        <ContentPad>
          <GeneralList
            data={datasets[toDoSubset]}
            employees={EmployeeMocks.employees}
            onSelectItem={onSelectCenterListItem}
            activeItem={active}
            mapper={mapper}
            specs={specs}
            filter={filter}
            ref={tableRef}
          />
        </ContentPad>
      </Leftside>
      <Rightside>
        <ContentPad>
          {!!showStudentDetailFor && <StudentDetail student={showStudentDetailFor} onClose={onCloseStudentDetail} />}
          {!showStudentDetailFor && (
            <TodaysOverview
              aeToday={datasets["ae-today"]}
              unassigned={unassigned}
              balanceDue={datasets["balance-due"]}
              missingInfo={datasets["missing-info"]}
              employees={EmployeeMocks.employees}
            />
          )}
        </ContentPad>
      </Rightside>
    </AppLayout>
  );
};
