import React, { useEffect, useState } from "react";
import { useModal } from "react-hooks-use-modal";
import { v4 as uuidv4 } from "uuid";

import * as Types from "../../../data/types";
import * as Utils from "../../../data/utils";

import * as StudentsSlice from "../../../data/students-slice";
import { ItemType } from "../../../hooks/use-sortable-filterable-data";
import {
  GeneralList,
  GeneralListColumnSpecification,
  GeneralListDataMapHandler,
  GeneralListItemSelectHandler
} from "../../general-list/general-list";
import { ScheduleBriefCell } from "../../general-list/schedule-brief-cell";

import { EditStudentScheduling } from "./edit-student-scheduling";

export interface StudentSchedulingProps {
  centerId: Types.UniqueId;
  studentId: Types.UniqueId;
  schedules?: Types.StudentSchedule[];
  onSaveStudentSchedule?: (payload: StudentsSlice.SaveStudentSchedulePayload) => void;
}

export const StudentScheduling: React.FC<StudentSchedulingProps> = ({
  centerId,
  studentId,
  schedules,
  onSaveStudentSchedule
}) => {
  const [selected, setSelected] = useState<Types.StudentSchedule>();
  const [editing, setEditing] = useState<Types.StudentSchedule>();

  const onSelectItem: GeneralListItemSelectHandler = item => {
    setSelected(schedules?.find(s => s.id === item.id));
  };

  const [EditCenterRateModal, openEditCenterRateModal, closeEditCenterRateModal] = useModal("utility-root", {
    preventScroll: false,
    closeOnOverlayClick: false
  });

  const onActionButtonClick = (id: string) => {
    setEditing(schedules?.find(s => s.id === id));
  };

  useEffect(() => {
    if (editing) {
      openEditCenterRateModal();
    } else {
      closeEditCenterRateModal();
    }
  }, [editing, openEditCenterRateModal, closeEditCenterRateModal]);

  const onCancelEditing = () => {
    setEditing(undefined);
  };

  const onSaveEditing = (schedule: Types.WeekSchedule) => {
    if (schedule && schedule.id && onSaveStudentSchedule) {
      console.log("saving", schedule);
      onSaveStudentSchedule({ studentId, schedule: { ...schedule } });
      setEditing(undefined);
    }
  };

  const onAddResource = () => {
    setEditing({ id: uuidv4(), days: [{ id: uuidv4(), day: undefined }] });
  };

  return (
    <>
      <div>
        <h5>Student Scheduling</h5>
        <GeneralList
          data={schedules}
          mapper={studentSchedulingMapper}
          specs={studentSchedulingSpecs}
          activeItem={selected?.id}
          onSelectItem={onSelectItem}
          onActionButtonClick={onActionButtonClick}
          pageSize={6}
        />
      </div>
      <div className="center-resource-toolbar">
        <button className="btn btn-primary" onClick={onAddResource}>
          Add New Schedule
        </button>
      </div>

      {!!editing && editing && (
        <EditCenterRateModal>
          <EditStudentScheduling
            schedule={schedules?.find(s => s.id === editing.id) ?? editing}
            onCancel={onCancelEditing}
            onSave={onSaveEditing}
          />
        </EditCenterRateModal>
      )}
    </>
  );
};

export const studentSchedulingMapper: GeneralListDataMapHandler = function (this, schedule, index?, array?) {
  const s = schedule as Types.StudentSchedule;
  const scheduleCell = <ScheduleBriefCell schedule={s} />;
  const activeCell = !s.deactivated ? <i className="fal icon fa-check"></i> : null;
  return {
    id: s.id,
    serviceSubject: Utils.formatServiceSubject(s.serviceSubject, "longservice"),
    delivery: s.delivery,
    effective: Utils.formatDate(s.effective),
    effectiveSorter: s.effective,
    expiration: Utils.formatDate(s.expiration),
    expirationSorter: s.expiration,
    scheduleCell,
    activeCell
  } as ItemType;
};

export const studentSchedulingSpecs: GeneralListColumnSpecification[] = [
  {
    title: "Service/Subject",
    name: "serviceSubject",
    sorterName: "serviceSubject",
    className: "primary",
    defaultSort: true
  },
  { title: "Delivery", name: "delivery" },
  { title: "Start Date", name: "effective", sorterName: "expirationSorter" },
  { title: "End Date", name: "expiration", sorterName: "expirationSorter" },
  { title: "Schedule", name: "scheduleCell" },
  //{ title: "Active", name: "activeCell" },
  { title: "Activate", name: "activate", isActionButton: true, actionButtonIconClasses: "fal icon fa-pencil" }
];
