import React from "react";
import moment from "moment";

import * as Types from "../../../data/types";

export interface AttendanceProps {
  data?: Types.Attendance[];
}

export const Attendance: React.FC<AttendanceProps> = ({ data }) => {
  return (
    <>
      <table data-testid="student-details-tab-attendance" className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Scheduled Time</th>
            <th>Present</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((a, index) => {
            return (
              <tr key={`student-detail-tab-attendance-key-${index}`}>
                <td>{moment(a.date).format("MMM D, YYYY")}</td>
                <td>{a.time}</td>
                <td style={{ fontSize: "110%", paddingLeft: "25px" }}>
                  {a.present ? (
                    <i className="fal active fa-check-circle" style={{ color: "#c9178d" }} />
                  ) : (
                    <i className="fal fa-times-circle" />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
