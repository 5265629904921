import React, { useMemo } from "react";

import * as Types from "../../data/types";

import { CardProps, CountCards } from "./count-cards";
import { UpcomingAeStart } from "./upcoming-ae-start";
import { TodaysAeAssignments } from "./todays-ae-assignments";

export interface TodaysOverviewProps {
  aeToday: Types.Student[];
  unassigned: Types.Student[];
  balanceDue: Types.Student[];
  missingInfo: Types.Student[];
  employees: Types.Employee[];
}

export const TodaysOverview: React.FC<TodaysOverviewProps> = ({
  aeToday,
  unassigned,
  balanceDue,
  missingInfo,
  employees
}) => {
  const countCardsData: CardProps[] = useMemo(
    () => [
      { count: aeToday.length, label: "AE Today", id: "a" },
      { count: unassigned.length, label: "Unassigned", id: "b" },
      { count: balanceDue.length, label: "Balance > $0", id: "c" },
      { count: missingInfo.length, label: "Missing Info", id: "d" }
    ],
    [aeToday, unassigned, balanceDue, missingInfo]
  );

  return (
    <div className="content-pad">
      <CountCards data={countCardsData} />

      <UpcomingAeStart aeToday={aeToday} />

      <TodaysAeAssignments aeToday={aeToday} employees={employees} />
    </div>
  );
};
