import React from "react";
import classnames from "classnames";

import * as Types from "../../data/types";

import "./center-information.scss";

export interface CenterInformationProps {
  center?: Types.Center;
  mode?: "edit" | "view";
  onSave?: () => void;
  onCancel?: () => void;
  onEdit?: () => void;
}

export interface FieldProps {
  value?: string;
  mode?: "edit" | "view";
}

export const Field: React.FC<FieldProps> = ({ value, mode }) => {
  return <input type="text" className="form-control" value={value} disabled={mode === "view"} />;
};

export const CenterInformation: React.FC<CenterInformationProps> = ({
  center,
  mode = "view",
  onEdit,
  onSave,
  onCancel
}) => {
  return (
    <div className={classnames("center-information-outer-container", { "edit-mode": mode === "edit" })}>
      <h2>Center Information</h2>
      <div className="center-information-container">
        <div className="row">
          <div className="col form-floating">
            <Field value={center?.name} mode={mode} />
            <label>Center Name</label>
            {onEdit && (
              <div className={classnames("center-information-edit-button-container", { "edit-mode": mode === "edit" })}>
                <button className="unstyled" onClick={onEdit}>
                  <i className="fal icon fa-pencil" />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col form-floating">
            <Field value={center?.line1} mode={mode} />
            <label>Address 1</label>
          </div>
        </div>
        <div className="row">
          <div className="col form-floating">
            <Field value={center?.line2} mode={mode} />
            <label>Address 2</label>
          </div>
        </div>
        {!!center?.gps && (
          <div className="row">
            <div className="col form-floating">
              <Field value={center?.gps} mode={mode} />
              <label>GPS</label>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col form-floating">
            <Field value={center?.city} mode={mode} />
            <label>City</label>
          </div>
          <div className="col form-floating">
            <Field value={center?.state} mode={mode} />
            <label>State</label>
          </div>
          <div className="col form-floating">
            <Field value={center?.zipcode} mode={mode} />
            <label>Zip code</label>
          </div>
        </div>
        {!!center?.email && (
          <div className="row">
            <div className="col form-floating">
              <Field value={center?.email} mode={mode} />
              <label>Email</label>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col form-floating">
            <Field value={center?.phone} mode={mode} />
            <label>Phone</label>
          </div>
        </div>
        <div className="row">
          <div className="col form-floating">
            <Field value={center?.url} mode={mode} />
            <label>Url</label>
          </div>
        </div>
      </div>

      <div className={classnames("center-information-button-row", { "edit-mode": mode === "edit" })}>
        <button className="btn btn-secondary" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
};
