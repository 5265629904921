import React from "react";
import moment from "moment";

import * as Types from "../../../data/types";

export interface HistoryProps {
  data?: Types.History[];
}

export const History: React.FC<HistoryProps> = ({ data }) => {
  return (
    <>
      <table data-testid="student-details-tab-history-financial" className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Event</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((h, index) => {
            return (
              <tr key={`student-detail-tab-history-key-${index}`}>
                <td style={{ whiteSpace: "nowrap", verticalAlign: "top", paddingRight: "20px" }}>
                  {moment(h.date).format("MMM D, YYYY")}
                </td>
                <td style={{ fontSize: "90%" }}>{h.text}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
