import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";
//import Select from "react-select";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListSubheader from "@mui/material/ListSubheader";

import * as Types from "../../data/types";

import "./schedule-master-controls.scss";

export interface ScheduleMasterControlsProps {
  onChangeDates?: (dates: string[]) => void;
  onChangeServiceFilter?: (newvalue: string) => void;
  onChangeOptions?: (options: string[]) => void;
}

export type DateRangePreset = "tomorrow" | "today" | "thisweek" | "nextweek";

export const calculateRangeParameters = (today: number, range: DateRangePreset) => {
  if (range === "tomorrow") {
    const start = moment(today).startOf("day").add(1, "day").format("YYYY-MM-DD");
    const end = moment(today).endOf("day").add(1, "day").format("YYYY-MM-DD");
    return { start, end };
  } else if (range === "thisweek") {
    const start = moment(today).startOf("isoWeek").format("YYYY-MM-DD");
    const end = moment(today).endOf("isoWeek").format("YYYY-MM-DD");
    return { start, end };
  } else if (range === "nextweek") {
    const start = moment(today).add(1, "week").startOf("isoWeek").format("YYYY-MM-DD");
    const end = moment(today).add(1, "week").endOf("isoWeek").format("YYYY-MM-DD");
    return { start, end };
  }

  const start = moment(today).startOf("day").format("YYYY-MM-DD");
  const end = moment(today).endOf("day").format("YYYY-MM-DD");
  return { start, end };
};

export interface ScheduleOption {
  readonly value: string;
  readonly label: string;
  readonly color?: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export const scheduleOptions: readonly ScheduleOption[] = [
  { value: "subjects", label: "Subjects" },
  { value: "locations", label: "Locations" }
];

export const ScheduleMasterControls: React.FC<ScheduleMasterControlsProps> = ({
  onChangeDates,
  onChangeServiceFilter,
  onChangeOptions
}) => {
  const today = useMemo(() => Date.now(), []);
  const [dateRange, setDateRange] = useState<{ start: string; end: string }>({ start: "", end: "" });
  const [range, setRange] = useState<DateRangePreset>("nextweek");
  const [options, setOptions] = useState<string[]>(["subjects"]);
  const [serviceFilter, setServiceFilter] = useState<string>("ALL");

  const handleChangeOptions = (event: SelectChangeEvent<typeof options>) => {
    const {
      target: { value }
    } = event;
    setOptions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeFilter = (event: SelectChangeEvent<string>) => {
    const {
      target: { value }
    } = event;
    setServiceFilter(value);
  };

  const handleTimeframeChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value }
    } = event;
    setRange(value as DateRangePreset);
  };

  useEffect(() => {
    onChangeOptions && onChangeOptions(options);
  }, [options, onChangeOptions]);

  useEffect(() => {
    onChangeServiceFilter && onChangeServiceFilter(serviceFilter);
  }, [serviceFilter, onChangeServiceFilter]);

  const onChangeStart = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateRange(prev => ({
      ...prev,
      start: e.target.value
    }));
  };

  const onChangeEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateRange(prev => ({
      ...prev,
      end: e.target.value
    }));
  };

  useEffect(() => {
    const newDateRange = calculateRangeParameters(today, range);
    setDateRange(newDateRange);
  }, [range, today]);

  useEffect(() => {
    let current = moment(dateRange.start, "YYYY-MM-DD");
    const last = moment(dateRange.end, "YYYY-MM-DD");
    if (last.isBefore(current)) {
      setDateRange(prev => ({
        ...prev,
        end: moment(current).add(6, "d").format("YYYY-MM-DD")
      }));
    } else {
      const newDates = [];
      while (current.isSameOrBefore(last)) {
        newDates.push(current.format("YYYY-MM-DD"));
        current = current.clone().add(1, "day");
      }
      onChangeDates && onChangeDates(newDates);
    }
  }, [dateRange, onChangeDates]);

  return (
    <div className="schedule-master-controls-container">
      <div className="schedule-master-controls">
        <div style={{ display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center" }}>
          <div>
            <input type="date" className="form-control" value={dateRange.start} onChange={onChangeStart} />
          </div>
          <div>&nbsp;through&nbsp;</div>
          <div>
            <input type="date" className="form-control" value={dateRange.end} onChange={onChangeEnd} />
          </div>
        </div>

        <div>
          <Box sx={{ minWidth: 160 }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="timeframe-select">Timeframe</InputLabel>
              <Select
                value={range}
                onChange={handleTimeframeChange}
                id="timeframe-select"
                label="Timeframe"
                input={<OutlinedInput label="Timeframe" />}
              >
                <MenuItem value="thisweek">This Week</MenuItem>
                <MenuItem value="nextweek">Next Week</MenuItem>
                <MenuItem value="tomorrow">Tomorrow</MenuItem>
                <MenuItem value="today">Today</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <div>
          <Box sx={{ minWidth: 300 }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="grouped-native-select">Service Filter</InputLabel>
              <Select
                value={serviceFilter}
                onChange={handleChangeFilter}
                id="grouped-native-select"
                label="Service Filter"
                input={<OutlinedInput label="Service Filter" />}
              >
                <MenuItem value="ALL">All Services/Subjects</MenuItem>
                {Types.serviceSubjectCombinedMap.map(source => {
                  if (source.subjects.length === 0) {
                    return (
                      <MenuItem key={source.service} value={source.service}>
                        {source.serviceDescription}
                      </MenuItem>
                    );
                  } else {
                    const items = source.subjects.map(subject => {
                      return (
                        <MenuItem key={subject.code} value={subject.code} style={{ marginLeft: "20px" }}>
                          {subject.description}
                        </MenuItem>
                      );
                    });
                    return [<ListSubheader key={source.service}>{source.serviceDescription}</ListSubheader>, ...items];
                  }
                })}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div>
          <Box sx={{ minWidth: 300 }}>
            <FormControl fullWidth>
              <InputLabel id="options-simple-select-label">Options</InputLabel>
              <Select
                labelId="options-simple-select-label"
                id="options-simple-select"
                value={options}
                label="Options"
                input={<OutlinedInput label="Options" />}
                onChange={handleChangeOptions}
                renderValue={selected =>
                  scheduleOptions
                    .filter(so => selected.includes(so.value))
                    .map(so => so.label)
                    .join(", ")
                }
                multiple
              >
                {scheduleOptions.map((option: ScheduleOption) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={options.includes(option.value)} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>
    </div>
  );
};
