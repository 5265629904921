import React from "react";

import * as Types from "../../../data/types";

import * as StaffSlice from "../../../data/staff-slice";

import "./subjects.scss";

export interface SubjectsProps {
  centerId?: Types.UniqueId;
  employeeId?: Types.UniqueId;
  subjects?: Types.SubjectType[];
  serviceSubjects?: string[];
  onToggleStaffSubject?: (payload: StaffSlice.ToggleSubjectPayload) => void;
  onToggleStaffServiceSubject?: (payload: StaffSlice.ToggleServiceSubjectPayload) => void;
}

export const Subjects: React.FC<SubjectsProps> = ({
  //subjects,
  centerId,
  employeeId,
  serviceSubjects,
  //onToggleStaffSubject,
  onToggleStaffServiceSubject
}) => {
  return (
    <>
      <table className="table" data-testid="staff-details-tab-subjects">
        <thead>
          <tr>
            <th></th>
            <th>Subject</th>
          </tr>
        </thead>
        <tbody>
          {/* {Types.subjectTypes.map((s, index) => {
            return (
              <tr key={`staff-details-tab-subjects-key-${index}`} className="staff-details-tab-subjects-row">
                <td style={{ fontSize: "90%", width: "80px" }}>
                  <input
                    type="checkbox"
                    checked={subjects?.includes(s)}
                    onChange={() => {
                      if (onToggleStaffSubject && centerId && employeeId) {
                        onToggleStaffSubject({ centerId, staffId: employeeId, subject: s });
                      }
                    }}
                  />
                </td>
                <td style={{ whiteSpace: "nowrap", verticalAlign: "top", paddingRight: "20px" }}>{s}</td>
              </tr>
            );
          })} */}

          {Types.serviceSubjectCombinedMap.map((s, index) => {
            if (s.subjects.length === 0) {
              const id = `staff-details-tab-subjects-key-${s.service}`;
              return (
                <tr key={id} className="staff-details-tab-subjects-row">
                  <td style={{ fontSize: "90%", width: "80px" }}>
                    <input
                      id={id}
                      type="checkbox"
                      checked={serviceSubjects?.includes(s.service) ?? false}
                      onChange={() => {
                        if (onToggleStaffServiceSubject && centerId && employeeId) {
                          onToggleStaffServiceSubject({ centerId, staffId: employeeId, serviceSubject: s.service });
                        }
                      }}
                    />
                  </td>
                  <td style={{ whiteSpace: "nowrap", verticalAlign: "top", paddingRight: "20px" }}>
                    <label htmlFor={id}>{s.serviceDescription}</label>
                  </td>
                </tr>
              );
            } else {
              return s.subjects.map(subj => {
                const id = `staff-details-tab-subjects-key-${subj.code}`;
                return (
                  <tr key={id} className="staff-details-tab-subjects-row">
                    <td style={{ fontSize: "90%", width: "80px" }}>
                      <input
                        id={id}
                        type="checkbox"
                        checked={serviceSubjects?.includes(subj.code) ?? false}
                        onChange={() => {
                          if (onToggleStaffServiceSubject && centerId && employeeId) {
                            onToggleStaffServiceSubject({
                              centerId,
                              staffId: employeeId,
                              serviceSubject: subj.code
                            });
                          }
                        }}
                      />
                    </td>
                    <td style={{ whiteSpace: "nowrap", verticalAlign: "top", paddingRight: "20px" }}>
                      <label htmlFor={id}>{`${s.serviceDescription} - ${subj.description}`}</label>
                    </td>
                  </tr>
                );
              });
            }
          })}
        </tbody>
      </table>
    </>
  );
};
