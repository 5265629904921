import React from "react";

import * as Types from "../../../data/types";

import * as States from "../../../data/states";

export interface ContactProps {
  contactInformation?: Types.ContactInformation;
}

export const Contact: React.FC<ContactProps> = ({ contactInformation }) => {
  return (
    <div className="form" data-testid="student-details-tab-content-contact">
      <div className="row">
        <div className="col form-floating">
          <input
            className="form-control"
            id="staff-detail-contact-form-phone"
            type="text"
            value={contactInformation?.phone || ""}
            readOnly
          />
          <label htmlFor="staff-detail-contact-form-phone">Phone</label>
        </div>
      </div>

      <div className="row">
        <div className="col sm-12 end">
          <hr />
        </div>
      </div>

      <div className="row">
        <div className="col form-floating">
          <input
            type="email"
            className="form-control"
            id="staff-detail-contact-form-business-email"
            value={contactInformation?.businessEmail || ""}
            readOnly
          />
          <label htmlFor="staff-detail-contact-form-business-email">Business Email</label>
        </div>

        <div className="col form-floating">
          <input
            type="email"
            className="form-control"
            id="staff-detail-contact-form-personal-email"
            value={contactInformation?.personalEmail || ""}
            readOnly
          />
          <label htmlFor="staff-detail-contact-form-personal-email">Personal Email</label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col form-floating">
          <input
            type="text"
            className="form-control"
            value={contactInformation?.address?.line1 || ""}
            readOnly
            id="staff-detail-contact-form-address"
          />
          <label htmlFor="staff-detail-contact-form-address">Address</label>
        </div>
        <div className="col form-floating">
          <input
            type="text"
            className="form-control"
            value={contactInformation?.address?.line2 || ""}
            readOnly
            id="staff-detail-contact-form-suite"
          />
          <label htmlFor="staff-detail-contact-form-suite">Suite/Apartment</label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col form-floating">
          <input
            type="text"
            className="form-control"
            value={contactInformation?.address?.city || ""}
            readOnly
            id="staff-detail-contact-form-city"
          />
          <label htmlFor="staff-detail-contact-form-city">City</label>
        </div>
        <div className="col form-floating">
          <select
            disabled={true}
            className="form-control"
            value={contactInformation?.address?.state || ""}
            onChange={() => {
              // just to stop linter complaints for now
              // istanbul ignore next
              return;
            }}
          >
            <option value="">Select a State</option>
            {States.states.map(s => (
              <option
                key={`student-detail-tab-content-contact-state-list-key-${s.abbreviation}`}
                value={s.abbreviation}
              >
                {s.name}
              </option>
            ))}
          </select>
          <label>State</label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col form-floating">
          <input
            type="text"
            className="form-control"
            value={contactInformation?.address?.zip || ""}
            readOnly
            id="staff-detail-contact-form-zipcode"
          />
          <label htmlFor="staff-detail-contact-form-zipcode">Zip</label>
        </div>
      </div>

      <div className="row">
        <div className="columns small-12 end">
          <hr />
        </div>
      </div>

      <div className="row">
        <div className="columns small-12 end">Emergency Contact</div>
      </div>
      <br />

      <div className="row">
        <div className="col form-floating">
          <input
            type="text"
            className="form-control"
            value={contactInformation?.emergencyContactName || ""}
            readOnly
            id="staff-detail-contact-form-emcontact"
          />
          <label htmlFor="staff-detail-contact-form-emcontact">Name</label>
        </div>
        <div className="col form-floating">
          <input
            type="text"
            className="form-control"
            value={contactInformation?.emergencyContactPhone || ""}
            readOnly
            id="staff-detail-contact-form-emcontact-phone"
          />
          <label htmlFor="staff-detail-contact-form-emcontact-phone">Phone</label>
        </div>
      </div>
    </div>
  );
};
