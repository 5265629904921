import React from "react";

import * as Types from "../../data/types";
import * as Utils from "../../data/utils";

import { ItemType } from "../../hooks/use-sortable-filterable-data";
import {
  GeneralList,
  GeneralListColumnSpecification,
  GeneralListDataMapHandler,
  GeneralListItemSelectHandler
} from "../general-list/general-list";

import "./student-selector.scss";

export type StudentSelectorTabType = "students" | "families" | "filters";

export interface StudentSelectorProps {
  students?: Types.Student[];
  selected?: string;
  onSelect?: (id: string) => void;
  onAction?: (id: string) => void;
  filter: string;
}

export const StudentSelector = React.forwardRef<HTMLTableElement, StudentSelectorProps>(
  ({ students = [], selected, onSelect, onAction, filter }, tableRef) => {
    const onSelectItem: GeneralListItemSelectHandler = item => {
      onSelect && onSelect(item.id);
    };
    return (
      <div className="student-selector-student-list-container">
        <GeneralList
          filter={filter}
          ref={tableRef}
          data={students}
          mapper={studentListMapper}
          specs={studentListSpecs}
          activeItem={selected}
          onSelectItem={onSelectItem}
          onActionButtonClick={onAction}
        />
      </div>
    );
  }
);

StudentSelector.displayName = "StudentSelector";

export const studentListMapper: GeneralListDataMapHandler = function (this, student, index?, array?) {
  const s = student as Types.Student;
  return {
    id: s.id,
    name: Utils.formatAlphabeticName(s.name)
  } as ItemType;
};

export const studentListSpecs: GeneralListColumnSpecification[] = [
  //{ title: "ID#", name: "id", sorterName: "id" },
  {
    title: "Name",
    name: "name",
    sorterName: "name",
    className: "primary",
    isStrongCell: true,
    defaultSort: true
  }
];
