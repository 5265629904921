import React, { useState, useEffect } from "react";

import * as Utils from "../../data/utils";

export interface TimeFieldProps {
  value?: number;
  mode?: "edit" | "view";
  onChange?: (newvalue: number | undefined) => void;
}

export const TimeField: React.FC<TimeFieldProps> = ({ value, mode, onChange }) => {
  const [localValue, setLocalValue] = useState<number | undefined>(value);
  const localOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(Utils.parse24HourTime(e.target.value));
  };

  useEffect(() => {
    onChange && onChange(localValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue]);

  if (mode === "view") {
    return <>{Utils.formatTime(localValue)}</>;
  } else {
    return (
      <input
        type="time"
        className="form-control"
        value={Utils.format24HourTime(localValue)}
        style={{ width: "100%" }}
        onChange={localOnChange}
      />
    );
  }
};
