import React from "react";
import classnames from "classnames";

import * as Types from "../../data/types";

export interface ToDoGroupProps {
  title: string;
  items: Types.TodoItem[];
  active?: Types.TodoItemType;
  onSelectSubset?: (selected: Types.TodoItemType) => void;
}

export const ToDoGroup: React.FC<ToDoGroupProps> = ({ title, items, active, onSelectSubset }) => {
  if (!items.length) {
    return null;
  }
  return (
    <>
      {!!title && <strong>{title}</strong>}
      <ul className="menu vertical">
        {items.map(item => {
          return (
            <button
              className="unstyled"
              onClick={() => onSelectSubset?.(item.type)}
              key={`todo-item-button-${item.type}`}
            >
              <li className={classnames({ active: active === item.type })}>
                {Types.todoItemDescriptiveNames[item.type]}
                {item.count > 0 && <span className="badge">{item.count}</span>}
              </li>
            </button>
          );
        })}
      </ul>
      <br />
    </>
  );
};
