import React from "react";

import RLO from "react-loading-overlay";
// @ts-ignore
RLO.propTypes = undefined;

export interface LoadingOverlayProps {
  loading?: boolean;
  children?: React.ReactNode;
}

// so we can set shared settings in one place
export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ loading, children }) => {
  return (
    <RLO active={loading} spinner>
      {children}
    </RLO>
  );
};
