import React from "react";
import moment from "moment";

import * as Types from "../../data/types";
import { ItemType } from "../../hooks/use-sortable-filterable-data";

import { GeneralList, GeneralListColumnSpecification, GeneralListDataMapHandler } from "../general-list/general-list";

export interface CenterNotesProps {
  notes?: Types.CenterNote[];
}

export const CenterNotes: React.FC<CenterNotesProps> = ({ notes }) => {
  return (
    <div>
      <h5>Notes</h5>
      <GeneralList data={notes} mapper={notesMapper} specs={notesSpecs} />
    </div>
  );
};

export const notesMapper: GeneralListDataMapHandler = function (this, note, index?, array?) {
  const n = note as Types.CenterNote;
  return {
    id: n.id,
    date: moment(n.date).format("MMM D, YYYY"),
    title: n.title,
    //note: n.content
    note: (
      <>
        <strong>{n.title}</strong>
        <p>{n.content}</p>
      </>
    )
  } as ItemType;
};

export const notesSpecs: GeneralListColumnSpecification[] = [
  { title: "Date", name: "date", sorterName: "date" },
  { title: "Note", name: "note" }
];
