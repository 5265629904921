import React, { useState } from "react";
import moment from "moment";

import * as Types from "../../data/types";
import { ToDoGroup } from "./to-do-group";

export interface ToDoSidebarProps {
  active?: Types.TodoItemType;
  data?: Types.TodoItem[];
  onSelectSubset?: (selected: Types.TodoItemType) => void;
}

export const todoGroups: { name: string; items: Types.TodoItemType[] }[] = [
  //{ name: "", items: ["ae-today", "initial-conference", "attending-today", "interim-conf"] },
  {
    name: "Student Pipeline",
    items: ["inquiry", "ae-today", "ae-no-conf", "initial-conference", "conf-not-enrolled", "re-enroll"]
  },
  {
    name: "Student Relations",
    items: [
      "attending-today",
      "balance-due",
      "interim-conf",
      "interim-test",
      "school-visit",
      "missing-info",
      "deactivated-sched"
    ]
  }
];

export const ToDoSidebar: React.FC<ToDoSidebarProps> = ({ active = "ae-today", data, onSelectSubset }) => {
  const [currentDate] = useState<moment.Moment>(moment(new Date()));

  if (!data?.length) {
    return null;
  }

  return (
    <>
      <p style={{ paddingLeft: "10px" }}>{currentDate.format("dddd, MMMM D, YYYY")}</p>

      {todoGroups.map(group => {
        const items = data.filter(sd => group.items.includes(sd.type));
        items.sort((left, right) => {
          return group.items.findIndex(it => it === left.type) - group.items.findIndex(it => it === right.type);
        });
        return (
          <ToDoGroup
            active={active}
            items={items}
            title={group.name}
            key={`to-do-list-group-key-${group.name}`}
            onSelectSubset={onSelectSubset}
          />
        );
      })}
    </>
  );
};
